import React from 'react'

const People = () => {
    return (
        <>
            <h4>People</h4>
            <div className='card shadow  mt-10'>
                <div className="card-header border-0 mt-5">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input
                                type="text"
                                data-kt-user-table-filter="search"
                                className="form-control form-control-solid w-250px ps-13"
                                placeholder="Search "
                            //   value={searchValue}
                            //   onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <div className="card-toolbar">
                        <div
                            className="d-flex justify-content-end"
                            data-kt-customer-table-toolbar="base"
                        >
                            {/* {filter && (
                      <div className="d-flex align-items-center me-5 mt-3 justify-content-center">
                        <a
                          className="text-danger text-hover-danger fw-bold"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            filterFormik.resetForm();
                            setSelectedTypes([]);
                          }}
                        >
                          Clear Filters
                        </a>
                      </div>
                    )} */}
                            <div>
                                {/* <button
                                        type="button"
                                        className="btn me-3"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        id="kt-toolbar-filter"
                                    //   onClick={handleTransType}
                                    >
                                        <i className="ki-duotone ki-filter fs-2 text-light">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                        Filter
                                    </button> */}
                                <div
                                    className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                                    data-kt-menu="true"
                                    id="kt-toolbar-filter"
                                >
                                    <div className="px-7 py-5">
                                        <div className="fs-4 text-dark fw-bold">
                                            Filter Options
                                        </div>
                                    </div>
                                    <form>
                                        <div className="separator border-gray-200"></div>
                                        <div className="px-7 py-5">
                                            {/* By Status */}
                                            <div
                                                className="mb-5"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <label className="form-label fs-5 fw-semibold mb-3">By Tax Type</label>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusActive"
                                                            name="status"
                                                            value="Active"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusActive">
                                                            Corporate Income Tax
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusInactive"
                                                            name="status"
                                                            value="Inactive"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusInactive">
                                                            VAT
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            PAYE
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            UIF
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            SDL
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            Turnover Tax
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            Provisional Tax
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* By Type */}
                                            <div className="mb-10">
                                                <label className="form-label fs-5 fw-semibold mb-3">By Status</label>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeRemittance"
                                                            name="type"
                                                            value="Remittance Partner"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeRemittance">
                                                            Paid
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeIDAuth"
                                                            name="type"
                                                            value="ID Authentication"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeIDAuth">
                                                            Overdue
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeSMS"
                                                            name="type"
                                                            value="SMS"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeSMS">
                                                            Due
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            type="reset"
                                                            className="btn btn-light btn-active-light-primary me-2"
                                                            data-kt-menu-dismiss="true"
                                                            data-kt-customer-table-filter="reset"
                                                        //   onClick={filterFormik.handleReset}
                                                        >
                                                            Reset
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            data-kt-customer-table-filter="filter"
                                                            data-kt-menu-dismiss="true"
                                                        //   disabled={filterFormik.isSubmitting}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn  me-3"

                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_add_event"
                                >

                                    Invite People
                                </button>


                            </div>






                        </div>


                    </div>
                    {/* <!--end::Card toolbar--> */}
                </div>
                <div className="card-body p-5 ms-5 me-5">
                    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_1"
                            >
                                All
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_2"
                            >
                                Active
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_3"
                            >
                                Inactive
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_4"
                            >
                                Deleted
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade active show"
                            id="kt_tab_pane_1"
                            role="tabpanel"
                        >
                            <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email</th>
                                        <th>Card Number</th>
                                        <th>Usage Limit</th>
                                        <th>KYC</th>
                                        <th>Card Status</th>

                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Raju</td>
                                        <td>Marketing</td>
                                        <td>raju@yopmail.com</td>
                                        <td>**** **** 2037</td>
                                        <td >ZAR 10,000</td>
                                        <td><div className='badge badge-light-success ps-10 pe-10 pt-2 pb-2'>Completed</div></td>
                                        <td><div className='badge badge-light-success ps-10 pe-10 pt-2 pb-2'>Active</div></td>

                                        <td>
                                            <div className='d-flex flex-row gap-4'>
                                                <div ><i className='bi bi-eye' id="kt_partner_toggle" ></i></div>
                                                <div><i className='bi bi-pencil'></i></div>
                                                <div><i className='bi bi-trash text-danger'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Ravi</td>
                                        <td>Finance</td>
                                        <td>ravi@yopmail.com</td>
                                        <td>**** **** 4567</td>
                                        <td>ZAR 15,000</td>
                                        <td><div className='badge badge-light-warning ps-10 pe-10 pt-2 pb-2'>Pending</div></td>
                                        <td><div className='badge badge-light-success ps-10 pe-10 pt-2 pb-2'>Active</div></td>

                                        <td>
                                            <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye'></i></div>
                                                <div><i className='bi bi-pencil'></i></div>
                                                <div><i className='bi bi-trash text-danger'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Anjali</td>
                                        <td>HR</td>
                                        <td>anjali@yopmail.com</td>
                                        <td>**** **** 6789</td>
                                        <td>ZAR 12,500</td>
                                        <td><div className='badge badge-light-danger ps-10 pe-10 pt-2 pb-2'>Failed</div></td>
                                        <td><div className='badge badge-light-danger ps-10 pe-10 pt-2 pb-2'>Inactive</div></td>

                                        <td>
                                            <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye'></i></div>
                                                <div><i className='bi bi-pencil'></i></div>
                                                <div><i className='bi bi-trash text-danger'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sonia</td>
                                        <td>Operations</td>
                                        <td>sonia@yopmail.com</td>
                                        <td>**** **** 1234</td>
                                        <td>ZAR 8,750</td>
                                        <td><div className='badge badge-light-success ps-10 pe-10 pt-2 pb-2'>Completed</div></td>
                                        <td><div className='badge badge-light-success ps-10 pe-10 pt-2 pb-2'>Active</div></td>

                                        <td>
                                            <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye'></i></div>
                                                <div><i className='bi bi-pencil'></i></div>
                                                <div><i className='bi bi-trash text-danger'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Ajay</td>
                                        <td>IT</td>
                                        <td>ajay@yopmail.com</td>
                                        <td>**** **** 9876</td>
                                        <td>ZAR 20,000</td>
                                        <td><div className='badge badge-light-warning ps-10 pe-10 pt-2 pb-2'>In Progress</div></td>
                                        <td><div className='badge badge-light-warning ps-10 pe-10 pt-2 pb-2'>Not Assigned</div></td>

                                        <td>
                                            <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye'></i></div>
                                                <div><i className='bi bi-pencil'></i></div>
                                                <div><i className='bi bi-trash text-danger'></i></div>
                                            </div>
                                        </td>
                                    </tr>




                                </tbody>
                            </table>

                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                            <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email</th>
                                        <th>Card Number</th>
                                        <th>Usage Limit</th>

                                        <th>Card Status</th>

                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Raju</td>
                                        <td>Marketing</td>
                                        <td>raju@yopmail.com</td>
                                        <td>**** **** 2037</td>
                                        <td >ZAR 10,000</td>

                                        <td><div className='badge badge-light-success ps-10 pe-10 pt-2 pb-2'>Active</div></td>

                                        <td>
                                            <div className='d-flex flex-row gap-4'>
                                                <div ><i className='bi bi-eye' id="kt_partner_toggle" ></i></div>
                                                <div><i className='bi bi-pencil'></i></div>
                                                <div><i className='bi bi-trash text-danger'></i></div>
                                            </div>
                                        </td>
                                    </tr>





                                </tbody>
                            </table>

                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                            <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email</th>
                                        <th>Card Number</th>
                                        <th>Usage Limit</th>

                                        <th>Card Status</th>

                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Raju</td>
                                        <td>Marketing</td>
                                        <td>raju@yopmail.com</td>
                                        <td>**** **** 2037</td>
                                        <td >ZAR 10,000</td>

                                        <td><div className='badge badge-light-danger ps-10 pe-10 pt-2 pb-2'>Inactive</div></td>

                                        <td>
                                            <div className='d-flex flex-row gap-4'>
                                                <div ><i className='bi bi-eye' id="kt_partner_toggle" ></i></div>
                                                <div><i className='bi bi-pencil'></i></div>
                                                <div><i className='bi bi-trash text-danger'></i></div>
                                            </div>
                                        </td>
                                    </tr>





                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
                            <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Raju</td>
                                        <td>Marketing</td>
                                        <td>raju@yopmail.com</td>

                                    </tr>





                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <div
                className="modal fade"
                id="kt_modal_add_event"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div
                        className="modal-content"
                        style={{ maxHeight: "700px", overflow: "auto" }}
                    >
                        <div
                            className="modal-header d-flex justify-content-between"
                            id="kt_modal_add_user_header"
                        >
                            <h2 className="fw-bold">Invite People</h2>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            <div className="fv-row mb-7 ">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                    Name
                                </label>
                                <input type="text" placeholder='Name' className='form-control' />
                            </div>
                            <div className="fv-row mb-7 ">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                    Email
                                </label>
                                <input type="Email" placeholder='Email' className='form-control' />
                            </div>
                            <div className="fv-row mb-7 ">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                    Role
                                </label>
                                <select className="form-select" data-control="select2" data-placeholder="Select an option">
                                    <option></option>
                                    <option value="1">Marketing</option>
                                    <option value="2">Finance</option>
                                    <option value="3">HR</option>
                                    <option value="4">Operations</option>


                                </select>
                            </div>






                            <div className="text-center mt-10">
                                <button
                                    type="reset"
                                    // onClick={() => cancel()}
                                    className="btn btn-light me-3"
                                    data-kt-users-modal-action="cancel"
                                >
                                    Discard
                                </button>

                                <button
                                    type="submit"
                                    className="btn"
                                    data-kt-users-modal-action="submit"
                                >
                                    <span className="indicator-label">Submit</span>
                                    {/* {formik.isSubmitting && (
                                                            <span className="indicator-progress">
                                                                Please wait...{" "}
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )} */}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="kt__partner_modal"
                className="bg-body"
                data-kt-drawer="true"
                data-kt-drawer-name="modal"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'449px', 'md': '455px'}"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_partner_toggle"
                data-kt-drawer-close="#kt_close"
            >
                <div
                    className="modal-dialog modal-fullscreen modal-dialog-end"
                    role="document"
                >
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header d-flex justify-content-between mt-5 mb-2 d-flex flex-row mx-10">
                            <div>
                                <h5 className="modal-title fw-bold fs-4">Details</h5>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                    aria-label="Close"
                                    id="kt_close"
                                >
                                    <i className="ki-duotone ki-cross fs-2x">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </button>
                            </div>
                        </div>
                        <hr className="w-100" />

                        <div className="modal-body w-100 p-5">
                            <div className="card shadow-sm p-4">
                                <div className="row">
                                    <div className="image-input image-input-outline" data-kt-image-input="true">
                                        <div
                                            className="image-input-wrapper w-125px h-125px d-flex ms-2 flex-row align-items-center justify-content-center"
                                            style={{
                                                // backgroundImage: imagePreview && formik.values.avatar ? `url(${imagePreview})` : 'none',
                                                backgroundSize: 'contain',
                                                backgroundPosition: 'center',
                                            }}

                                        >

                                            <i className="bi bi-person-fill" style={{ fontSize: '50px' }}></i>

                                        </div>


                                        {/* Edit Button */}
                                        {/* <label
                                                            style={{ marginTop: '126px' }}
                                                            className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body"
                                                            data-kt-image-input-action="change"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-dismiss="click"
                                                            title="Change avatar"
                                                        >
                                                            <i className="ki-duotone ki-pencil fs-6">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>
                                                            <input
                                                                type="file"
                                                                name="avatar"
                                                                accept=".png, .jpg, .jpeg,.svg"
                                                                // onChange={(event) => {
                                                                //     const file = event?.currentTarget?.files?.[0];
                                                                //     if (file) {
                                                                //         formik.setFieldValue("avatar", file);
                                                                //         event.target.value = "";
                                                                //     }
                                                                // }}

                                                            />
                                                            <input type="hidden" name="avatar_remove" />
                                                        </label> */}

                                        {/* Remove Button */}
                                        {/* <span
                                                            style={{ marginRight: '50%' }}
                                                            className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body"
                                                            data-kt-image-input-action="cancel"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-dismiss="click"
                                                            title="Cancel avatar"
                                                            // onClick={() => formik.setFieldValue('avatar', null)}
                                                        >
                                                            <i className="ki-outline ki-cross fs-3"></i>
                                                        </span> */}

                                        {/* Remove Button */}
                                        {/* <span
                                                            style={{ marginLeft: '-90%' }}
                                                            className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body"
                                                            data-kt-image-input-action="remove"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-dismiss="click"
                                                            title="Remove avatar"
                                                            // onClick={() => formik.setFieldValue('avatar', null)}
                                                        >
                                                            <i className="ki-outline ki-cross fs-3"></i>
                                                        </span> */}
                                    </div>


                                    {[
                                        { label: "Name", value: "John Doe" },
                                        { label: "Designation", value: "Software Engineer" },
                                        { label: "Card Type", value: "Prepaid" },
                                        { label: "Card Name", value: "John's Visa" },
                                        { label: "Card Status", value: "Active" },
                                        { label: "Usage Limit", value: "ZAR 5000" },
                                        { label: "Available", value: "ZAR 3000" },
                                        { label: "Per Transaction Limit", value: "ZAR 10,000" },
                                        { label: "Category", value: "Personal" },
                                        { label: "Action", value: "" }

                                    ].map((item, index) => (
                                        <div className="col-12 d-flex justify-content-between py-4" key={index}>
                                            <div className="fw-bold text-dark">{item.label}</div>
                                            <div className={`${item.label === "Card Status" ? "badge badge-light-warning" : "text-muted"}`}>
                                                {item.value}
                                            </div>
                                            {item.label === "Action" && (
                                                <>
                                                    <div >
                                                        <div className="mb-10">
                                                            <div className="form-check form-switch form-check-custom form-check-solid">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexSwitchDefault" />

                                                            </div>
                                                        </div>


                                                    </div></>
                                            )
                                            }

                                        </div>
                                    ))}
                                </div>


                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default People