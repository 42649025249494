import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../core/Auth";
import { business, getAllData, primaryApplicant } from "./api";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import { useFormCompletion } from "./Stepper";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface UserFormValues {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  emailId: string;
  mobile: string;
  nationality: string;
  idProofType: string;
  file: File | undefined; // File type for file upload, can be undefined initially
  idNumber: string;
  idExpiryDate: string;
  status: string;
}


const PrimaryApplicant = () => {
  const deviceId = uuidv4();
  const [previewSrc, setPreviewSrc] = useState("");
  const [extension, setExtension] = useState("");
  const { regUser, setRegUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showInput, setShowInput] = React.useState(false);

  const navigate = useNavigate();
  const { markFormCompleted } = useFormCompletion();

  const maxSize = 3 * 1024 * 1024;


  const initialValues = {
    firstName: regUser?.firstName ?? "",
    lastName: regUser?.lastName ?? "",
    dateOfBirth: regUser?.dob?.split("T")[0] ?? "",
    emailId: regUser?.email ?? "",
    mobile: regUser?.isdCode?.toString().concat(regUser?.mobile),
    nationality: regUser?.nationality ?? "",
    idProofType: regUser?.idType ?? "",
    file: undefined,
    idNumber: regUser?.idNumber ?? "",
    idExpiryDate: regUser?.idExpiryDate?.split("T")[0] ?? "",
    status: "",
  }

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "First name can only contain letters and numbers")
      .required("First name is required")
      .test("name-changed", "First name must be different from the current value", function (value) {
        if (regUser?.reapplyParams?.firstName) {
          return value !== initialValues.firstName;
        }
        return true; // No validation if reapplyParams is not present
      }),

    lastName: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "Last name can only contain letters and numbers")
      .required("Last name is required")
      .test("name-changed", "Last name must be different from the current value", function (value) {
        if (regUser?.reapplyParams?.lastName) {
          return value !== initialValues.lastName;
        }
        return true; // No validation if reapplyParams is not present
      }),

    dateOfBirth: Yup.date()
      .required("Date of birth is required")
      .test("date-changed", "Date of birth must be different from the current value", function (value) {
        if (regUser?.reapplyParams?.dob) {
          const initialDate = new Date(initialValues.dateOfBirth);
          const newDate = new Date(value);
          initialDate.setHours(0, 0, 0, 0);
          newDate.setHours(0, 0, 0, 0);
          return initialDate.getTime() !== newDate.getTime();
        }
        return true; // No validation if reapplyParams is not present
      }),

    emailId: Yup.string()
      .required("Email ID is required")
      .email("Please provide a valid email format")
      .min(8, "Email must be at least 8 characters long")
      .max(50, "Email cannot exceed 50 characters")
      .matches(
        /^[a-z0-9.@]+$/,
        "Email can only contain lowercase letters, digits, and periods"
      ),

    idNumber: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "ID number can only contain letters and numbers")
      .required("ID number is required")
      .test("name-changed", "ID number must be different from the current value", function (value) {
        if (regUser?.reapplyParams?.idNumber) {
          return value !== initialValues.idNumber;
        }
        return true; // No validation if reapplyParams is not present
      }),

    nationality: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "Nationality can only contain letters and numbers")
      .required("Nationality is required")
      .test("name-changed", "Nationality must be different from the current value", function (value) {
        if (regUser?.reapplyParams?.nationality) {
          return value !== initialValues.nationality;
        }
        return true; // No validation if reapplyParams is not present
      }),

    idProofType: Yup.string()
      .required("ID proof type is required")
      .test("name-changed", "ID proof type must be different from the current value", function (value) {
        if (regUser?.reapplyParams?.idType) {
          console.log(value);
          console.log(initialValues.idProofType);

          return value !== initialValues.idProofType;
        }
        return true; // No validation if reapplyParams is not present
      }),

    idExpiryDate: Yup.date()
      .required("ID expiry date is required")
      .test("date-changed", "Expiry date must be different from the current value", function (value) {
        if (regUser?.reapplyParams?.idExpiryDate) {
          const initialDate = new Date(initialValues.idExpiryDate);
          const newDate = new Date(value);
          initialDate.setHours(0, 0, 0, 0);
          newDate.setHours(0, 0, 0, 0);
          return initialDate.getTime() !== newDate.getTime();
        }
        return true; // No validation if reapplyParams is not present
      }),

    file: Yup.mixed()
      .nullable()
      .test("fileType", "Please upload a valid PDF or image file", function (value) {
        const reapply = regUser?.reapply;
        if (reapply) {
          return true;
        }
        if (!value) return true; // Allow null or undefined

        const file = value as File;
        const validExtensions = [
          
          ".jpg",
          ".jpeg",
          ".png",
          ".svg",
          ".webp",
        ];
        const extension = file.name
          ? file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
          : "";
        return validExtensions.includes(extension);
      })
      .test("fileSize", "File size must not exceed 3 MB", function (value) {
        const reapply = regUser?.reapply;
        if (reapply) {
          return true;
        }
        if (!value) return true; // Allow null or undefined

        const file = value as File;
        return file.size <= 3 * 1024 * 1024; // 3 MB
      })
      .test("required", function (value) {
        if (regUser?.reapply) {
          if (regUser?.reapplyParams?.idProofDocument) {
            return !!value || this.createError({ message: "Please upload a valid ID document" });
          }
          if (formik?.values?.file === undefined || null) {
            return this.createError({ message: "ID proof is required" });
          }
          // return true;
        }
        return !!value || this.createError({ message: "ID proof is required" });
      }),
  });


  const handlePreview = (documentPath: string, documentExtension: string) => {
    setPreviewSrc(documentPath);
    setExtension(documentExtension);
  };

  const formik = useFormik<UserFormValues>({
    initialValues: {
      firstName: regUser?.firstName ?? "",
      lastName: regUser?.lastName ?? "",
      dateOfBirth: regUser?.dob?.split("T")[0] ?? "",
      emailId: regUser?.email ?? "",
      mobile: regUser?.isdCode?.toString().concat(regUser?.mobile) ?? "",
      nationality: regUser?.nationality ?? "",
      idProofType: regUser?.idType ?? "",
      file: undefined,
      idNumber: regUser?.idNumber ?? "",
      idExpiryDate: regUser?.idExpiryDate?.split("T")[0] ?? "",
      status: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange:false,
    validateOnBlur:false,
    onSubmit: async (values, { setFieldValue, resetForm, setSubmitting }) => {
      setLoading(true); // Start loading
      setSubmitting(true); // Indicate form is being submitted

      try {
        const res = await primaryApplicant(
          values?.file,
          values?.firstName,
          values?.lastName,
          values?.dateOfBirth,
          values?.emailId,
          values?.nationality,
          values?.idProofType,
          values?.idNumber,
          values?.idExpiryDate,
          regUser?.token ?? "",
          regUser?.reapply ?? false
        );

        if (res?.data?.status?.statusCode === 0) {
          setRegUser((prevState) => ({
            ...prevState,
            ...res?.data?.message,
          }));

          markFormCompleted("primary-applicant");
          regUser?.reapply ? navigate("/auth/signature") : regUser?.livenessTool==="AWS-REKOG"? navigate("/auth/selfie"): navigate("/auth/smileId");
          resetForm();
        } else {
          setFieldValue("status", res?.data?.status?.messageDescription);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false); // Stop loading
        setSubmitting(false); // Indicate form submission is complete
        setShowInput(false);
      }
    },
  });

  useEffect(() => {
    getAllData(regUser?.token ?? "").then((res) => {
      setRegUser((prevState) => ({
        ...prevState,
        ...res.data.message,
      }));
    });
  }, [])

  const handleCancel = () => {
    setShowInput(true);
    formik.setFieldValue("file", null);
    const fileInput = document.getElementById(
      "idFile"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  useEffect(() => {
    // Ensure regUser and businessDocs exist and fetch only if data is available
    if (regUser?.idProofDocument && regUser?.idProofDocument?.documentPath && !regUser?.reapplyParams?.idProofDocument) {
      const inputId = "idFile"; // License
      const formikField = "file";
  
      const fileUrl = regUser?.idProofDocument?.documentPath;
  
      // Fetch the file and simulate file selection for the specific input
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = `${regUser?.idProofDocument?.documentName || "document"}.${regUser?.idProofDocument?.documentExtention || "pdf"}`;
          const file = new File([blob], fileName, { type: blob.type });
  
          const fileInput = document.getElementById(inputId) as HTMLInputElement;
          if (fileInput) {
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            fileInput.files = dataTransfer.files;
  
            const event = new Event("change", { bubbles: true });
            fileInput.dispatchEvent(event);
  
            // Set the file in Formik for the respective field
            formik.setFieldValue(formikField, file);
          }
        })
        .catch((error) => {
          console.error("Error fetching file: ", error);
        });
    }
  }, [getAllData]);
  

  return (
    <div
      className=" p-5 w-100"

    >
      <p className="fs-2 fw-bold text-center">
        Please enter the primary applicant’s details. (Director 1)
      </p>

      <div className="p-10">
        {formik?.values?.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik?.values?.status}
            </div>
          </div>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-5">
            <label className="form-label">First Name  {regUser?.reapplyParams?.firstName ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.reapplyParams?.firstName}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="text"
              className={`form-control ${formik?.touched?.firstName && formik?.errors?.firstName
                ? "is-invalid"
                : ""
                } ${regUser?.reapplyParams?.firstName
                  ? "is-invalid"
                  : ""
                }`}
              placeholder="First Name"
              maxLength={50}
              id="firstName"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="invalid-feedback">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Last Name {regUser?.reapplyParams?.lastName ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.reapplyParams?.lastName}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="text"
              className={`form-control ${formik?.touched?.lastName && formik?.errors?.lastName
                ? "is-invalid"
                : ""
                } ${regUser?.reapplyParams?.lastName
                  ? "is-invalid"
                  : ""
                }`}
              placeholder="Last Name"
              id="lastName"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              maxLength={50}
            />
            {formik?.touched?.lastName && formik.errors.lastName ? (
              <div className="invalid-feedback">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Date Of Birth {regUser?.reapplyParams?.dob ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.reapplyParams?.dob}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="date"
              className={`form-control ${formik.touched.dateOfBirth && formik.errors.dateOfBirth
                ? "is-invalid"
                : ""
                } ${regUser?.reapplyParams?.dob
                  ? "is-invalid"
                  : ""
                }`}
              placeholder="Date Of Birth"
              id="dateOfBirth"
              name="dateOfBirth"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() - 15))
                  .toISOString()
                  .split("T")[0]
              }
            />
            {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
              <div className="invalid-feedback">
                {formik.errors.dateOfBirth}
              </div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">
              Email Id (To be used as registered ID)
            </label>
            <input
              type="email"
              className={`form-control ${formik.touched.emailId && formik.errors.emailId
                ? "is-invalid"
                : ""
                }`}
              placeholder="Email Id"
              id="emailId"
              name="emailId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailId}
              maxLength={50}
            />
            {formik.touched.emailId && formik.errors.emailId ? (
              <div className="invalid-feedback">{formik.errors.emailId}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Mobile</label>
            <PhoneInput
              inputStyle={{
                width: "100%",
                height: "43.59px",
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  height: "43.59px",
                },
              }}
              hideDropdown={true}
              inputProps={{
                readOnly: true,
              }}
              value={formik.values.mobile}
              onChange={(value) => formik.setFieldValue("mobile", value)}
              defaultCountry="in"
            />
          </div>
          <div className="mb-5">
            <label className="form-label">Nationality {regUser?.reapplyParams?.nationality ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.reapplyParams?.nationality}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <div>
              <select
                className={`form-select ${formik.touched.nationality && formik.errors.nationality
                  ? "is-invalid"
                  : ""
                  } ${regUser?.reapplyParams?.nationality
                    ? "is-invalid"
                    : ""
                  }`}
                name="nationality"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nationality}
                aria-label="Select example"
              >
                <option value="">Select</option>
                <option value="India">India</option>
                <option value="South Africa">South Africa</option>
                <option value="Uganda">Uganda</option>
              </select>
              {formik.touched.nationality && formik.errors.nationality ? (
                <div className="invalid-feedback">
                  {formik.errors.nationality}
                </div>
              ) : null}
            </div>
          </div>
          <div className="mb-5">
            <label className="form-label">Id Proof Type</label>
            <div>
              <select
                className={`form-select ${formik.touched.idProofType && formik.errors.idProofType
                  ? "is-invalid"
                  : ""
                  } ${regUser?.reapplyParams?.idType
                    ? "is-invalid"
                    : ""
                  }`}
                name="idProofType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idProofType}
                aria-label="Select example"
              >
                <option value="">Select</option>
                <option value="NATIONAL_ID">NID</option>
                <option value="NATIONAL_ID_NO_PHOTO">Passport</option>
              </select>
              {formik.touched.idProofType && formik.errors.idProofType ? (
                <div className="invalid-feedback">
                  {formik.errors.idProofType}
                </div>
              ) : null}
            </div>
          </div>
          <div className={`mb-5 ${regUser?.reapplyParams?.idProofDocument ? "form-control is-invalid" : ""}`}>
            <label className="form-label">
              Upload ID Proof{" "}
              {regUser?.reapplyParams?.idProofDocument ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-add-money">
                      {regUser?.reapplyParams?.idProofDocument}
                    </Tooltip>
                  }
                >
                  <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                  </span>
                </OverlayTrigger>
              ) : null}
            </label>

            {/* Show preview if reapply is true and file exists */}
            {formik.values?.file && !showInput ? (
              <div className="d-flex flex-start">
                <div className="position-relative">
                  <span
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      cursor: "pointer",
                      color: "red",
                      fontSize: "20px",
                    }}
                    onClick={handleCancel} // Cancel preview to allow re-upload
                  >
                    &times;
                  </span>

                  <img
                    src={
                      formik?.values?.file?.type === "application/pdf"
                        ? toAbsoluteUrl("/media/subicons/pdf.png")
                        : formik?.values?.file
                          ? URL.createObjectURL(formik?.values?.file)
                          : ""
                    }
                    onClick={() => {
                      const fileUrl = formik?.values?.file
                        ? URL.createObjectURL(formik.values.file)
                        : "";
                      const isPdf = formik?.values?.file?.type === "application/pdf";
                      const extension = isPdf
                        ? "pdf"
                        : formik?.values?.file?.name?.split(".").pop() || "";
                      handlePreview(fileUrl, extension); // Open modal to preview file
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    className="mt-2"
                    alt="ID Proof"
                    style={{
                      height: "40px",
                      width: "40px",
                      cursor: "pointer",
                      borderRadius: "5%",
                    }}
                  />
                </div>
              </div>
            ) : (
              <input
                type="file"
                ref={fileInputRef}
                id="idFile"
                className={`form-control ${formik.errors.file ? "is-invalid" : ""
                  }`}
                onChange={(event) => {
                  const file = event.currentTarget.files?.[0];
                  formik.setFieldValue("file", file); // Set file value in Formik
                  if(!regUser?.reapply){
                    setShowInput(false);
                  }
                }}
                accept="image/*"
              />
            )}

            {/* Show validation error */}
            {formik.touched.file && formik.errors.file && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.file}</span>
                </div>
              </div>
            )}


          </div>

          <div className="mb-5">
            <label className="form-label">ID Number {regUser?.reapplyParams?.idNumber ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.reapplyParams?.idNumber}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="text"
              className={`form-control ${formik.touched.idNumber && formik.errors.idNumber
                ? "is-invalid"
                : ""
                } ${regUser?.reapplyParams?.idNumber
                  ? "is-invalid"
                  : ""
                }`}
              placeholder="ID Number"
              id="idNumber"
              name="idNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.idNumber}
              maxLength={20}
            />
            {formik.touched.idNumber && formik.errors.idNumber ? (
              <div className="invalid-feedback">{formik.errors.idNumber}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Id Expiry Date {regUser?.reapplyParams?.idExpiryDate ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.reapplyParams?.idExpiryDate}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="date"
              className={`form-control ${formik.touched.idExpiryDate && formik.errors.idExpiryDate
                ? "is-invalid"
                : ""
                } ${regUser?.reapplyParams?.idExpiryDate
                  ? "is-invalid"
                  : ""
                }`}
              placeholder="Id Expiry Date"
              id="idExpiryDate"
              name="idExpiryDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.idExpiryDate}
              min={new Date().toISOString().split("T")[0]}

            />
            {formik.touched.idExpiryDate && formik.errors.idExpiryDate ? (
              <div className="invalid-feedback">
                {formik.errors.idExpiryDate}
              </div>
            ) : null}
          </div>
          <div className="mt-20">
            <button
              type="submit"
              className="btn w-100"
              disabled={formik.isSubmitting || loading}
            >
              {formik.isSubmitting ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div>Next</div>
              )}
            </button>
          </div>
        </form>
        <div
        className="modal fade w-100"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered w-100"
          role="document"
        >
          {previewSrc && (
            <>
              {extension === "pdf" ? (
                <div
                  className="modal-content w-100 p-0 m-0"
                  style={{ position: "absolute", right: "50%" }}
                >
                  <div className="modal-body p-0 m-0">
                    <iframe
                      src={previewSrc}
                      title="PDF Preview"
                      style={{ width: "200%", height: "80vh" }} // Adjust width to 100%
                    ></iframe>
                  </div>
                </div>
              ) : (
                <img
                  src={previewSrc}
                  alt="Document Preview"
                  style={{
                    width: "300px", // Optional: set width to 100%
                    height: "auto", // Optional: maintain aspect ratio
                    borderRadius: "10px",
                    objectFit: "fill",
                    marginLeft: "20%",
                    // maxWidth:"200px"
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default PrimaryApplicant;

