import React from "react";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { Insurance, messageBusinessInsurer } from "./insuranceSlice";
import { useLocation } from "react-router";
import { downloadFile } from "../documentRoom/Folder";

const BusinessInsuarnceDetail = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const insurance = location.state?.insurance as Insurance | undefined;
  const baseCurrency = sessionStorage.getItem("currency");

  const formik = useFormik({
    initialValues: {
      message: "",
      attachments: [] as File[],
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Message is required"),
      attachments: Yup.array().test(
        "min-files",
        "At least one file is required",
        (value) => value && value.length > 0
      ),
      // .test(
      //   "is-pdf",
      //   "Only PDF files are allowed",
      //   (value) =>
      //     value && value.every((file) => file.type === "application/pdf")
      // ),
    }),
    // enableReinitialize: true,
    onSubmit: async (values) => {
      if (insurance)
        dispatch(
          messageBusinessInsurer({
            message: values.message,
            uploadFiles: values.attachments,
            bizzInsuranceGuid: insurance?.bizzInsuranceGuid,
          }) as any
        ).then(() => {
          formik.resetForm();
          const modal = Modal.getInstance("#kt_modal_message");
          if (modal) {
            modal.hide();
          }
        });
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const currentAttachments = formik?.values?.attachments || [];
    const maxFiles = 10;

    if (currentAttachments.length >= maxFiles) {
      // Stop further processing if the limit is already reached
      return;
    }

    const filesArray = Array.from(event.target.files);
    const remainingSlots = maxFiles - currentAttachments.length;

    // Take only the files that fit within the limit
    const filesToAdd = filesArray.slice(0, remainingSlots);

    formik.setFieldValue("attachments", [...currentAttachments, ...filesToAdd]);

    event.target.value = ""; // Clear the file input
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = formik.values.attachments.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("attachments", updatedFiles);
  };

  const calculatePercentage = (
    value1: string | undefined,
    value2: string | undefined
  ): number => {
    if (value1 === undefined || value2 === undefined) return 0;
    // Remove commas and convert to numbers
    const num1 = parseInt(value1.replace(/,/g, ""), 10);
    const num2 = parseInt(value2.replace(/,/g, ""), 10);

    if (num2 === 0) {
      return 0;
    }

    // Calculate and return percentage
    const percentage = Math.round((num1 / num2) * 100);
    return percentage;
  };

  return (
    <>
      <h4>Program Details</h4>
      <div className="card p-5 mt-5">
        <div className="card d-flex flex-row gap-5 mt-5">
          <div className="container">
            {/* <div className="row g-10"> */}
            {/* {programs.map((program, index) => ( */}
            <div key={1} className="" style={{ width: "650px" }}>
              <div className="card shadow p-5" style={{ height: "442px" }}>
                <div className="d-flex flex-row gap-3">
                  <div style={{ height: "50px", width: "50px" }}>
                    <img
                      className="rounded"
                      style={{
                        height: "50px",
                        width: "50px",
                        objectFit: "contain",
                      }}
                      src={insurance?.logoUrl}
                      alt={insurance?.bizzInsuranceName}
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column justify-content-center">
                      <div className="fw-bold fs-6">
                        {insurance?.bizzInsuranceName}
                      </div>
                      <div className="fw-bold fs-8 text-gray-600">
                        ({insurance?.headline})
                      </div>
                    </div>
                    <div style={{ maxWidth: "400px" }}>
                      <div className="d-flex flex-column gap-2">
                        <div
                          className="progress"
                          style={{ height: "20px", backgroundColor: "#e9ecef" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${100 - calculatePercentage(insurance?.uncoverdValue, insurance?.coverdValue)}%`,
                              backgroundColor: "#28a745",
                              textAlign: "center",
                              lineHeight: "25px",
                              color: "#fff",
                            }}
                          >{`${100 - calculatePercentage(insurance?.uncoverdValue, insurance?.coverdValue)}%`}</div>
                        </div>
                        <div className="text-gray-600 fs-8">
                          <span>
                            Coverage: {insurance?.coverdValue} {baseCurrency}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          <span>
                            Remaining: {insurance?.uncoverdValue} {baseCurrency}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr
                  className="text-gray-600"
                  style={{ borderTop: "1px dashed" }}
                />
                <div className="fs-7 text-gray-600">
                  {insurance?.planDetails}
                </div>

                {/* <div className="mt-5 fs-7 text-gray-600">
                  $Old Mutual Limited (OML) is a premium African financial
                  services group that offers a broad spectrum of financial
                  solutions to retail and corporate customers across key markets
                  in 13 countries. Our purpose is to help our customers thrive
                  by enabling them to achieve their lifetime financial goals,
                  while investing their funds in ways that will create a
                  positive future for them, their families, their communities
                  and broader society. In this way, we significantly contribute
                  to improving the lives of our customers and their communities
                  while ensuring a sustainable future for our business.
                </div> */}
              </div>
            </div>
            {/* ))} */}
            {/* </div> */}
          </div>
          <div className="container ">
            <div className="d-flex flex-column gap-10">
              <div className="card shadow p-10">
                <div className="d-flex flex-row justify-content-between mb-2">
                  <span className="fw-bold">Policy Number :</span>
                  &nbsp; {insurance?.policyNumber}
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <span className="fw-bold">Valid Till :</span>&nbsp;{" "}
                  {insurance?.validTill}
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <span className="fw-bold">Policy Document :</span>
                  <div
                    className="badge badge-primary cursor-pointer"
                    onClick={() => {
                      if (insurance)
                        downloadFile(
                          insurance?.policyDocUrl,
                          "Policy Document"
                        );
                    }}
                  >
                    &nbsp;Download
                  </div>
                </div>
              </div>
              <div className="card shadow p-10">
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_message"
                >
                  Message Vendor
                </button>
                <button
                  className="btn btn-light-primary mt-5"
                  onClick={() => {
                    // window.open(program?.website, "_blank");
                    window.open(
                      insurance?.website,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  Visit Website
                </button>
              </div>
              <div
                className="card shadow p-5"
                style={{ height: "240px", overflow: "scroll" }}
              >
                <div className="fw-bold ms-2">Claim History</div>
                {insurance?.claimHistoryMapList?.map((history) => (
                  <div
                    className="card shadow-sm p-3 m-2"
                    key={history?.claimedId}
                  >
                    <div className="d-flex flex-row justify-content-between text-gray-600 fs-8">
                      <div>{history?.claimedDate}</div>
                      <div>Claim Id:{history?.claimedId}</div>
                    </div>
                    <div className="mt-2 d-flex flex-row justify-content-between ">
                      <div className="fw-bold">
                        {history?.claimedAmount} Claimed&nbsp;
                        <img
                          style={{ height: "20px" }}
                          src={toAbsoluteUrl(
                            "/media/logos/icons8-success-30.png"
                          )}
                          alt=""
                        />
                      </div>
                      <div>
                        <i
                          className="bi bi-download text-primary cursor-pointer"
                          onClick={() =>
                            downloadFile(history?.docUrl, "Claim Slip")
                          }
                        ></i>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" tabIndex={-1} id="kt_modal_message">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Message Vendor</h5>
              </div>
              <div className="modal-body">
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Message <span className="fs-8"></span>
                  </label>
                  <textarea
                    // type="text"
                    name="message"
                    maxLength={500}
                    className="form-control"
                    placeholder="Message..."
                    style={{ minHeight: "150px" }}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <div className="fv-plugins-message-container">
                      <span className="text-danger">
                        {formik.errors.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="container mt-4 card shadow p-5">
                  <h5 className="fw-bold required">Upload a File</h5>
                  <p className="text-muted small">File upload description</p>

                  {/* Drag and Drop */}
                  <div className="border border-2 rounded-3 p-4 mb-3 text-center">
                    <input
                      type="file"
                      className="form-control d-none"
                      id="fileInput"
                      multiple
                      onChange={handleFileChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      htmlFor="fileInput"
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="display-4 mb-2">+</div>
                      <span className="fw-bold">Drop your files here</span>
                      <span className="text-primary small">
                        Browse file from your computer(Maximum 10 files)
                      </span>
                    </label>
                  </div>

                  {/* Display Uploaded Files */}
                  {formik.values.attachments.length > 0 &&
                    formik.values.attachments.map((file, index) => (
                      <div className="mb-3 card p-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <i className="bi bi-file-earmark me-2 fs-4"></i>
                            <div>
                              <div className="fw-bold">{file.name}</div>
                              <div className="small text-muted">
                                {(file.size / 1024).toFixed(1)} KB
                              </div>
                            </div>
                          </div>
                          <div>
                            {/* Trash Icon to Remove File */}
                            <i
                              className="bi bi-trash text-danger fs-4"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveFile(index)}
                            ></i>
                          </div>
                        </div>
                        {/* <ProgressBar now={100} variant="success" className="mt-2" /> */}
                      </div>
                    ))}

                  {formik.touched.attachments && formik.errors.attachments && (
                    <div className="text-danger small">
                      {Array.isArray(formik.errors.attachments)
                        ? formik.errors.attachments.map((error, index) =>
                            typeof error === "string" ? (
                              <div key={index}>{error}</div>
                            ) : null
                          )
                        : typeof formik.errors.attachments === "string"
                          ? formik.errors.attachments
                          : null}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInsuarnceDetail;
