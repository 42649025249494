import React, { useEffect, useState } from 'react';
import { useAuth } from '../../core/Auth';
import { compareFaces, getAllData, uploadSelfieDoc } from './api';
import { useNavigate } from 'react-router';
import { useFormCompletion } from './Stepper';
import { toast, ToastContainer } from 'react-toastify';

const IDCardCapture = () => {
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { regUser, setRegUser } = useAuth();
    const navigate = useNavigate();
    const { markFormCompleted } = useFormCompletion();
    const [similarity, setSimilarity] = useState("0");
    const [userData, setUserData] = useState<any>(null);

    // Convert base64 to File
    const converBase64ToImage = (base64: string, fileName: string): File | null => {
        try {
            const base64Data = base64.replace(/^data:image\/\w+;base64,/, "");
            if (!/^[A-Za-z0-9+/]+={0,2}$/.test(base64Data)) {
                throw new Error("Invalid Base64 string");
            }

            const byteString = atob(base64Data);
            const mimeString = base64.match(/^data:(image\/\w+);base64,/)?.[1] || "image/png";
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);

            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new File([new Blob([ab], { type: mimeString })], fileName, { type: mimeString });
        } catch (error) {
            console.error("Failed to convert base64 to image:", error);
            return null;
        }
    };

    // Convert File to base64
    const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (typeof reader.result === 'string') resolve(reader.result);
                else reject(new Error('Invalid file format'));
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    // Face comparison
    const compareFacesAndVerify = async (sourceFile: File, targetFile: File) => {
        try {
            const res = await compareFaces(sourceFile, targetFile, regUser?.token ?? "");
            if(res?.data?.status?.statusCode===0){
                const similarityValue = res?.data?.message?.similarityValue;
                setSimilarity(similarityValue);
               
               
                if (Number(similarityValue) >= Number(regUser?.livenessConfidenceValue)) {
                    toast.success("ID successfully verified! Proceed to the next step.", { theme: "colored" });
                    markFormCompleted("smile");
                } else {
                    toast.error("ID similarity value is low. Please try again.", { theme: "colored" });
                    setIsLoading(false)
                }
            }
            else{
                toast.error("Failed to verify ID. Please try again.", { theme: "colored" });
                setIsLoading(false)
            }
           
        } catch (error) {
            console.error("Error comparing faces:", error);
            toast.error("Error verifying ID. Please try again.", { theme: "colored" });
        }
    };

    // First effect to fetch initial data
    useEffect(() => {
        let isMounted = true;

        const fetchInitialData = async () => {
            try {
                const response = await getAllData(regUser?.token ?? "");
                if (!isMounted) return;

                const newUserData = response.data.message;
                setUserData(newUserData);
                setRegUser(prevState => ({
                    ...prevState,
                    ...newUserData
                }));
            } catch (error) {
                console.error("Error fetching initial data:", error);
                if (isMounted) {
                    setError("Failed to load data");
                    toast.error("Failed to load data. Please try again.", { theme: "colored" });
                }
            }
        };

        fetchInitialData();
        return () => { isMounted = false; };
    }, []);

    // Second effect to process images after userData is set
    useEffect(() => {
        let isMounted = true;

        const processImages = async () => {
            if (!userData?.idProofDocument?.documentPath) return;

            try {
                const response = await fetch(userData.idProofDocument.documentPath);
                if (!response.ok) throw new Error('Failed to fetch image');

                const blob = await response.blob();
                const fileName = `${userData.idProofDocument.documentName || "document"}.${userData.idProofDocument.documentExtention}`;
                const file = new File([blob], fileName, { type: blob.type });

                if (!isMounted) return;

                const base64String = await convertFileToBase64(file);
                const sourceFile = converBase64ToImage(base64String, "sourceImage");
                const targetFile = converBase64ToImage(regUser?.livelinessImage || "", "targetImage");

                if (sourceFile && targetFile && isMounted) {
                    await compareFacesAndVerify(sourceFile, targetFile);
                }
            } catch (error) {
                console.error("Error processing images:", error);
                if (isMounted) {
                    setError("Error processing images");
                    toast.error("Error processing images. Please try again.", { theme: "colored" });
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        if (userData) {
            processImages();
        }

        return () => { isMounted = false; };
    }, [userData]);

    return (
        <div className="container text-center mt-5">
            <ToastContainer limit={1} />
            <h2 className="mb-4">Validation of ID Card</h2>
            {/* <p className="mb-3">Position your ID card in front of the camera and click "Capture Photo".</p> */}

            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center flex-column mb-4">
                    {userData?.idProofDocument?.documentPath && (
                        <div className="d-flex flex-row justify-content-center align-items-center gap-5">
                            <div>
                                <p className="fw-bold">Selfie Image</p>
                                <img
                                    src={regUser?.livelinessImage}
                                    alt="Selfie"
                                    className="img-thumbnail mb-3"
                                    style={{ width: '300px', height: 'auto' }}
                                />
                            </div>
                            <div>
                                <p className="fw-bold">ID Card</p>
                                <img
                                    src={userData.idProofDocument.documentPath}
                                    alt="ID Card"
                                    className="img-thumbnail mb-3"
                                    style={{ width: '300px', height: '180px', objectFit: "cover" }}
                                />
                            </div>
                        </div>
                    )}
                      <div className='d-flex flex-column align-items-center justify-content-center'>
                        <div>
                            {!similarity? <div className='mb-5'> <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> <div>Loading Similarity value...</div> </div> : <p className='fw-bold'>Similarity Value : {similarity}</p>}

                        </div>
                        <div className='d-flex flex-row gap-5'>
                            {  (Number(similarity) < Number(regUser?.livenessConfidenceValue))&&<>
                                <button className="btn btn-danger" onClick={async () => { navigate("/auth/primary-applicant") }}>
                                Reupload Id Proof
                            </button>
                            </> }
                            {  (Number(similarity) >= Number(regUser?.livenessConfidenceValue) )&&<>
                            <button className="btn" onClick={async () => { navigate("/auth/signature"); await uploadSelfieDoc(converBase64ToImage(regUser?.livelinessImage || "", "targetImage"), regUser?.token ?? ""); }}>
                                Continue
                            </button>
                            </>}
                        </div>
                    </div>
                </div>
            )}

            {error && <div className="text-danger mt-3">{error}</div>}
        </div>
    );
};

export default IDCardCapture;