import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Swal from "sweetalert2";
import axios from "axios";

// Define your API URL
const API_URL = process.env.REACT_APP_WEB_API_URL;

interface SearchCriteria {
  propertyName: string;
  propertyValue: string;
}

interface FetchProgramListPayload {
  searchCriteriaList: SearchCriteria[];
}

export interface Program {
  tenantId: string;
  programGuid: string;
  programName: string;
  logoGuid: string;
  category: string;
  headline: string;
  shortDescription: string;
  detailedDescription: string;
  eligibilityCriteria: string;
  website: string;
  email: string;
  status: number;
  createdDateString: string;
  logoUrl: string;
}

interface ProgramState {
  programs: Program[];
  program: Program;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: ProgramState = {
  programs: [],
  program: {
    tenantId: "",
    programGuid: "",
    programName: "",
    logoGuid: "",
    category: "",
    headline: "",
    shortDescription: "",
    detailedDescription: "",
    eligibilityCriteria: "",
    website: "",
    email: "",
    status: 0,
    createdDateString: "",
    logoUrl: "",
  },
  loading: false,
  error: null,
  success: false,
};

export type RuleGuid = {
  ruleGuid: string;
};

const getToken = (): string | null => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  return tokenData ? tokenData.token : null;
};

const apiCall = async (url: string, body: any) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const options = {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  };

  const response = await enhancedFetch(url, options);
  const data = await response.json();

  if (data.status.statusCode !== 0) {
    const errorMessage =
      data.status.messageDescription || data.message || "An error occurred.";

    throw new Error(errorMessage);
  }

  return data.results || data.message;
};

export const fetchProgramList = createAsyncThunk<
  Program[],
  FetchProgramListPayload,
  { rejectValue: string }
>("programList/view", async ({ searchCriteriaList }, { rejectWithValue }) => {
  try {
    const url = `${API_URL}/programList/find`;
    const response = await apiCall(url, { searchCriteriaList });
    return response as Program[];
  } catch (error) {
    // Handle errors and return a rejected value if necessary
    return rejectWithValue("Failed to fetch program list");
  }
});

export const findProgram = createAsyncThunk<
  Program,
  { programGuid: string },
  { rejectValue: string }
>("programList/find", async ({ programGuid }) => {
  const url = `${API_URL}/programList/view`;
  return await apiCall(url, { programGuid: programGuid });
});

export const messageVendor = createAsyncThunk<
  { statusCode: number; messageDescription: string },
  { message: string; uploadFiles: File[] | null; programGuid: string },
  { rejectValue: string }
>(
  "programList/messageVendor",

  async ({ message, uploadFiles, programGuid }, { rejectWithValue }) => {
    const token = getToken();

    if (!uploadFiles) {
      return rejectWithValue("No file selected.");
    }

    try {
      const formData = new FormData();
      uploadFiles.forEach((file) => {
        formData.append("uploadFiles", file);
      });
      formData.append("message", message);
      formData.append("programGuid", programGuid);
      //   formData.append("userId", userId);

      const response = await axios.post<{
        status: { statusCode: number; messageDescription: string };
      }>(`${API_URL}/programList/messageVendor`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status.statusCode === 0) {
        Swal.fire({
          title: "Confirmation!",
          text: "Message sent to vendor",
          icon: "success",
        });
      }

      return response.data.status;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

const programSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgramList.pending, (state) => {
        state.loading = false;
      })
      .addCase(
        fetchProgramList.fulfilled,
        (state, action: PayloadAction<Program[]>) => {
          state.programs = action.payload; // Update users with fetched data
          state.loading = false;
          state.error = null; // Clear any previous errors
        }
      )
      .addCase(fetchProgramList.rejected, (state, action) => {
        state.error = action.payload || "An error occurred.";
        state.loading = false;
      })
      .addCase(findProgram.pending, (state) => {
        state.loading = false; // Set loading to true
      })
      .addCase(
        findProgram.fulfilled,
        (state, action: PayloadAction<Program>) => {
          state.program = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(findProgram.rejected, (state, action) => {
        state.error = action.payload || "An error occurred."; // Handle error
        state.loading = false;
      });
  },
});

export default programSlice.reducer;
