import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { fetchInsuranceList } from "./insuranceSlice";
import { RootState } from "../../store";

const BusinessInsurance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseCurrency = sessionStorage.getItem("currency");

  const { insurances, loading, error } = useSelector(
    (state: RootState) => state.insurances
  );

  useEffect(() => {
    dispatch(
      fetchInsuranceList({
        // searchCriteriaList: [],
      } as any) as any
    );
  }, [dispatch]);

  const programs = [
    {
      title: "Old Mutual",
      subtitle: "(General liability insurance)",
      image:
        "http://t0.gstatic.com/images?q=tbn:ANd9GcR8g4g6nq6sSnD6sM6Kt1CYcGuL16GIYNlQJHotRsrIR6nv6k0Y",
      description: "ZAR 80,000 remaining",
      credits: "Valid till December 2025",
    },
    {
      title: "Hollard",
      subtitle: "(Workers' compensation)",
      image:
        "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSa5ptdmzMiEQxPxZXLOdN_l3FIgBty99Qpd1iOKm2n7uLzQNdW",
      description: "ZAR 120,000 remaining",
      credits: "Valid till January 2025",
    },
    {
      title: "Sanlam",
      subtitle: "(Business interruption insurance)",
      image:
        "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRDdkvqwU-Z3CSRXxKYdBoL4NVVDUEMkA7WVw1ajrf5_GqPK_xE",
      description: "ZAR 20,000 remaining",
      credits: "Valid till September 2025",
    },
    {
      title: "OUTsurance Holdings",
      subtitle: "(Product liability insurance)",
      image:
        "http://t1.gstatic.com/images?q=tbn:ANd9GcRpcmYXiyT7yD7caIt7ILf9y9Yt4GlnzPgUIz9VDVd_VZsIa4m6",
      description: "ZAR 89,000 remaining",
      credits: "Valid till October 2025",
    },
    {
      title: "Bryte",
      subtitle: "(Cyber liability insurance)",
      image:
        "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRxY5UjKANLqgbdcOUEhcboCIU-vNXBv91v3a4Dw3jWwvZGWWdT",
      description: "ZAR 40,000 remaining",
      credits: "Valid till April 2025",
    },
  ];
  return (
    <>
      <h4>Business Insurance</h4>
      <div className="card p-5">
        <div className="d-flex flex-end">
          <button
            type="button"
            className="btn me-3"
            onClick={() => {
              navigate("/insurance-buy");
            }}
          >
            Buy
          </button>
        </div>

        <div className="container py-5">
          <div className="row g-10">
            {insurances?.map((inc, index) => (
              <div key={index} className="col-12 col-md-6 col-lg-4">
                <div
                  className="card shadow p-4 cursor-pointer"
                  style={{ maxWidth: "350px", margin: "auto" }}
                  onClick={() => {
                    navigate("/insurance-details", {
                      state: { insurance: inc },
                    });
                  }}
                >
                  <div className="d-flex flex-row gap-3">
                    <div style={{ height: "50px", width: "50px" }}>
                      <img
                        className="rounded"
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        src={inc?.logoUrl}
                        alt={inc?.bizzInsuranceName}
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <div className="fw-bold fs-6">
                        {inc.bizzInsuranceName}
                      </div>
                      <div className="fw-bold fs-8 text-gray-600">
                        ({inc?.headline})
                      </div>
                    </div>
                  </div>
                  <hr
                    className="text-gray-600"
                    style={{ borderTop: "1px dashed" }}
                  />
                  <div className="fw-bold">{`${baseCurrency} ${inc?.uncoverdValue} remaining`}</div>
                  <div className="mt-2 fs-8 text-gray-600">
                    {`Valid till ${inc?.validTill}`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInsurance;
