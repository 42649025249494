import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../core/Auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { docUpload, getAllData } from "./api";
import { useFormCompletion } from "./Stepper";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

interface BusinessDoc {
  documentTypes: number[];
  documentPath: string;
  documentExtension: string;
}
interface FormValues {
  license: File | undefined;
  moa: File | undefined;
  tax: File | undefined;
}

const Documents = () => {
  const [loading, setLoading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [extension, setExtension] = useState("");
  const { regUser, setRegUser } = useAuth();
  const [showInput, setShowInput] = React.useState(false);
  const [showTaxInput, setShowTaxInput] = React.useState(false);
  const [showMoaInput, setShowMoaInput] = React.useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const { markFormCompleted } = useFormCompletion();

  const maxSize = 3 * 1024 * 1024;

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    validateOnChange:false,
    validateOnBlur:false,
    initialValues: {
      license: undefined,
      moa: undefined,
      tax: undefined,
    },
    validationSchema: Yup.object().shape({
      license: Yup.mixed()
        .test("fileType", "Only PDF and images are allowed", (value) => {
          if (!value) return true;
          const file = value as File;
          const validExtensions = [
            ".pdf",
            ".jpg",
            ".jpeg",
            ".png",
            ".svg",
            ".gif",
            ".bmp",
            ".webp",
            ".tiff",
          ];
          const extension = file.name
            ? file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
            : "";
          return validExtensions.includes(extension);
        })
        .test(
          "fileSize",
          "File size must be less than or equal to 3 MB",
          (value) => {
            if (!value) return true;
            const file = value as File;
            return file.size <= maxSize;
          }
        )
        .test("required", function (value) {
          if (regUser?.reapply) {
            if (regUser?.reapplyParams?.tradeLicenceDocument) {
              return (
                !!value ||
                this.createError({
                  message: "Please upload a valid license document",
                })
              );
            }
            if (formik?.values?.license === undefined || null) {
              return this.createError({
                message: "License document is required",
              });
            }
            
          }
          return (
            !!value ||
            this.createError({ message: "License document is required" })
          );
        })
        .nullable(),

      moa: Yup.mixed()

        .test("fileType", "Only PDF and images are allowed", (value) => {
          if (!value) return true;
          const file = value as File;
          const validExtensions = [
            ".pdf",
            ".jpg",
            ".jpeg",
            ".png",
            ".svg",
            ".gif",
            ".bmp",
            ".webp",
            ".tiff",
          ];
          const extension = file.name
            ? file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
            : "";
          return validExtensions.includes(extension);
        })
        .test(
          "fileSize",
          "File size must be less than or equal to 3 MB",
          (value) => {
            if (!value) return true;
            const file = value as File;
            return file.size <= maxSize;
          }
        )
        .test("required", function (value) {
          if (regUser?.reapply) {
            if (regUser?.reapplyParams?.aoaMoaDocument) {
              return (
                !!value ||
                this.createError({
                  message: "Please upload a valid MOA document",
                })
              );
            }
            if (formik?.values?.moa === undefined || null) {
              return this.createError({ message: "MOA document is required" });
            }
            
          }
          return (
            !!value || this.createError({ message: "MOA document is required" })
          );
        })
        .nullable(),

      tax: Yup.mixed()
        .nullable()
        .test("fileType", "Only PDF and images are allowed", (value) => {
          if (!value) return true;
          const file = value as File;
          const validExtensions = [
            ".pdf",
            ".jpg",
            ".jpeg",
            ".png",
            ".svg",
            ".gif",
            ".bmp",
            ".webp",
            ".tiff",
          ];
          const extension = file.name
            ? file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
            : "";
          return validExtensions.includes(extension);
        })
        .test(
          "fileSize",
          "File size must be less than or equal to 3 MB",
          (value) => {
            if (!value) return true;
            const file = value as File;
            return file.size <= maxSize;
          }
        )
        .test("required", function (value) {
          if (regUser?.reapply) {
            if (regUser?.reapplyParams?.taxDocument) {
              return (
                !!value ||
                this.createError({
                  message: "Please upload a valid tax document",
                })
              );
            }
            if (formik?.values?.tax === undefined || null) {
              return this.createError({ message: "Tax document is required" });
            }
            
          }
          return (
            !!value || this.createError({ message: "Tax document is required" })
          );
        }),
    }),

    onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);

      try {
        if (!regUser?.reapply && formik.values) {
          const data = await docUpload(
            values?.license,
            values?.moa,
            values?.tax,
            regUser?.token ?? ""
          );

          if (data?.data?.status?.statusCode === 0) {
            markFormCompleted("documents");
            navigate("/auth/other-documents");
            
            
            
            
          }
        } else if (
          regUser?.reapply &&
          (formik?.values?.license ||
            formik?.values?.tax ||
            formik?.values?.moa)
        ) {
          const data = await docUpload(
            formik?.values?.license ?? values?.license,
            formik?.values?.moa ?? values?.moa,
            formik?.values?.tax ?? values?.tax,
            regUser?.token ?? ""
          );

          if (data?.data?.status?.statusCode === 0) {
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            markFormCompleted("documents");
            navigate("/auth/other-documents");
            resetForm();
          }
        } else {
          markFormCompleted("documents");
          navigate("/auth/other-documents");
          resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
        setSubmitting(false);
        setShowInput(false)
        setShowTaxInput(false)
        setShowMoaInput(false)
      }
    },
  });

  const handlePreview = (documentPath: string, documentExtension: string) => {
    setPreviewSrc(documentPath);
    setExtension(documentExtension);
  };

  useEffect(() => {
    getAllData(regUser?.token ?? "").then((res) => {
      setRegUser((prevState) => ({
        ...prevState,
        ...res.data.message,
      }));
    });
  }, []);

  const handleCancel = () => {
    setShowInput(true);
    formik.setFieldValue("license", null);
    const fileInput = document.getElementById(
      "licenseFile"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const handleCancelTax = () => {
    setShowTaxInput(true);
    const fileInput = document.getElementById("taxFile") as HTMLInputElement; 
    formik.setFieldValue("tax", null);
    if (fileInput) {
      fileInput.value = ""; 
    }
  };
  const handleCancelMoa = () => {
    setShowMoaInput(true);
    const fileInput = document.getElementById("moaFile") as HTMLInputElement; 
    formik.setFieldValue("moa", null);
    if (fileInput) {
      fileInput.value = ""; 

    }
  };

  useEffect(() => {
    
    if (regUser?.businessDocs?.length) {
      regUser.businessDocs.forEach((item) => {
        let inputId = "";
        let formikField = "";

        
        if (item.documentTypes?.[0] === 16 && !regUser?.reapplyParams?.tradeLicenceDocument) {
          inputId = "licenseFile"; 
          formikField = "license";
        } else if (item.documentTypes?.[0] === 19  && !regUser?.reapplyParams?.taxDocument) {
          inputId = "taxFile"; 
          formikField = "tax";
        } else if (item.documentTypes?.[0] === 18  && !regUser?.reapplyParams?.aoaMoaDocument) {
          inputId = "moaFile"; 
          formikField = "moa";
        }

        
        if (inputId) {
          const fileUrl = item.documentPath;

          
          fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const fileName = `${item?.documentName || "document"}.${item?.documentExtention || "pdf"
                }`;
              const file = new File([blob], fileName, { type: blob.type });

              const fileInput = document.getElementById(
                inputId
              ) as HTMLInputElement;
              if (fileInput) {
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(file);
                fileInput.files = dataTransfer.files;

                const event = new Event("change", { bubbles: true });
                fileInput.dispatchEvent(event);

                
                formik.setFieldValue(formikField, file);
              }
            });
        }
      });
    }
  }, [getAllData]);

  console.log(formik?.values);



  return (
    <>
      <div className="d-flex flex-column justify-content-center w-100">
        <p className="fs-2 fw-bold text-center">
          Please upload the following documents
        </p>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="p-10">
          <div className={`mb-10 ${regUser?.reapplyParams?.tradeLicenceDocument ? "form-control is-invalid" : ""}`}>
              <label className="form-label">
                Trade License
                {regUser?.reapplyParams?.tradeLicenceDocument ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-add-money">
                        {regUser?.reapplyParams?.tradeLicenceDocument}
                      </Tooltip>
                    }
                  >
                    <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                      <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                    </span>
                  </OverlayTrigger>
                ) : null}
              </label>

              {/* Show preview if reapply is true and file exists */}
              { formik.values?.license && !showInput ? (
                <div className="d-flex flex-start">
                  <div className="position-relative">
                    <span
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        cursor: "pointer", 
                        color: "red",
                        fontSize: "20px",
                      }}
                      onClick={handleCancel} 
                    >
                      &times;
                    </span>

                    <img
                      src={
                        formik?.values?.license?.type === "application/pdf"
                          ? toAbsoluteUrl("/media/subicons/pdf.png")
                          : formik?.values?.license
                            ? URL.createObjectURL(formik?.values?.license)
                            : ""
                      }
                      onClick={() => {
                        const fileUrl = formik?.values?.license
                          ? URL.createObjectURL(formik.values.license)
                          : "";
                        const isPdf =
                          formik?.values?.license?.type === "application/pdf";
                        const extension = isPdf
                          ? "pdf"
                          : formik?.values?.license?.name?.split(".").pop() ||
                          "";
                        handlePreview(fileUrl, extension); 
                      }}
                        data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      className="mt-2"
                      alt="Trade Licence"
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        borderRadius: "5%",
                      }}
                    />
                  </div>
                </div>
                
              ) : (
                <input
                  type="file"
                  ref={fileInputRef}
                  id="licenseFile"
                  className={`form-control `}
                  onChange={(event) => {
                    const file = event.currentTarget.files?.[0];
                    formik.setFieldValue("license", file);
                    if(!regUser?.reapply){
                      setShowInput(false);
                    }
                    if (regUser?.reapplyParams?.tradeLicenceDocument) {
                      setRegUser((prevRegUser) => {
                        if (!prevRegUser) return prevRegUser;
                    
                        return {
                          ...prevRegUser,
                          reapplyParams: {
                            ...prevRegUser.reapplyParams,
                            tradeLicenceDocument: "",
                          },
                        };
                      });
                    }
                    
                    
                  }}
                  accept="image/*,.pdf"
                />
              )}


              {/* Show validation error */}
              {formik.touched.license && formik.errors.license && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.license}</span>
                  </div>
                </div>
              )}
            </div>

            <div className={`mb-10 ${regUser?.reapplyParams?.taxDocument ? "form-control is-invalid" : ""}`}>
              <label className="form-label">
                Tax Documents
                {regUser?.reapplyParams?.taxDocument ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-add-money">
                        {regUser?.reapplyParams?.taxDocument}
                      </Tooltip>
                    }
                  >
                    <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                      <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                    </span>
                  </OverlayTrigger>
                ) : null}
              </label>

              {/* Show preview if reapply is true and file exists */}
              { formik.values?.tax && !showTaxInput ? (
                <div className="d-flex flex-start">
                  <div className="position-relative">
                    <span
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        cursor: "pointer",
                        color: "red",
                        fontSize: "20px",
                      }}
                      onClick={handleCancelTax} 
                    >
                      &times;
                    </span>

                    <img
                      src={
                        formik?.values?.tax?.type === "application/pdf"
                          ? toAbsoluteUrl("/media/subicons/pdf.png")
                          : formik?.values?.tax
                            ? URL.createObjectURL(formik?.values?.tax)
                            : ""
                      }
                      onClick={() => {
                        const fileUrl = formik?.values?.tax
                          ? URL.createObjectURL(formik.values.tax)
                          : "";
                        const isPdf =
                          formik?.values?.tax?.type === "application/pdf";
                        const extension = isPdf
                          ? "pdf"
                          : formik?.values?.tax?.name?.split(".").pop() || "";
                        handlePreview(fileUrl, extension); 
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      className="mt-2"
                      alt="Tax Document"
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        borderRadius: "5%",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <input
                  type="file"
                  ref={fileInputRef}
                  id="taxFile"
                  className={`form-control `}
                  onChange={(event) => {
                    const file = event.currentTarget.files?.[0];
                    formik.setFieldValue("tax", file);
                    if(!regUser?.reapply){
                      setShowTaxInput(false);
                    }
                  }}
                  accept="image/*,.pdf"
                />
              )}

              {/* Show validation error */}
              {formik.touched.tax && formik.errors.tax && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.tax}</span>
                  </div>
                </div>
              )}
            </div>


            <div className={` ${regUser?.reapplyParams?.aoaMoaDocument ? "form-control is-invalid" : ""}`}>
              <label className="form-label">
                AOA/MOA
                {regUser?.reapplyParams?.aoaMoaDocument ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-add-money">
                        {regUser?.reapplyParams?.aoaMoaDocument}
                      </Tooltip>
                    }
                  >
                    <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                      <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                    </span>
                  </OverlayTrigger>
                ) : null}
              </label>

              {/* Show preview if reapply is true and file exists */}
              {formik.values?.moa && !showMoaInput ? (
                <div className="d-flex flex-start">
                  <div className="position-relative">
                    <span
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        cursor: "pointer",
                        color: "red",
                        fontSize: "20px",
                      }}
                      onClick={handleCancelMoa} 
                    >
                      &times;
                    </span>

                    <img
                      src={
                        formik?.values?.moa?.type === "application/pdf"
                          ? toAbsoluteUrl("/media/subicons/pdf.png")
                          : formik?.values?.moa
                            ? URL.createObjectURL(formik?.values?.moa)
                            : ""
                      }
                      onClick={() => {
                        const fileUrl = formik?.values?.moa
                          ? URL.createObjectURL(formik.values.moa)
                          : "";
                        const isPdf =
                          formik?.values?.moa?.type === "application/pdf";
                        const extension = isPdf
                          ? "pdf"
                          : formik?.values?.moa?.name?.split(".").pop() || "";
                        handlePreview(fileUrl, extension); 
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      className="mt-2"
                      alt="AOA/MOA Document"
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        borderRadius: "5%",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <input
                  type="file"
                  ref={fileInputRef}
                  id="moaFile"
                  className={`form-control`}
                  onChange={(event) => {
                    const file = event.currentTarget.files?.[0];
                    formik.setFieldValue("moa", file);
                    if(!regUser?.reapply){
                      setShowMoaInput(false);
                    }
                  }}
                  accept="image/*,.pdf"
                />
              )}

              {/* Show validation error */}
              {formik.touched.moa && formik.errors.moa && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.moa}</span>
                  </div>
                </div>
              )}
            </div>

          </div>
          <div className="p-10">
            <button
              type="submit"
              className="btn w-100"
              disabled={formik.isSubmitting || loading}
            >
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span role="status" aria-hidden="true"></span>
                </div>
              ) : (
                <div>Proceed</div>
              )}
            </button>
          </div>
        </form>

        {/* Modal for Preview */}
      </div>
      <div
        className="modal fade w-100"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered w-100"
          role="document"
        >
          {previewSrc && (
            <>
              {extension === "pdf" ? (
                <div
                  className="modal-content w-100 p-0 m-0"
                  style={{ position: "absolute", right: "50%" }}
                >
                  <div className="modal-body p-0 m-0">
                    <iframe
                      src={previewSrc}
                      title="PDF Preview"
                      style={{ width: "200%", height: "80vh" }} 
                    ></iframe>
                  </div>
                </div>
              ) : (
                <img
                  src={previewSrc}
                  alt="Document Preview"
                  style={{
                    width: "300px", 
                    height: "auto", 
                    borderRadius: "10px",
                    objectFit: "fill",
                    marginLeft: "20%",
                    
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Documents;
