import React from "react";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { messageVendor } from "./programsSlice";
import { Modal } from "bootstrap";

const ProgramDetails = () => {
  const dispatch = useDispatch();

  const { program, loading, error } = useSelector(
    (state: RootState) => state.program
  );

  const formik = useFormik({
    initialValues: {
      message: "",
      attachments: [] as File[],
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Message is required"),
      attachments: Yup.array().test(
        "min-files",
        "At least one file is required",
        (value) => value && value.length > 0
      ),
      // .test(
      //   "is-pdf",
      //   "Only PDF files are allowed",
      //   (value) =>
      //     value && value.every((file) => file.type === "application/pdf")
      // ),
    }),
    // enableReinitialize: true,
    onSubmit: async (values) => {
      dispatch(
        messageVendor({
          message: values.message,
          uploadFiles: values.attachments,
          programGuid: program?.programGuid,
        }) as any
      ).then(() => {
        formik.resetForm();
        const modal = Modal.getInstance("#kt_modal_message");
        if (modal) {
          modal.hide();
        }
      });
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const currentAttachments = formik?.values?.attachments || [];
    const maxFiles = 10;

    if (currentAttachments.length >= maxFiles) {
      // Stop further processing if the limit is already reached
      return;
    }

    const filesArray = Array.from(event.target.files);
    const remainingSlots = maxFiles - currentAttachments.length;

    // Take only the files that fit within the limit
    const filesToAdd = filesArray.slice(0, remainingSlots);

    formik.setFieldValue("attachments", [...currentAttachments, ...filesToAdd]);

    event.target.value = ""; // Clear the file input
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = formik.values.attachments.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("attachments", updatedFiles);
  };

  return (
    <>
      <h4>Program Details</h4>
      <div className="card p-5 mt-5">
        <div className="card d-flex flex-row gap-5 mt-5">
          <div className="container">
            <div key={1} className="" style={{ width: "650px" }}>
              <div className="card shadow p-5">
                <div className="d-flex flex-row gap-3">
                  <div style={{ height: "50px", width: "50px" }}>
                    <img
                      className="rounded"
                      style={{
                        height: "50px",
                        width: "50px",
                        objectFit: "contain",
                      }}
                      src={program?.logoUrl}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <div className="fw-bold fs-6">{program?.programName}</div>
                    <div className="fw-bold fs-8 text-gray-600">
                      ({program?.category})
                    </div>
                  </div>
                </div>
                <hr
                  className="text-gray-600"
                  style={{ borderTop: "1px dashed" }}
                />
                <div className="fw-bold">{program?.headline}</div>
                <div className="mt-2 fs-7 text-gray-600">
                  {program?.shortDescription}
                </div>
                {/* <div className="mt-5 fs-7 text-gray-600" style={{}}>
                  {program?.detailedDescription}
                </div> */}
                <div
                  className="mt-5 fs-7 text-gray-600"
                  style={{ whiteSpace: "pre-wrap" }} // Ensures line breaks are handled properly
                  dangerouslySetInnerHTML={{
                    __html: program?.detailedDescription.replace(
                      /\n/g,
                      "<br />"
                    ),
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="container ">
            <div className="d-flex flex-column gap-10">
              <div className="card shadow p-10">
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_message"
                >
                  Message Vendor
                </button>
                <button
                  className="btn btn-light-primary mt-5"
                  onClick={() => {
                    // window.open(program?.website, "_blank");
                    window.open(
                      program?.website,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  Visit Website
                </button>
              </div>
              <div className="card shadow p-5">
                <div className="fw-bold ms-2 ">Eligibility Requirement</div>
                <div>
                  <ul className="mt-2 text-gray-600 fs-7">
                    {program?.eligibilityCriteria
                      ?.split("\n")
                      .filter((line) => line.trim() !== "")
                      .map((line, index) => <li key={index}>{line.trim()}</li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" tabIndex={-1} id="kt_modal_message">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Message Vendor</h5>
              </div>
              <div className="modal-body">
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Message <span className="fs-8"></span>
                  </label>
                  <textarea
                    // type="text"
                    name="message"
                    maxLength={500}
                    className="form-control"
                    placeholder="Message..."
                    style={{ minHeight: "150px" }}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <div className="fv-plugins-message-container">
                      <span className="text-danger">
                        {formik.errors.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="container mt-4 card shadow p-5">
                  <h5 className="fw-bold required">Upload a File</h5>
                  <p className="text-muted small">File upload description</p>

                  {/* Drag and Drop */}
                  <div className="border border-2 rounded-3 p-4 mb-3 text-center">
                    <input
                      type="file"
                      className="form-control d-none"
                      id="fileInput"
                      multiple
                      onChange={handleFileChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      htmlFor="fileInput"
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="display-4 mb-2">+</div>
                      <span className="fw-bold">Drop your files here</span>
                      <span className="text-primary small">
                        Browse file from your computer(Maximum 10 files)
                      </span>
                    </label>
                  </div>

                  {/* Display Uploaded Files */}
                  {formik.values.attachments.length > 0 &&
                    formik.values.attachments.map((file, index) => (
                      <div className="mb-3 card p-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <i className="bi bi-file-earmark me-2 fs-4"></i>
                            <div>
                              <div className="fw-bold">{file.name}</div>
                              <div className="small text-muted">
                                {(file.size / 1024).toFixed(1)} KB
                              </div>
                            </div>
                          </div>
                          <div>
                            {/* Trash Icon to Remove File */}
                            <i
                              className="bi bi-trash text-danger fs-4"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveFile(index)}
                            ></i>
                          </div>
                        </div>
                        {/* <ProgressBar now={100} variant="success" className="mt-2" /> */}
                      </div>
                    ))}

                  {formik.touched.attachments && formik.errors.attachments && (
                    <div className="text-danger small">
                      {Array.isArray(formik.errors.attachments)
                        ? formik.errors.attachments.map((error, index) =>
                            typeof error === "string" ? (
                              <div key={index}>{error}</div>
                            ) : null
                          )
                        : typeof formik.errors.attachments === "string"
                          ? formik.errors.attachments
                          : null}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramDetails;
