import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";

import notificationReducer from "../firebase/NotificationSlice";
import roleReducer from "./pages/settings/RuleSettingsSlice";
import vendorReducer from "./pages/IncomingInvoice/vendorSlice";
import ruleDashboardSlice from "./pages/settings/RuleDashboardSlice";
import documentRoomSlice from "./pages/documentRoom/documentRoomSlice";
import settingsSlice from "./pages/documentRoom/settingSlice";
import shareHistorySlice from "./pages/documentRoom/shareHistorySlice";
import collectionsSlice from "./pages/documentRoom/collectionSlice";
import programSlice from "./pages/SMEPrograms/programsSlice";
import insuranceSlice from "./pages/Insurance/insuranceSlice";

const RESET_STATE = "RESET_STATE";

export const resetState = () => ({
  type: RESET_STATE,
});

const appReducer = combineReducers({
  notificationLogs: notificationReducer,
  role: roleReducer,
  vendors: vendorReducer,
  folders: documentRoomSlice,
  shareHistory: shareHistorySlice,
  docAppList: shareHistorySlice,
  emailList: shareHistorySlice,
  collections: collectionsSlice,
  init: collectionsSlice,
  settings: settingsSlice,
  uploadResponse: settingsSlice,
  banks: vendorReducer,
  ruleDashboard: ruleDashboardSlice,
  programs: programSlice,
  program: programSlice,
  insurances: insuranceSlice,
  insuarance: insuranceSlice,
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: Action<string>
) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
