import { useState, useEffect, useRef } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import deleteIcon from "../../../../_metronic/assets/Icons/Group.png";
import { Modal } from "bootstrap";
import { TransferAuthentication } from "./AddMoneyAuthentication";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import { transferData } from "./TransferData";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { RuleMessage, User } from "../TransferMoney/TransferModal";
import { ThreeDots } from "react-loader-spinner";

interface Account {
  tenantId: string;
  functionCode: string;
  primaryRefId: string;
  primaryFundId: string;
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}

interface accDetails {
  currency: string;
  iconCode: string;
  goal: string;
  goalLimit: number;
  creditCondition: string;
  creditType: string;
  creditValue: number;
  creditFrequency: string;
  subAccountType: string;
  subAccountName: string;
  subAccountNumber: string;
  subAccountStatus: number;
  balance: number;
  createdDate: string;
}

interface FormValues {
  selectedAccount: string;
  amount: string;
  approver: string;
}
export function TransferModal({
  AccData,
  clicked,
}: {
  AccData: Account | null;
  clicked: boolean;
}) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;
  const [authority, setAuthority] = useState(0);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState(" ");
  const [button, setButton] = useState("Next");
  const [acc, setAcc] = useState("");
  const [accounts, setAccounts] = useState<accDetails[]>([]);
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const [approvers, setApprovers] = useState<User[]>([]);

  const [ruleData, setRuleData] = useState<RuleMessage | null>(null);
  const [loading, setLoading] = useState(false);

  transferData.accountNumber = AccData?.accountNumber || ""; // Assign an empty string if 'acc' is undefined
  transferData.payerAccountNumber = acc || "";
  transferData.amount = inputValue || "";
  transferData.payerType = "Sub Account";
  transferData.payeeAccountNumber = AccData?.accountNumber || "";
  transferData.payeeType = "Main Account"; // Assign an empty string if 'accData?.subAccountType' is undefined
  transferData.remarks = "trf";

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener("shown.bs.modal", () => {
        const selectField = modalRef?.current?.querySelector(
          "select"
        ) as HTMLSelectElement | null;
        if (selectField) {
          selectField.focus();
        }
      });

      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleTransferCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleTransferCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleDeleteConfirmation = () => {
    const modalElement = document.getElementById("transfer-authentication");
    const modal = new Modal(modalElement as Element);
    modal.show();
    setButton("Next");
  };

  const handleTransferCloseModal = () => {
    formikRef.current?.resetForm();
    setButton("Next");
    setLoading(false);
    setLoading(false);
    setRuleData(null);
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    // handleDeleteConfirmation()
  };

  const SubAccounts = async () => {
    try {
      const url = `${API_URL}/subAccount/getSubAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: AccData?.accountNumber,
        accountType: (AccData?.primaryFundId || "").match(/-(\d+)-/)?.[1],
        currency: AccData?.currency,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.message.subAccounts);
    } catch (error) {
      console.log(error);
    }
  };

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (clicked) {
      SubAccounts();
      // ApproverList(userDet?.userDetails?.zarLimit);
    }
  }, [AccData, clicked]);

  // useEffect(() => {
  //   const debounceTimeout = setTimeout(() => {
  //     if (+inputValue) {
  //       ApproverList(inputValue);
  //     }
  //   }, 500);

  //   return () => clearTimeout(debounceTimeout);
  // }, [inputValue]);

  useEffect(() => {
    // if (userDet?.userDetails?.authority?.includes("2")) {
    //   setButton("Initiate");
    // }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  const getRules = async () => {
    try {
      const url = `${API_URL}/transaction/checkTxnDetails`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        amount: transferData?.amount,
        currency: AccData?.currency,
        transactionType: "TRANSFER_SUBACCOUNT_TO_BANK",
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setRuleData(data?.message);
      if (data.message?.processingType === "PROCESS") {
        setButton("Pay Now");
      }
      if (data.message?.processingType === "SEND_FOR_APPROVAL") {
        setButton("Initiate");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <style>
        {`
          .custom-input::-webkit-outer-spin-button,
          .custom-input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        `}
      </style>
      <div
        className="modal fade"
        tabIndex={-1}
        id="transfer_modal"
        ref={modalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered "
          // style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-header d-flex flex-row justify-content-between ">
              <h3 className="fw-bold m-0">Add Money</h3>
              {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
             
              </div> */}
            </div>
            <div
              className="modal-body w-500px"
              style={{
                display: authority === 3 ? "flex" : "",
                minHeight: "300px",
                alignItems: authority === 3 ? "center" : "normal",
                justifyContent: authority === 3 ? "center" : "normal",
              }}
            >
              {authority === 3 ? (
                <div>
                  <p className="fw-bold text-center text-danger">
                    You don't have access to this feature.
                  </p>

                  <p className="fw-bold text-center text-danger">
                    Please contact admin.
                  </p>
                </div>
              ) : (
                <div className="d-flex flex-column gap-5 px-5">
                  <Formik
                    innerRef={formikRef}
                    initialValues={{
                      selectedAccount: "",
                      amount: "",
                      approver: "",
                    }}
                    validateOnBlur={true}
                    validateOnChange={true}
                    validationSchema={Yup.object().shape({
                      selectedAccount: Yup.string().required(
                        "Please select an account"
                      ),
                      amount: Yup.number()
                        .required("Please enter an amount")
                        .positive("Amount must be positive")
                        .typeError("Please enter a valid number")
                        .test({
                          name: "balanceCheck",
                          test: function (value) {
                            const { selectedAccount } = this.parent; // Get the selected account

                            // Check if an account is selected before performing the balance check
                            if (!selectedAccount) {
                              return true; // Return true to indicate no error if no account is selected
                            }

                            const selectedAccountData = accounts.find(
                              (account) =>
                                account.subAccountName === selectedAccount
                            ); // Find the selected account in the accounts array

                            // Check if the amount is less than or equal to the selected account balance
                            const isBalanceValid =
                              selectedAccountData &&
                              value <= selectedAccountData.balance;

                            // Check if the total amount (including the service charge) exceeds the balance
                            const totalAmount =
                              value + (ruleData?.serviceChargeAmt || 0);
                            const isTotalAmountValid =
                              selectedAccountData &&
                              totalAmount <= selectedAccountData.balance;

                            // Return true if both conditions are valid
                            return isBalanceValid && isTotalAmountValid;
                          },
                          message:
                            "Amount must be less than or equal to the selected account balance",
                        }),
                    })}
                    onSubmit={async (values, { resetForm }) => {
                      try {
                        if (button === "Next") {
                          getRules();
                          setLoading(true);
                          return;
                        }

                        if (button === "Initiate") {
                          transferData.beneficiaryName =
                            AccData?.accountNumber || "";
                          transferData.currency = baseCurrency || "";
                          transferData.initiatedTxn = true;
                        }
                        // Assuming handleTransferCloseModal is an asynchronous function
                        await handleTransferCloseModal();

                        // Assuming handleDeleteConfirmation is an asynchronous function
                        await new Promise((resolve) =>
                          setTimeout(resolve, 100)
                        );
                        await handleDeleteConfirmation();

                        resetForm();
                      } catch (error) {
                        console.error("An error occurred:", error);
                        // Handle any error gracefully here
                      }
                    }}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="mb-5">
                          <label
                            className="form-label"
                            htmlFor="selectedAccount"
                          >
                            Select Account
                          </label>
                          <Field name="selectedAccount">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <>
                                <select
                                  {...field}
                                  className="form-select"
                                  value={field.value}
                                  onChange={(e) => {
                                    setAcc(e.target.value);
                                    form.setFieldValue(
                                      "selectedAccount",
                                      e.target.value
                                    );
                                  }}
                                  autoFocus={true}
                                >
                                  <option value="" disabled>
                                    Select Account
                                  </option>
                                  {accounts.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item?.subAccountName}
                                    >
                                      {item?.subAccountName}
                                      &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;{" "}
                                      {item.currency}{" "}
                                      {item?.balance.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </option>
                                  ))}
                                </select>
                              </>
                            )}
                          </Field>

                          <ErrorMessage
                            component="div"
                            name="selectedAccount"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label " htmlFor="amount">
                            Enter Amount
                          </label>
                          <Field name="amount">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <>
                                <span
                                  className="fs-6 fw-bold text-gray-700"
                                  style={{
                                    position: "absolute",
                                    padding: "12.5px",
                                    right: "410px",
                                    marginTop: "25px",
                                  }}
                                >
                                  {AccData?.currency}
                                </span>
                                <input
                                  {...field}
                                  type="text"
                                  // autoFocus={true}
                                  className={`custom-input form-control ${
                                    form.touched.amount && form.errors.amount
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  // maxLength={12}
                                  // pattern="[0-9]*"
                                  // placeholder="$"
                                  // value={inputValue}
                                  style={{ paddingLeft: "60px" }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // If the value is empty, set the previous value to an empty string
                                    if (
                                      inputValue === "" ||
                                      inputValue === "0"
                                    ) {
                                      form.handleChange(e); // Trigger Formik's change handler
                                      form.validateField("amount");
                                      form.setFieldValue("amount", "");
                                      setInputValue("");
                                      return;
                                    }
                                    // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                                    if (
                                      /^\d{1,12}(\.\d{0,2})?$/.test(inputValue)
                                    ) {
                                      form.handleChange(e); // Trigger Formik's change handler
                                      form.validateField("amount");
                                      form.setFieldValue("amount", inputValue);
                                      setInputValue(inputValue);
                                    }
                                    setButton("Next");
                                    setRuleData(null);
                                    setLoading(false);

                                    // if (inputValue.length <= 12) {
                                    //   form.handleChange(e); // Trigger Formik's change handler
                                    //   form.validateField("amount");
                                    //   form.setFieldValue("amount", inputValue);
                                    //   setInputValue(inputValue);
                                    // }
                                  }}
                                />
                              </>
                            )}
                          </Field>

                          <ErrorMessage
                            component="div"
                            name="amount"
                            className="text-danger text-nowrap"
                          />
                        </div>

                        {loading && (
                          <div className="d-flex flex-row justify-content-center">
                            <ThreeDots
                              visible={true}
                              height="80"
                              width="80"
                              color="#0000FF"
                              radius="9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                          </div>
                        )}
                        {ruleData && !loading && (
                          <div className="card shadow-sm p-5 mt-2 mb-5">
                            <p>
                              {" "}
                              <span className="fw-bold">Service Charge :</span>
                              &nbsp;
                              <span>
                                {ruleData?.serviceCharge}&nbsp;
                                {AccData?.currency}
                              </span>
                            </p>

                            <p>
                              <span className="fw-bold">
                                Service Charge Tax :{" "}
                              </span>
                              &nbsp;
                              <span>
                                {ruleData?.serviceChargeTax}&nbsp;
                                {AccData?.currency}
                              </span>
                            </p>
                            <p>
                              <span className="fw-bold">
                                Service Charge Amount :{" "}
                              </span>
                              &nbsp;
                              <span>
                                {ruleData?.serviceChargeAmt}&nbsp;
                                {AccData?.currency}
                              </span>
                            </p>
                            <p>
                              <span className="fw-bold">Total : </span>&nbsp;
                              <span>
                                {Number(transferData?.amount) +
                                  Number(ruleData?.serviceChargeAmt)}
                                &nbsp;{AccData?.currency}
                              </span>
                            </p>
                            {ruleData?.processingType ===
                              "SEND_FOR_APPROVAL" && (
                              <p>
                                {" "}
                                <span className="fw-bold">
                                  This will be sent to {ruleData?.actorName}
                                  &nbsp;for approval
                                </span>
                              </p>
                            )}
                          </div>
                        )}

                        <div className="d-flex flex-row gap-5">
                          <button
                            type="submit"
                            className="btn rounded"
                            style={{
                              width: "100%",
                            }}
                            // disabled={
                            //   !values.selectedAccount ||
                            //   !values.amount ||
                            //   isNaN(Number(values.amount)) ||
                            //   Number(values.amount) <= 0 || // Ensure amount is greater than 0
                            //   (Number(values.amount) + (ruleData?.serviceChargeAmt || 0)) > (
                            //     accounts.find(account => account.subAccountName === values.selectedAccount)?.balance || 0
                            //   )
                            // }
                          >
                            {button}
                          </button>

                          <button
                            type="button"
                            className="btn btn-secondary rounded"
                            style={{
                              width: "100%",
                              color: "#246bfd",
                            }}
                            onClick={handleTransferCloseModal}
                          >
                            Cancel
                          </button>
                        </div>
                        {authority === 3 && (
                          <p className="text-danger fw-bold text-center">
                            You don't have the authority to perform this action
                          </p>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {<TransferAuthentication />}
    </>
  );
}
