/* eslint-disable react/jsx-no-target-blank */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import {
  Menus,
  Url,
} from "../../../../../app/pages/dashboard/DashboardWrapper";
import Authorize from "../../../../../app/modules/auth/components/Authorize";

type SetMenusType = Dispatch<SetStateAction<Menus[] | undefined>>;

// Define the type for Api object
interface ApiType {
  setMenus?: SetMenusType;
}

export const Api: ApiType = {};

const SidebarMenuMain = () => {
  // const menus = sessionStorage.getItem("menus");
  // const allMenus = menus ? JSON.parse(menus) : null;
  // const sideMenus: Menus[] = allMenus?.menus;

  const [sideMenus, setSideMenus] = useState<Menus[]>([]);

  const fetchMenus = () => {
    const menus = sessionStorage.getItem("menus");
    const allMenus = menus ? JSON.parse(menus) : null;
    const updatedMenus: Menus[] = allMenus?.menus || [];
    setSideMenus(updatedMenus);
  };

  useEffect(() => {
    fetchMenus(); // Initial fetch

    const handleMenusUpdated = () => fetchMenus(); // Fetch menus on custom event

    // Listen for the custom event
    window.addEventListener("menusUpdated", handleMenusUpdated);

    return () => {
      // Cleanup event listener
      window.removeEventListener("menusUpdated", handleMenusUpdated);
    };
  }, []);

  // const [menus, setMenus] = useState<Menus[] | undefined>([]);

  // assignSetMenus(setMenus);
  // hello

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="dashboard"
        title="Dashboard"
      // fontIcon="bi bi-faChevronCircleUp"
      />
      {sideMenus?.map((cat) => {
        return cat?.menus?.length > 1 ? (
          <Authorize
            key={cat?.categoryIndex}
            hasAnyPermission={
              cat?.menus?.map((menu) => `${menu.menuCode}|VIEW`) || []
            }
          >
            <SidebarMenuItemWithSub
              to="#"
              icon={cat?.catIconLink}
              title={cat?.menuCategory}
            >
              {cat.menus.map(
                (menu, index) =>
                  menu.menuName !== "Currency Conversion" && (
                    <Authorize
                      hasAnyPermission={[`${menu?.menuCode}|VIEW`]}
                      key={index}
                    >
                      <SidebarMenuItem
                        to={Url[menu?.menuCode]?.path || "#"}
                        title={menu.menuName}
                        hasBullet={true}
                      />
                    </Authorize>
                  )
              )}
            </SidebarMenuItemWithSub>
          </Authorize>
        ) : (
          <Authorize
            key={cat?.categoryIndex}
            hasAnyPermission={[`${cat?.menus[0]?.menuCode}|VIEW`]}
          >
            <SidebarMenuItem
              to={Url[cat?.menus[0]?.menuCode]?.path || "#"}
              icon={cat?.catIconLink}
              title={`${cat?.menus[0]?.menuName}`}
              fontIcon="bi-layers"
            />
          </Authorize>
        );
      })}



{/*       <SidebarMenuItemWithSub
        to="tax"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg
"
        title="Tax Manager"
      >
        <SidebarMenuItem
          to="tax/dashboard"
          title="Dashboard"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="tax/compliance"
          title="Compliance Calendar"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="tax/paymentcenter"
          title="Payment Center"
          hasBullet={true}
        />

        <SidebarMenuItem
          to="tax/analytics"
          title="Insights & Analytics"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="#"
          title="Reports"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="tax/settings"
          title="Settings"
          hasBullet={true}
        />

        <SidebarMenuItemWithSub
          to="#"

          // icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg"
          hasBullet={true}
          title="Documents"
        >
          <SidebarMenuItem
            to="tax/documentRoom/folder"
            title="Folders & Files"
            hasBullet={true}
          />

           <SidebarMenuItem
          to="documentRoom/files"
          title="Files"
          hasBullet={true}
        />
          <SidebarMenuItem
            to="tax/documentRoom/settings"
            title="Settings"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="tax/documentRoom/history"
            title="Shared history"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="tax/documentRoom/onDemandDoc"
            title="On demand documents"
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>  */}

      <SidebarMenuItem
        to="programs"
        title="Programs"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg"
        fontIcon="bi-layers"

      />

{/*       <SidebarMenuItem
        to="/insurance"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg"
        title="Business Insurance"
        fontIcon="bi-app-indicator"
      /> */}

{/*       <SidebarMenuItemWithSub
        to="expense-cards"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg
"
        title="Expense Cards"
      >
        <SidebarMenuItem
          to="/expense-cards/dashboard"
          hasBullet={true}
          title="Dashboard"
         
        />
         <SidebarMenuItem
          to="/expense-cards/expenses"
          hasBullet={true}
          title="Expenses"
         
        />
          <SidebarMenuItem
          to="/expense-cards/people"
          hasBullet={true}
          title="People"
         
        />
          <SidebarMenuItem
          to="/expense-cards/cards"
          hasBullet={true}
          title="Cards"
         
        />
         <SidebarMenuItem
          to="/expense-cards/analytics"
          hasBullet={true}
          title="Analytics"
         
        />
         <SidebarMenuItem
          to="/expense-cards/settings"
          hasBullet={true}
          title="Settings"
         
        />
         <SidebarMenuItem
          to="/expense-cards/reports"
          hasBullet={true}
          title="Reports"
         
        />
      </SidebarMenuItemWithSub> */}




      {/* <SidebarMenuItemWithSub

        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg"
        title="Document Locker"
      >
        <SidebarMenuItem
          to="documentRoom/folder"
          title="Folders & Files"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="documentRoom/workspace"
          title="Collections"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="documentRoom/settings"
          title="Settings"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="documentRoom/history"
          title="Shared history"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="documentRoom/onDemandDoc"
          title="On demand documents"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}

{/*       <SidebarMenuItemWithSub


        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg"
        title="Reconcilation"
      >
        <SidebarMenuItem
          to="/reconcilation"
          title="Reconcilation"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/recon-1"
          title="Reconcilation-1"
          hasBullet={true}
        />

      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg
"
        title="Product returns management"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg
"
        title="Cash Flow Manager"
        fontIcon="bi-layers"
      />

      <SidebarMenuItemWithSub
        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg
"
        title="Compliance Zone"
      >
        <SidebarMenuItem to="#" title="Tax Payment" hasBullet={true} />
        <SidebarMenuItem
          to="#"
          title="Returns file and Compliance"
          hasBullet={true}
        />

        <SidebarMenuItem
          to="#"
          title="P&L and Balance Sheet ( Monthly, quarterly, periodic)"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg
"
        title="Business Insights and Analytics"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg
"
        title="Digital Advisor - Virtual CFO Services"
        fontIcon="bi-layers"
      />
      <SidebarMenuItemWithSub
        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg
"
        title="Supply Chain"
      >
        <SidebarMenuItem
          to="#"
          title="Supply Chain management"
          hasBullet={true}
        />
        <SidebarMenuItem to="#" title="Group Purchase" hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg
"
        title="Networking Opportunities"
      >
        <SidebarMenuItem
          to="#"
          title="Match making – Business Opportunities"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}

    
    </>
  );
};

export { SidebarMenuMain };
