import React, { useState } from 'react'
import { useAuth } from '../../core/Auth'
import { getIconClass } from './PendingPage'


const SubmittedPage = () => {
    const { regUser } = useAuth()


    const [selectedFile, setSelectedFile] = useState<String | null>(null);

    const [fileExtension, setFileExtension] = useState<String | null>(null);

    const RenderPreview = () => {
        const safeSelectedFile = selectedFile ? selectedFile.toString() : ""; // Ensure it's a primitive string

        if (
            fileExtension === "jpg" ||
            fileExtension === "jpeg" ||
            fileExtension === "png" ||
            fileExtension === "gif" ||
            fileExtension === "svg"
        ) {
            return (
                <img
                    src={safeSelectedFile}
                    alt="Preview"
                    style={{ maxWidth: "600px" }}
                />
            );
        } else if (fileExtension === "pdf") {
            return (
                <embed
                    src={safeSelectedFile}
                    type="application/pdf"
                    width="100%"
                    height="600px"
                />
            );
        } else {
            return <div>Unsupported file type</div>;
        }
    };
    return (
        <div>

            <div className="card shadow p-5 mt-5 mb-5 w-100" style={{ overflow: "auto" }}>
                {regUser?.queryModels && regUser.queryModels.length > 0 &&
                    regUser.queryModels.some((item) => item?.userResponse) ? (
                    regUser.queryModels.map((item, index) =>
                        item?.userResponse ? (
                            <div key={index} className="card shadow p-5 mt-3">
                                <div className="d-flex flex-row gap-5">
                                    {/* Left Section */}
                                    <div className="mb-4 w-50">
                                        <div className="d-flex align-items-start gap-5">
                                            {/* Left circle with index */}
                                            <div
                                                className="rounded-circle bg-light-primary d-flex justify-content-center align-items-center"
                                                style={{ minWidth: "40px", minHeight: "40px" }}
                                            >
                                                <span className="fw-bold">{index + 1}</span>
                                            </div>
                                            {/* Content */}
                                            <div>
                                                <div className="fs-4" style={{ fontWeight: "bolder" }}>
                                                    {item?.title}
                                                </div>
                                                <div className="text-muted mt-2">
                                                    <strong>Reason for this request:</strong>
                                                    <p className="text-dark mt-2">{item?.description}</p>
                                                </div>
                                                <div className="text-muted mt-2">
                                                    <strong>Current Status:</strong>{" "}
                                                    <span
                                                        className={`fw-bold ${item?.userResponse ? "text-success" : "text-danger"
                                                            }`}
                                                    >
                                                        {item?.userResponse ? "RECEIVED" : "PENDING"}
                                                    </span>
                                                    {item?.userResponseDate
                                                        ? ` (${item?.userResponseDate})`
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Right Section */}
                                    <div className="d-flex flex-column gap-5 w-50">
                                        <div className="form-group">
                                            <label>Your response</label>
                                            <textarea
                                                className="form-control"
                                                value={item?.userResponse}
                                                readOnly
                                            />
                                        </div>
                                        <div>
                                            <label>Attachments</label>

                                            <div className='d-flex flex-row gap-5 mt-2 '>
                                                {item?.queryDocuments.map((doc, index) => {
                                                    const iconClass = getIconClass(doc?.documentExtention); // Get the icon based on file extension
                                                    return (
                                                        <div className="d-flex flex-row align-items-center gap-3 !important" key={index}>
                                                            <i className={`${iconClass} fs-4 card shadow p-2 cursor-pointer`} data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_1" onClick={() => {
                                                                setSelectedFile(doc?.documentPath);
                                                                setFileExtension(doc?.documentExtention);
                                                            }}></i>

                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="">
                                                    <RenderPreview />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ) : null
                    )
                ) : (
                    <div className="text-center text-muted">Great! You don't have any request at the moment</div>
                )}
            </div>
        </div>

    )
}

export default SubmittedPage