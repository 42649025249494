import React, { useState } from 'react';
import { useAuth } from '../../core/Auth';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { getAllData, kycReplyAdditionalQuery } from './api';
import Swal from 'sweetalert2';

interface QueryModel {
    queryId: string;
    title: string;
    description: string;
    userResponse?: string;
    userResponseDate?: string;
}

export const getIconClass = (extension: any) => {
    const ext = extension?.toLowerCase();

    switch (ext) {
        case "pdf":
            return "bi-filetype-pdf text-danger";
        case "png":
        case "jpg":
        case "gif":
        case "svg":
        case "jpeg":
            return "bi-file-image text-primary";
        case "doc":
        case "docx":
            return "bi-file-text text-info";
        case "zip":
            return "bi-file-zip text-warning";
        case "xls":
        case "xlsx":
            return "bi-file-earmark-spreadsheet text-success";
        case "txt":
            return "bi-file-text text-muted";
        default:
            return "bi-file-earmark-text text-secondary"; // Default icon for unknown types
    }
};

interface FormValues {
    queryId: string;
    userResponse: string;
    attachments: File[];
}

// Individual query form component to isolate form state
const QueryForm: React.FC<{
    query: QueryModel;
    index: number;
}> = ({ query, index }) => {

    const [loading, setLoading] = useState(false);
    const { regUser, setRegUser } = useAuth();

    const formik = useFormik<FormValues>({
        initialValues: {
            queryId: query.queryId,
            userResponse: '',
            attachments: [],
        },
        validationSchema: Yup.object({
            queryId: Yup.string().required('Query ID is required.'),
            userResponse: Yup.string().required('User response is mandatory.'),
            attachments: Yup.array()
                .test(
                    'is-pdf',
                    'Only PDF files are allowed',
                    (value) =>
                        value && value.every((file) => file.type === 'application/pdf')
                ),
        }),
        onSubmit: async (values: FormValues, { setStatus, resetForm, setSubmitting }: FormikHelpers<FormValues>) => {
            setLoading(true);
            setSubmitting(true);
            console.log(values);

            try {
                const validDocuments = values.attachments.filter((doc) => doc);
                const data = await kycReplyAdditionalQuery(regUser?.kycId || "", values?.queryId, values?.userResponse, validDocuments, regUser?.token ?? "");
                if (data?.data?.status?.statusCode === 0) {
                    Swal?.fire({
                        icon: "success",
                        titleText: "Query Submitted",
                    });
                }
                getAllData(regUser?.token ?? "").then((res) => {
                    setRegUser((prevState) => ({
                        ...prevState,
                        ...res.data.message,
                    }));
                });
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },

    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        const filesArray = Array.from(event.target.files);
        formik.setFieldValue('attachments', [
            ...formik.values.attachments,
            ...filesArray,
        ]);
        event.target.value = '';
    };

    const handleRemoveFile = (index: number) => {
        const updatedFiles = formik.values.attachments.filter((_, i) => i !== index);
        formik.setFieldValue('attachments', updatedFiles);
    };

    return (
        <div className="card shadow p-5 mt-5">
            <form onSubmit={formik.handleSubmit}>
                <div className="d-flex flex-row gap-5">
                    {/* Left Section */}
                    <div className="mb-4 w-50">
                        <div className="d-flex align-items-start gap-5">
                            <div
                                className="rounded-circle bg-light-primary d-flex justify-content-center align-items-center"
                                style={{ minWidth: '40px', minHeight: '40px' }}
                            >
                                <span className="fw-bold">{index + 1}</span>
                            </div>
                            <div>
                                <div className="fs-4" style={{ fontWeight: 'bolder' }}>
                                    {query.title}
                                </div>
                                <div className="text-muted mt-2">
                                    <strong>Reason for this request:</strong>
                                    <p className="text-dark mt-2">{query.description}</p>
                                </div>
                                <div className="text-muted mt-2">
                                    <strong>Current Status:</strong>{' '}
                                    <span
                                        className={`fw-bold ${query.userResponse ? 'text-success' : 'text-danger'
                                            }`}
                                    >
                                        {query.userResponse ? 'RECEIVED' : 'PENDING'}
                                    </span>
                                    {query.userResponseDate ? ` (${query.userResponseDate})` : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="form-group mb-3 w-50">
                        <label>Your response</label>
                        <textarea
                            className="form-control"
                            name="userResponse"
                            value={formik.values.userResponse}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.errors.userResponse && formik.touched.userResponse && (
                            <div className="text-danger small">
                                {formik.errors.userResponse}
                            </div>
                        )}

                        <div className="mt-4 card shadow p-5">
                            <label>Attachments</label>
                            <div className="border border-2 rounded-3 p-4 mb-3 text-center">
                                <input
                                    type="file"
                                    className="form-control d-none"
                                    id={`fileInput-${query.queryId}`}
                                    accept=".pdf, .png, .jpg, .jpeg, .gif, .svg, .doc, .docx, .zip, .xls, .xlsx, .txt"
                                    multiple
                                    onChange={handleFileChange}
                                />
                                <label
                                    htmlFor={`fileInput-${query.queryId}`}
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="display-4 mb-2">
                                        <i className="bi bi-cloud-arrow-up-fill fs-2hx text-primary fw-bolder"></i>
                                    </div>
                                </label>
                            </div>

                            {/* Display Uploaded Files */}
                            {formik.values.attachments.map((file, fileIndex) => {
                                const fileExtension = file.name.split('.').pop();
                                const iconClass = getIconClass(fileExtension); // Get the icon based on file extension
                                return (
                                    <div key={fileIndex} className="mb-3 card shadow p-2">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <i className={`${iconClass} me-2 fs-4`}></i>
                                                <div>
                                                    <div className="fw-bold">{file.name}</div>
                                                    <div className="small text-muted">
                                                        {(file.size / 1024).toFixed(1)} KB
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <i
                                                    className="bi bi-trash text-danger fs-4"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleRemoveFile(fileIndex)}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                           
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={formik.isSubmitting || loading}
                                >
                                    Submit
                                </button>
                            </div>

                    </div>
                </div>
            </form>
        </div>
    );
};

const PendingPage: React.FC = () => {
    const { regUser } = useAuth();

    return (
        <div>
        <div
          className="card shadow p-5 mt-5 mb-5 w-100"
          style={{ maxHeight: "80vh", overflow: "auto" }}
        >
          {regUser?.queryModels && regUser.queryModels.length > 0 ? (
            regUser.queryModels.filter((item) => !item?.userResponse).length > 0 ? (
              regUser.queryModels
                .filter((item) => !item?.userResponse)
                .map((query, index) =>
                  query ? (
                    <QueryForm key={query.queryId} query={query} index={index} />
                  ) : null
                )
            ) : (
              <div className="text-center text-dark">Great! You don't have any request at the moment</div>
            )
          ) : (
            <div className="text-center text-muted">Great! You don't have any request at the moment</div>
          )}
        </div>
      </div>
      
    );
};

export default PendingPage;