import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { MenuComponent } from "../../../_metronic/assets/ts/components";
import { useFormik } from "formik";
import { Modal } from "bootstrap";
import pdf from "../../../_metronic/assets/all-serviceIcons/pdf-file.svg";
import docx from "../../../_metronic/assets/all-serviceIcons/docx-file.svg";
import jpeg from "../../../_metronic/assets/all-serviceIcons/image-files.svg";
import xlsx from "../../../_metronic/assets/all-serviceIcons/excel-file.svg";
import ShareLinkModal from "./ShareLinkModal";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createCollection,
  deleteCollection,
  initCollection,
  Item,
  listCollection,
  renameCollection,
  updateCollection,
} from "./collectionSlice";
import { RootState } from "../../store";
import { downloadFile, FilterSchema } from "./Folder";
import {
  downloadFolder,
  Folders,
  renameDirectory,
  viewCollection,
} from "./documentRoomSlice";
import { generateSearchCriteria } from "./SharingHistory";
import Authorize from "../../modules/auth/components/Authorize";

export const filterSchema = Yup.object()
  .shape({
    itemName: Yup.string()
      .max(50, "Item name must be 50 characters or less")
      .optional(),
    sharedWith: Yup.string()
      .max(50, "Shared email must be 50 characters or less")
      .optional(),
    createdDate: Yup.date()
      .nullable()
      .max(new Date(), "Date cannot be in the future")
      .optional(),
    formError: Yup.string(),
  })
  .test("at-least-one-field", "At least one filter is required", (values) => {
    const hasValue = !!(
      values.itemName?.trim() ||
      values.sharedWith?.trim() ||
      values.createdDate
    );
    if (!hasValue) {
      throw new Yup.ValidationError(
        "At least one filter is required",
        null,
        "formError"
      );
    }
    return true;
  });

interface FilterType {
  itemName: string;
  sharedWith: string;
  createdDate: string;
  formError?: string;
}

const Workspace: FC = () => {
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [filter, setFilter] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const colldropdownRef = useRef<HTMLDivElement>(null);
  const [shareGuid, setShareGuid] = useState("");

  const [collectionName, setCollectionName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [rename, setRename] = useState("");
  const [dash, setDash] = useState(true);
  const [fileName, setFileName] = useState<string | undefined>("");
  const [profiledoc, setProfileDoc] = useState<string | undefined>("");

  const { collections } = useSelector((state: RootState) => state?.collections);

  const collectionGuid = useSelector(
    (state: RootState) => state?.folders?.folders?.collectionGuid
  );

  const { folders } = useSelector((state: RootState) => state?.folders);

  const { init } = useSelector((state: RootState) => state?.init);

  const colfilterFormik = useFormik<FilterType>({
    initialValues: {
      itemName: "",
      sharedWith: "",
      createdDate: "",
    },
    validationSchema: filterSchema,
    onSubmit: (values) => {
      setFilter(true);
      dispatch(listCollection(generatePayload(values) as any) as any);

      // Close dropdown after submission
      if (colldropdownRef.current) {
        const dropdownButton = colldropdownRef.current.querySelector(
          '[data-bs-toggle="dropdown"]'
        ) as HTMLButtonElement; // Cast to HTMLButtonElement
        if (dropdownButton) {
          dropdownButton.click();
        }
      }
    },
    onReset: async (_, { resetForm }) => {
      // Generate payload with reset values
      const resetPayload = generatePayload({
        itemName: "",
        sharedWith: "",
        createdDate: "",
      });
      await dispatch(listCollection(resetPayload as any) as any);
      setFilter(false);
    },
  });

  // Helper function to generate payload
  const generatePayload = (values: typeof colfilterFormik.initialValues) => {
    return {
      ...(searchTerm && {
        search: searchTerm,
      }),
      pageNumber: currentPage,
      resultPerPage: itemsPerPage,
      skipPagination: false,
      searchFilter: [
        ...generateSearchCriteria("search", !!searchTerm, searchTerm),
        ...generateSearchCriteria(
          "ITEM_NAME",
          !!values?.itemName,
          values?.itemName
        ),
        ...generateSearchCriteria(
          "CREATED_DATE",
          !!values?.createdDate,
          values?.createdDate
        ),
        ...generateSearchCriteria(
          "SHARED_WITH",
          !!values?.sharedWith,
          values?.sharedWith
        ),
      ],
    };
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(
        listCollection(generatePayload(colfilterFormik?.values) as any) as any
      );
      // dispatch(
      //   listDirectory({
      //     search: searchTerm,
      //     parentFolderGuid: parentFolderGuid,
      //   } as any) as any
      // );
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      listCollection(generatePayload(colfilterFormik?.values) as any) as any
    );
  }, [dispatch, currentPage, itemsPerPage]);

  const filterFormik = useFormik({
    initialValues: {
      itemName: "",
      itemType: [""],
      lastModified: null,
    },
    validationSchema: FilterSchema,
    onSubmit: (values) => {
      setFilter(true);
      dispatch(
        listCollection(generatePayload(colfilterFormik?.values) as any) as any
      );
    },
    onReset: async (values) => {
      setTimeout(async () => {
        await dispatch(
          listCollection(generatePayload(colfilterFormik?.values) as any) as any
        );
      }, 300);

      setFilter(false);
    },
  });

  const handleDocViewClick = async () => {
    const modalElement = document.getElementById("docu_pdf_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handleUpload = () => {
    const modalElement = document.getElementById("kt_modal_upload_file");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const handleOpenModal = () => {
    const modalElement = document.getElementById("date_range");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Calculate pagination
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentData = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(collections?.length / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const items = [
    "akhil@yopmail.com",
    "ruthra@yopmail.com",
    "sathya@yopmail.com",
    "ajinkya@yopmail.com",
  ];

  const [checked, setChecked] = useState<string[]>([]);

  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    folderGuid: string
  ) => {
    const target = e.target as HTMLInputElement;

    setChecked((prev) => {
      if (target.checked) {
        // Add the folderGuid to the state
        return [...prev, folderGuid];
      } else {
        // Remove the folderGuid from the state
        return prev.filter((guid) => guid !== folderGuid);
      }
    });
  };

  const handleSelectAll = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement;
    console.log(target.checked);
    // setSelectAll(target.checked);

    if (target.checked) {
      // Sum all amounts from filteredTransactions

      const allGuids = collections.map((col) => col.collectionGuid);
      console.log(allGuids);

      setChecked(allGuids);
    } else {
      setChecked([]);
    }
  };

  const useCheckboxLogic = (initialData: Item[]) => {
    const [checkedFolders, setCheckedFolders] = useState<Set<string>>(
      new Set()
    );

    // Update checkedFolders when initialData changes
    useEffect(() => {
      if (initialData?.length) {
        setCheckedFolders(
          new Set(
            initialData
              .filter((item) => item.collectionItem)
              .map((item) => item.itemGuid)
          )
        );
      }
    }, [initialData]);

    const [expandedFolders, setExpandedFolders] = useState<Set<string>>(
      new Set()
    );

    const toggleExpand = (folderId: string) => {
      setExpandedFolders((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(folderId)) {
          newSet.delete(folderId);
        } else {
          newSet.add(folderId);
        }
        return newSet;
      });
    };

    const findParentGuids = (data: Item[], targetGuid: string): string[] => {
      const parentGuids: string[] = [];

      const findParent = (items: Item[], guid: string): boolean => {
        for (const item of items) {
          if (item.itemType === "FOLDER" && item.itemList) {
            const foundInChildren = item.itemList.some(
              (child) => child.itemGuid === guid
            );
            if (foundInChildren) {
              parentGuids.push(item.itemGuid);
              return true;
            }

            if (item.itemList.some((child) => child.itemType === "FOLDER")) {
              const deeperSearch = findParent(item.itemList, guid);
              if (deeperSearch) {
                parentGuids.push(item.itemGuid);
                return true;
              }
            }
          }
        }
        return false;
      };

      findParent(data, targetGuid);
      return parentGuids;
    };

    const toggleCheck = (folderId: string, data: Item[]) => {
      console.log(checkedFolders, folderId);

      setCheckedFolders((prev) => {
        const newSet = new Set(prev);
        const parentGuids = findParentGuids(data, folderId);

        // Remove the current folder from the checked set
        if (newSet.has(folderId)) {
          newSet.delete(folderId);
        } else {
          // If any parent is checked, prevent checking
          const isParentChecked = parentGuids.some((parentGuid) =>
            newSet.has(parentGuid)
          );
          if (!isParentChecked) {
            newSet.add(folderId);
          }
        }

        return newSet;
      });
    };

    const isCheckDisabled = (folderId: string, data: Item[]) => {
      const parentGuids = findParentGuids(data, folderId);
      return parentGuids.some((parentGuid) => checkedFolders.has(parentGuid));
    };

    const getAllCheckedGuids = () => {
      return Array.from(checkedFolders);
    };

    const handleUpload = async () => {
      const checkedFolderGuids = Array.from(checkedFolders);
      dispatch(
        updateCollection({
          itemGuidList: checkedFolderGuids,
          collectionGuid: collectionGuid,
        } as any) as any
      ).then(() => {
        const modal = Modal.getInstance("#kt_modal_upload_file");
        if (modal) {
          modal.hide();
        }
        dispatch(
          viewCollection({
            collectionGuid: folders?.collectionGuid,
            itemGuid: folders?.itemGuid,
          } as any) as any
        );
      });
    };
    return {
      checkedFolders,
      expandedFolders,
      toggleExpand,
      toggleCheck,
      isCheckDisabled,
      getAllCheckedGuids,
      handleUpload,
    };
  };

  const checkboxLogic = useCheckboxLogic(init?.itemList);

  const renderFolder = (
    folder: Item,
    level: number,
    data: Item[],
    checkboxLogic: ReturnType<typeof useCheckboxLogic>
  ) => {
    const {
      checkedFolders,
      expandedFolders,
      toggleExpand,
      toggleCheck,
      isCheckDisabled,
    } = checkboxLogic;

    const hasSubFolders = folder?.itemList && folder?.itemList?.length > 0;
    const isExpanded = expandedFolders.has(folder.itemGuid);
    const isChecked = checkedFolders.has(folder.itemGuid);
    // (folder.collectionItem && checkedFolders.has(folder.itemGuid));
    const checkDisabled = isCheckDisabled(folder.itemGuid, data);

    return (
      <div key={folder.itemGuid} className="w-full">
        <div
          className="d-flex align-items-center py-2"
          style={{ paddingLeft: `${level * 20}px` }}
        >
          <div className="form-check form-check-custom form-check-solid me-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={isChecked}
              disabled={checkDisabled}
              onChange={() => toggleCheck(folder.itemGuid, data)}
            />
          </div>

          <span className="fs-7 me-3">
            {folder.itemType === "FILE" ? (
              <i className="bi bi-file-earmark text-dark me-2"></i>
            ) : (
              <i className="bi bi-folder2-open text-dark me-2"></i>
            )}

            {folder.itemName}
          </span>

          {hasSubFolders && (
            <i
              className={`cursor-pointer ${isExpanded ? "bi bi-chevron-up" : "bi bi-chevron-down"} text-dark`}
              onClick={() => toggleExpand(folder.itemGuid)}
            />
          )}
        </div>

        {hasSubFolders && isExpanded && (
          <div className="ms-4">
            {folder?.itemList
              ?.filter((item) => item.itemType === "FOLDER")
              .map((subFolder) =>
                renderFolder(subFolder, level + 1, data, checkboxLogic)
              )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {" "}
      <h4 className="mb-4">Document Room - Collections</h4>{" "}
      {dash ? (
        <div className="card p-2">
          <div className="d-flex justify-content-between mb-5">
            <div className="d-flex align-items-center  position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <input
                type="text"
                data-kt-docs-table-filter="search"
                className="form-control p-3 border-secondary w-250px ps-15"
                placeholder="Search Files & Folders"
                // defaultValue={searchTerm}
                onChange={(event) => handleSearch(event?.target?.value)}
              />
            </div>
            <div className="d-flex flex-1 align-items-center p-3 gap-10 flex-end">
              {filter && (
                <div className="d-flex align-items-center me-5 mt-3 justify-content-center">
                  <a
                    className="text-danger text-hover-danger fw-bold cursor-pointer text-decoration-underline"
                    onClick={async () => {
                      colfilterFormik.resetForm();
                      // setSelectedTypes([]);
                    }}
                  >
                    Clear Filters
                  </a>
                </div>
              )}

              <div className="dropdown" ref={colldropdownRef}>
                <button
                  className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#ebe9eb",
                  }}
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-funnel text-dark fs-2 p-0 m-0"></i>
                </button>

                <ul
                  className="dropdown-menu dropdown-menu-end p-10 w-350px"
                  aria-labelledby="dropdownMenuButton"
                  onClick={(e) => e.stopPropagation()}
                >
                  <form
                    onSubmit={colfilterFormik.handleSubmit}
                    onReset={colfilterFormik.handleReset}
                    // onClick={(e) => e.stopPropagation()}
                  >
                    <li>
                      <p className="fs-4 ">Filter options</p>
                      <hr />
                    </li>
                    <li className="mb-3">
                      <label className="fs-5 mb-2" htmlFor="itemName">
                        Item name
                      </label>
                      <input
                        type="text"
                        id="itemName"
                        name="itemName"
                        className="form-control"
                        placeholder="Enter name"
                        value={colfilterFormik.values.itemName}
                        onChange={colfilterFormik.handleChange}
                        onBlur={colfilterFormik.handleBlur}
                      />
                      {colfilterFormik.touched.itemName &&
                        colfilterFormik.errors.itemName && (
                          <div className="text-danger">
                            {colfilterFormik.errors.itemName}
                          </div>
                        )}
                    </li>
                    <li className="mb-5">
                      <label className="fs-5 mb-2" htmlFor="createdDate">
                        Created date
                      </label>
                      <input
                        type="date"
                        id="createdDate"
                        name="createdDate"
                        className="form-control"
                        max={new Date().toISOString().split("T")[0]}
                        value={colfilterFormik.values.createdDate}
                        onChange={colfilterFormik.handleChange}
                        onBlur={colfilterFormik.handleBlur}
                      />
                      {colfilterFormik.touched.createdDate &&
                        colfilterFormik.errors.createdDate && (
                          <div className="text-danger">
                            {colfilterFormik.errors.createdDate}
                          </div>
                        )}
                    </li>
                    <li className="mb-3">
                      <label className="fs-5 mb-2" htmlFor="sharedWith">
                        Shared with
                      </label>
                      <input
                        type="text"
                        id="sharedWith"
                        name="sharedWith"
                        className="form-control"
                        placeholder="Enter name"
                        value={colfilterFormik.values.sharedWith}
                        onChange={colfilterFormik.handleChange}
                        onBlur={colfilterFormik.handleBlur}
                      />
                      {colfilterFormik.touched.sharedWith &&
                        colfilterFormik.errors.sharedWith && (
                          <div className="text-danger">
                            {colfilterFormik.errors.sharedWith}
                          </div>
                        )}
                    </li>
                    {colfilterFormik.errors.formError && (
                      <div className="text-danger text-sm mt-2">
                        {colfilterFormik.errors.formError}
                      </div>
                    )}
                    <li>
                      <div className="d-flex justify-content-end">
                        <button className="btn btn-md" type="submit">
                          Apply
                        </button>
                      </div>
                    </li>
                  </form>
                </ul>
              </div>

              {checked?.length > 0 ? (
                <button
                  className={`btn btn-danger fs-7 p-3 w-100px`}
                  onClick={() =>
                    Swal.fire({
                      icon: "warning",
                      text: "Are you sure you want to delete the selected collections?",
                      showCancelButton: true,
                      confirmButtonText: "Yes, delete",
                    }).then((res) => {
                      if (res?.isConfirmed) {
                        setChecked([]);
                        dispatch(
                          deleteCollection({
                            collectionGuidList: checked,
                          } as any) as any
                        ).then(() => {
                          dispatch(listCollection({} as any) as any);
                        });
                      }
                    })
                  }
                >
                  Delete ({checked?.length})
                </button>
              ) : (
                <>
                  {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
                  {/* <button
                  className={`btn fs-7 p-3 w-100px`}
                  onClick={() => setAdd(true)}
                  //   id="kt_modal_toggle_domestic"
                  //   disabled={payAmount < 1}
                >
                  New Folder
                </button> */}
                  {/* </Authorize> */}
                  {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}

                  {/* </Authorize> */}
                  <div className="dropdown">
                    <Authorize hasAnyPermission={["MENU_COLLECTIONS|ADD"]}>
                      <button
                        className="btn px-5"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Add Collection
                      </button>

                      <ul
                        className="dropdown-menu dropdown-menu-end p-2 w-120px"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => setAdd(true)}
                          >
                            New Collection
                          </button>
                        </li>
                      </ul>
                    </Authorize>
                  </div>
                </>
              )}
            </div>
          </div>

          <table
            className="table align-middle border rounded table-row-dashed fs-6 g-5"
            style={{
              maxHeight: "440px",
              overflowY: "scroll",
            }}
          >
            <thead className="bg-gray-200">
              <tr className="text-start text-dark-600 fw-bold fs-6 ">
                <Authorize hasAnyPermission={["MENU_COLLECTIONS|DELETE"]}>
                  <th className="px-3">
                    {" "}
                    <label className="form-check form-check-custom form-check-sm fs-7">
                      <input
                        className="form-check-input cursor-pointer border border-dark"
                        type="checkbox"
                        checked={
                          checked?.length > 0 &&
                          checked?.length === collections?.length
                        }
                        onClick={handleSelectAll}
                      />{" "}
                    </label>
                  </th>
                </Authorize>
                <th className="min-w-200px p-5">Name</th>
                <th className="min-w-100px p-5">Count</th>
                <th className="min-w-100px p-5">Shared with</th>
                <th className="min-w-100px p-5">Last Modified</th>
                <th className="min-w-100px p-5">Action</th>
              </tr>
            </thead>

            <tbody>
              {add && (
                <tr>
                  <td></td>
                  <td>
                    <div className="d-flex align-items-center gap-5">
                      <input
                        type="text"
                        className="form-control w-300px"
                        maxLength={50}
                        placeholder="Collection name"
                        onChange={(e) => setCollectionName(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === ".") {
                            e.preventDefault(); // Prevent entering a full stop
                          }
                          if (e.key === "/") {
                            e.preventDefault(); // Prevent entering a full stop
                          }
                        }}
                      />
                      <div className="d-flex gap-3">
                        <i
                          className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"
                          onClick={() => {
                            if (collectionName) {
                              dispatch(
                                createCollection({
                                  collectionName: collectionName,
                                  collectionGuid: collectionGuid,
                                }) as any
                              ).then(
                                setTimeout(() => {
                                  dispatch(
                                    listCollection({
                                      search: searchTerm,
                                      parentFolderGuid: collectionGuid,
                                    } as any) as any
                                  );
                                }, 300),
                                setAdd(false)
                              );
                            }
                          }}
                        ></i>
                        <i
                          className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                          onClick={() => setAdd(false)}
                        ></i>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {collections?.length > 0 ? (
                collections?.map((item, index) => (
                  <tr
                    key={index}
                    onMouseOver={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "#f1f1f2";
                    }}
                    onMouseOut={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "white";
                    }}
                  >
                    <Authorize hasAnyPermission={["MENU_COLLECTIONS|DELETE"]}>
                      <td className="px-3">
                        <label className="form-check form-check-custom form-check-sm fs-7">
                          <input
                            className="form-check-input cursor-pointer border border-dark"
                            type="checkbox"
                            checked={checked.includes(item.collectionGuid)}
                            onClick={(e) =>
                              handleCheckClick(e, item?.collectionGuid)
                            }
                          />{" "}
                        </label>
                      </td>
                    </Authorize>
                    <td className="p-5 text-gray-600 cursor-pointer fw-hover-bold fs-6">
                      <div className=" d-flex flex-row gap-3 align-items-center">
                        {editingId === item.collectionGuid ? (
                          <div className="d-flex align-items-center gap-5">
                            <input
                              type="text"
                              maxLength={50}
                              className="form-control w-300px"
                              placeholder="Folder name"
                              defaultValue={item.collectionName}
                              onChange={(e) => {
                                const newName = e.target.value.replace(
                                  /\./g,
                                  ""
                                );
                                setRename(newName);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault(); // Prevent entering a full stop
                                }
                                if (e.key === "/") {
                                  e.preventDefault(); // Prevent entering a full stop
                                }
                              }}
                            />
                            <div className="d-flex gap-3">
                              <i
                                className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"
                                onClick={() => {
                                  if (rename) {
                                    dispatch(
                                      renameCollection({
                                        collectionGuid: item?.collectionGuid,
                                        collectionName: rename,
                                      }) as any
                                    ).then(() => {
                                      setRename("");
                                      dispatch(
                                        listCollection({
                                          // parentFolderGuid: parentFolderGuid,
                                        } as any) as any
                                      );
                                    });
                                  }
                                  setEditingId(null);
                                }}
                              ></i>
                              <i
                                className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                                onClick={() => {
                                  setRename("");
                                  setEditingId(null);
                                }}
                              ></i>
                            </div>
                          </div>
                        ) : (
                          <>
                            {item.collectionName?.length > 30 ? (
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="top"
                                overlay={
                                  <Popover
                                    className="p-2 bg-light-secondary"
                                    id="popover-trigger-focus"
                                  >
                                    {item.collectionName}
                                  </Popover>
                                }
                              >
                                <p
                                  className={`text-${item.size === "System Generated" ? "primary" : "gray-600"} fw-hover-bold fs-6 mb-0`}
                                  onClick={() =>
                                    dispatch(
                                      viewCollection({
                                        collectionGuid: item?.collectionGuid,
                                        itemGuid: item?.collectionGuid,
                                      } as any) as any
                                    ).then(setDash(false))
                                  }
                                >
                                  {item.collectionName?.length > 30
                                    ? `${item.collectionName.slice(0, 30)}...`
                                    : item.collectionName}
                                </p>
                              </OverlayTrigger>
                            ) : (
                              <p
                                className={`text-${item.size === "System Generated" ? "primary" : "gray-600"} fw-hover-bold fs-6 mb-0`}
                                onClick={() =>
                                  dispatch(
                                    viewCollection({
                                      collectionGuid: item?.collectionGuid,
                                      itemGuid: item?.collectionGuid,
                                    } as any) as any
                                  ).then(setDash(false))
                                }
                              >
                                {item.collectionName}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                    <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                      <p className="mb-0"> {item.size}</p>
                    </td>
                    <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                      <p className="mb-0">
                        {item.shareWith.length > 20 ? (
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={
                              <Popover
                                className="px-7 py-3 bg-light-secondary"
                                id="popover-trigger-focus"
                              >
                                {item.shareWith
                                  .split(",")
                                  .map((status, idx) => (
                                    <div key={idx}>{status.trim()}</div>
                                  ))}
                              </Popover>
                            }
                          >
                            <div>
                              {item.shareWith?.length > 20
                                ? `${item.shareWith.slice(0, 20)}...`
                                : item.shareWith}
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <p className="text-gray-600 text-hover-primary fw-hover-bold fs-6 mb-0">
                            {item.shareWith ? item.shareWith : "-"}
                          </p>
                        )}
                      </p>
                    </td>
                    <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                      <p className="mb-0"> {item.lastModified}</p>
                    </td>
                    <td className="p-5 text-gray-600 p-2">
                      <div className="dropdown" style={{ width: "20px" }}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-add-money">Actions</Tooltip>
                          }
                        >
                          <button
                            className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25%",
                              backgroundColor: "#ebe9eb",
                            }}
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                          </button>
                        </OverlayTrigger>

                        <ul
                          className="dropdown-menu dropdown-menu-end p-1"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => {
                                setShareGuid(item?.collectionGuid);
                                handleOpenModal();
                              }}
                            >
                              Share
                            </button>
                          </li>
                          <Authorize
                            hasAnyPermission={["MENU_COLLECTIONS|EDIT"]}
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() =>
                                  setEditingId(item.collectionGuid)
                                }
                              >
                                Rename
                              </button>
                            </li>
                          </Authorize>
                          <Authorize
                            hasAnyPermission={["MENU_COLLECTIONS|DELETE"]}
                          >
                            <li>
                              <button
                                className="dropdown-item text-danger"
                                type="button"
                                onClick={() =>
                                  Swal.fire({
                                    icon: "question",
                                    text: "Are you sure you want to delete workspace?",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete",
                                  }).then((res) => {
                                    if (res?.isConfirmed)
                                      dispatch(
                                        deleteCollection({
                                          collectionGuidList: [
                                            item?.collectionGuid,
                                          ],
                                        } as any) as any
                                      ).then(() => {
                                        dispatch(
                                          listCollection({} as any) as any
                                        );
                                      });
                                  })
                                }
                              >
                                Delete
                              </button>
                            </li>
                          </Authorize>
                          {/* {item?.status !== "Link Expired" && ( */}
                          {/* <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={handleOpenAccessModal}
                              >
                                Manage access
                              </button>
                            </li> */}
                          {/* )} */}
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="d-flex justify-content-between">
            <div className="p-5">
              <ul className="pagination">
                <li
                  className={`page-item double-arrow ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={0}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(1)}
                  >
                    <i className="previous"></i>
                    <i className="previous"></i>
                  </a>
                </li>
                <li
                  className={`page-item previous ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={1}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="previous"></i>
                  </a>
                </li>
                {renderPageNumbers()}
                <li
                  className={`page-item next ${
                    currentPage ===
                    Math.ceil(collections?.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  key={2}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="next"></i>
                  </a>
                </li>
                <li
                  className={`page-item double-arrow ${
                    currentPage ===
                    Math.ceil(collections?.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  key={3}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() =>
                      handlePageChange(
                        Math.ceil(collections?.length / itemsPerPage)
                      )
                    }
                  >
                    <i className="next"></i>
                    <i className="next"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="p-5 d-flex align-items-center">
              <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                Total: {collections?.length}&nbsp;&nbsp;
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </div>
      ) : (
        <div className="card p-2">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex align-items-center  position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <input
                type="text"
                data-kt-docs-table-filter="search"
                className="form-control p-3 border-secondary w-250px ps-15"
                placeholder="Search Files & Folders"
                // defaultValue={searchTerm}
                onChange={(event) => handleSearch(event?.target?.value)}
              />
            </div>
            <div className="d-flex flex-1 align-items-center p-3 gap-10 flex-end">
              <div className="dropdown" ref={dropdownRef}>
                <button
                  className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#ebe9eb",
                  }}
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => filterFormik?.resetForm()}
                >
                  <i className="bi bi-funnel text-dark fs-2 p-0 m-0"></i>
                </button>

                <ul
                  className="dropdown-menu dropdown-menu-end p-10 w-350px"
                  aria-labelledby="dropdownMenuButton"
                  onClick={(e) => e.stopPropagation()}
                >
                  <form onSubmit={filterFormik.handleSubmit}>
                    <li>
                      <p className="fs-4">Filter options</p>
                      <hr />
                    </li>

                    <li className="mb-3">
                      <label className="fs-5 mb-2">Item name</label>
                      <input
                        id="itemName"
                        name="itemName"
                        type="text"
                        className={`form-control ${
                          filterFormik.touched.itemName &&
                          filterFormik.errors.itemName
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Enter name"
                        onChange={filterFormik.handleChange}
                        onBlur={filterFormik.handleBlur}
                        value={filterFormik.values.itemName}
                      />
                      {filterFormik.touched.itemName &&
                        filterFormik.errors.itemName && (
                          <div className="invalid-feedback">
                            {filterFormik.errors.itemName}
                          </div>
                        )}
                    </li>

                    <li className="mb-3 flex-column">
                      <label className="fs-5 mb-2">Item type</label>
                      <div
                        className="d-inline-flex gap-1 cursor-pointer m-0 form-select mb-3 border-0 px-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Select Item type
                      </div>
                      {filterFormik.touched.itemType &&
                        filterFormik.errors.itemType && (
                          <div className="text-danger">
                            {filterFormik.errors.itemType}
                          </div>
                        )}
                      <div className="collapse" id="collapseExample">
                        <div className="card">
                          {items.map((item, index) => (
                            <div key={index} className="d-flex gap-5 mb-3">
                              <label className="form-check form-check-custom form-check-sm">
                                <input
                                  className="form-check-input cursor-pointer border border-dark"
                                  type="checkbox"
                                  id={`itemType-${item}`}
                                  name="itemType"
                                  value={item}
                                  onChange={filterFormik.handleChange}
                                  checked={filterFormik.values.itemType.includes(
                                    item
                                  )}
                                  // onClick={(e) => handleCheckClick(e)}
                                />{" "}
                              </label>
                              <p className="m-0">{item}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>

                    <li className="mb-5">
                      <label className="fs-5 mb-2">Last modified</label>
                      <input
                        id="lastModified"
                        name="lastModified"
                        type="date"
                        className={`form-control ${
                          filterFormik.touched.lastModified &&
                          filterFormik.errors.lastModified
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={filterFormik.handleChange}
                        onBlur={filterFormik.handleBlur}
                        value={filterFormik.values.lastModified || ""}
                      />
                      {filterFormik.touched.lastModified &&
                        filterFormik.errors.lastModified && (
                          <div className="invalid-feedback">
                            {filterFormik.errors.lastModified}
                          </div>
                        )}
                    </li>

                    <li>
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-md btn-primary"
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                    </li>
                  </form>
                </ul>
              </div>

              {/* <div className="d-flex">
                <div
                  className="d-flex border border-dark px-2 py-1 rounded-start-5 border-end-0 align-items-center"
                  onClick={() => setList(true)}
                >
                  {list && (
                    <i className="bi bi-check2 fs-3 fw-bold text-dark"></i>
                  )}
                  <img
                    src={listImg}
                    alt=""
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </div>
                <div
                  className="d-flex border border-dark px-2 py-1 rounded-end-5 align-items-center"
                  onClick={() => setList(false)}
                >
                  {!list && (
                    <i className="bi bi-check2 fs-3 fw-bold text-dark"></i>
                  )}
                  <img
                    src={gridImg}
                    alt=""
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </div>
              </div> */}
              <button
                className={`btn fs-7 p-3 w-100px`}
                onClick={() => {
                  dispatch(
                    initCollection({
                      collectionGuid: folders?.collectionGuid,
                    } as any) as any
                  );
                  handleUpload();
                }}
              >
                Upload Files
              </button>
            </div>
          </div>
          <div className="d-flex mb-5 bg-light-primary">
            <div className="d-flex p-2 ">
              <div className="d-flex align-items-center">
                <p
                  className="mb-0 text-primary cursor-pointer"
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.textDecoration = "underline")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.textDecoration = "none")
                  }
                  onClick={() => {
                    dispatch(listCollection({} as any) as any).then(
                      setDash(true)
                    );
                  }}
                >
                  collections
                </p>
                <i className="bi bi-chevron-right fs-8 text-dark px-2"></i>
              </div>
              {folders?.rootDirectory
                ?.split("/")
                ?.filter((path: string) => path !== "")
                ?.map((path: string, index: number) => (
                  <div key={index} className="d-flex align-items-center">
                    <p
                      key={index}
                      className="mb-0 text-primary cursor-pointer"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.textDecoration = "underline")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.textDecoration = "none")
                      }
                      onClick={() => {
                        if (index === 0) {
                          dispatch(listCollection({} as any) as any).then(
                            setDash(true)
                          );
                        }
                        if (
                          folders?.rootDirectory
                            ?.split("/")
                            ?.filter((path: string) => path !== "")?.length -
                            1 !==
                          index
                        ) {
                          const folderGuid =
                            folders?.folderToParentMapping[path];
                          if (folderGuid) {
                            dispatch(
                              viewCollection({
                                collectionGuid: folders?.collectionGuid,
                                itemGuid: folderGuid,
                              } as any) as any
                            ).then(setDash(false));
                          }
                        }
                      }}
                    >
                      {path}
                    </p>
                    <i className="bi bi-chevron-right fs-8 text-dark px-2"></i>
                  </div>
                ))}
            </div>
          </div>

          <div
            style={{
              maxHeight: "440px",
              overflowY: "scroll",
            }}
          >
            <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
              <thead className="bg-gray-200">
                <tr className="text-start text-dark-600 fw-bold fs-6 ">
                  {/* <th className="px-3">
                      {" "}
                      <label className="form-check form-check-custom form-check-sm fs-7">
                        <input
                          className="form-check-input cursor-pointer border border-dark"
                          type="checkbox"
                          checked={
                            folders?.folders?.filter(
                              (item: Folders) =>
                                item?.source === "USER_GENERATED"
                            ).length === checked?.length
                          }
                          onClick={handleSelectAll}
                        />{" "}
                      </label>
                    </th> */}

                  <th className="min-w-200px p-5">Name</th>
                  <th className="min-w-200px p-5">Type</th>
                  <th className="min-w-100px p-5">Size</th>
                  <th className="min-w-100px p-5">Last Modified</th>
                  <th className="min-w-100px p-5">Action</th>
                </tr>
              </thead>

              <tbody>
                {folders?.folders?.length > 0 ? (
                  folders?.folders?.map((item: Folders, index: number) => (
                    <tr
                      key={index}
                      onMouseOver={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "#f1f1f2";
                      }}
                      onMouseOut={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "white";
                      }}
                    >
                      {/* <td className="px-3">
                          {item.source === "USER_GENERATED" && (
                            <label className="form-check form-check-custom form-check-sm fs-7">
                              <input
                                className="form-check-input cursor-pointer border border-dark"
                                type="checkbox"
                                // checked={checked.includes(item.folderGuid)}
                                // onClick={(e) =>
                                //   handleCheckClick(e, item.folderGuid)
                                // }
                              />{" "}
                            </label>
                          )}
                        </td> */}

                      <td className="p-5 text-gray-600 cursor-pointer fw-hover-bold fs-6">
                        <div className=" d-flex flex-row gap-3 align-items-center">
                          {item?.type === "FOLDER" ? (
                            <i
                              className={`bi bi-folder2-open fs-2 text-${
                                item.folderGuid === "System Generated"
                                  ? "primary"
                                  : "dark"
                              }`}
                            ></i>
                          ) : (
                            <img
                              src={
                                item?.fileName?.split(".").pop() === "pdf"
                                  ? pdf
                                  : ["jpeg", "png", "jpg"].includes(
                                        item?.fileName?.split(".").pop() || ""
                                      )
                                    ? jpeg
                                    : ["xlsx", "csv"].includes(
                                          item?.fileName?.split(".").pop() || ""
                                        )
                                      ? xlsx
                                      : docx
                              }
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                          )}
                          {editingId === item.folderGuid ? (
                            <div className="d-flex align-items-center gap-5">
                              <input
                                type="text"
                                maxLength={50}
                                className="form-control w-300px"
                                placeholder="Folder name"
                                defaultValue={
                                  item.folderName
                                    ? item.folderName?.split(".")?.[0]
                                    : item.fileName?.split(".")?.[0]
                                }
                                onChange={(e) => {
                                  const ext = item?.fileName?.split(".").pop();
                                  const newName = e.target.value.replace(
                                    /\./g,
                                    ""
                                  ); // Remove any full stops
                                  if (item?.type === "FOLDER")
                                    setRename(newName);
                                  else setRename(`${newName}.${ext}`);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === ".") {
                                    e.preventDefault(); // Prevent entering a full stop
                                  }
                                  if (e.key === "/") {
                                    e.preventDefault(); // Prevent entering a full stop
                                  }
                                }}
                              />
                              .{item?.fileName?.split(".").pop()}
                              <div className="d-flex gap-3">
                                <i
                                  className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"
                                  onClick={() => {
                                    if (rename) {
                                      dispatch(
                                        renameDirectory({
                                          parentFolderGuid: item?.folderGuid,
                                          folderName: rename,
                                        }) as any
                                      ).then(() => {
                                        setRename("");
                                        const itemGuid =
                                          folders?.rootDirectory
                                            ?.split("/")
                                            ?.filter(
                                              (path: string) => path !== ""
                                            )?.length <= 1
                                            ? collectionGuid
                                            : item?.parentFolderGuid;
                                        dispatch(
                                          viewCollection({
                                            collectionGuid: collectionGuid,
                                            itemGuid: itemGuid,
                                          } as any) as any
                                        );
                                      });
                                    }
                                    setEditingId(null);
                                  }}
                                ></i>
                                <i
                                  className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                                  onClick={() => setEditingId(null)}
                                ></i>
                              </div>
                            </div>
                          ) : (
                            <>
                              {(item.folderName
                                ? item.folderName
                                : item.fileName
                              )?.length > 30 ? (
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="top"
                                  overlay={
                                    <Popover
                                      className="p-2 bg-light-secondary"
                                      id="popover-trigger-focus"
                                    >
                                      {item.folderName
                                        ? item.folderName
                                        : item.fileName}
                                    </Popover>
                                  }
                                >
                                  <p
                                    className={`text-${item.source === "SYSTEM GENERATED" ? "primary" : "gray-600"} fw-hover-bold fs-6 mb-0`}
                                    onClick={() => {
                                      if (item?.fileName) {
                                        // if (
                                        //   item?.fileName?.split(".").pop() ===
                                        //   "pdf"
                                        // ) {
                                        setFileName(item?.fileName);
                                        setProfileDoc(item?.readUrl);
                                        handleDocViewClick();
                                        // } else if (
                                        //   ["jpeg", "png", "jpg"].includes(
                                        //     item?.fileName
                                        //       ?.split(".")
                                        //       .pop() || ""
                                        //   )
                                        // ) {
                                        //   setProfileImg(item?.readUrl);
                                        //   handleViewClick();
                                        // }
                                      } else if (item.folderName) {
                                        dispatch(
                                          viewCollection({
                                            collectionGuid:
                                              folders?.collectionGuid,
                                            itemGuid: item?.folderGuid,
                                          } as any) as any
                                        ).then(setDash(false));
                                      }
                                    }}
                                  >
                                    {(item.folderName
                                      ? item.folderName
                                      : item.fileName
                                    )?.length > 30
                                      ? `${(item.folderName
                                          ? item.folderName
                                          : item.fileName
                                        ).slice(0, 30)}...`
                                      : item.folderName
                                        ? item.folderName
                                        : item.fileName}
                                  </p>
                                </OverlayTrigger>
                              ) : (
                                <p
                                  className={`text-${item.source === "SYSTEM GENERATED" ? "primary" : "gray-600"} fw-hover-bold fs-6 mb-0`}
                                  onClick={() => {
                                    if (item?.fileName) {
                                      // if (
                                      //   item?.fileName?.split(".").pop() ===
                                      //   "pdf"
                                      // ) {
                                      setFileName(item?.fileName);
                                      setProfileDoc(item?.readUrl);
                                      handleDocViewClick();
                                      // } else if (
                                      //   ["jpeg", "png", "jpg"].includes(
                                      //     item?.fileName?.split(".").pop() ||
                                      //       ""
                                      //   )
                                      // ) {
                                      //   setProfileImg(item?.readUrl);
                                      //   handleViewClick();
                                      // }
                                    } else if (item.folderName) {
                                      dispatch(
                                        viewCollection({
                                          collectionGuid:
                                            folders?.collectionGuid,
                                          itemGuid: item?.folderGuid,
                                        } as any) as any
                                      ).then(setDash(false));
                                    }
                                  }}
                                >
                                  {item.folderName
                                    ? item.folderName
                                    : item.fileName}
                                </p>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0"> {item.source}</p>
                      </td>
                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0"> {item.size}</p>
                      </td>
                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0"> {item.lastModified}</p>
                      </td>
                      <td className="p-5 text-gray-600 p-2">
                        <div className="d-flex gap-5 align-items-center">
                          {/* <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-add-money">Share</Tooltip>
                              }
                            >
                              <button
                                className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  borderRadius: "25%",
                                  backgroundColor: "#ebe9eb",
                                }}
                                type="button"
                                onClick={
                                  () => {}
                                  // handleOpenModal(item?.folderGuid)
                                }
                              >
                                <i className="bi bi-link-45deg text-dark fs-3 p-0 m-0"></i>
                              </button>
                            </OverlayTrigger> */}

                          <div className="dropdown" style={{ width: "20px" }}>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-add-money">
                                  Actions
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  borderRadius: "25%",
                                  backgroundColor: "#ebe9eb",
                                }}
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                              </button>
                            </OverlayTrigger>

                            <ul
                              className="dropdown-menu dropdown-menu-end p-1"
                              aria-labelledby="dropdownMenuButton"
                              style={{ zIndex: 1000000 }}
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    if (item?.fileName) {
                                      // if (
                                      //   item?.fileName?.split(".").pop() ===
                                      //   "pdf"
                                      // ) {
                                      setFileName(item?.fileName);
                                      setProfileDoc(item?.readUrl);
                                      handleDocViewClick();
                                      // } else if (
                                      //   ["jpeg", "png", "jpg"].includes(
                                      //     item?.fileName?.split(".").pop() ||
                                      //       ""
                                      //   )
                                      // ) {
                                      //   setProfileImg(item?.readUrl);
                                      //   handleViewClick();
                                      // }
                                    } else if (item.folderName) {
                                      dispatch(
                                        viewCollection({
                                          collectionGuid:
                                            folders?.collectionGuid,
                                          itemGuid: item?.folderGuid,
                                        } as any) as any
                                      ).then(setDash(false));
                                    }
                                  }}
                                >
                                  View
                                </button>
                              </li>
                              {item.source === "USER_GENERATED" && (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() =>
                                      setEditingId(item.folderGuid)
                                    }
                                  >
                                    Rename
                                  </button>
                                </li>
                              )}
                              {item.type !== "FOLDER" &&
                                item?.fileName?.split(".").pop() === "pdf" && (
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() => {
                                        navigate("/documentRoom/pdfView", {
                                          state: {
                                            pdfUrl: item?.readUrl,
                                            itemId: item?.folderGuid,
                                          },
                                        });
                                        // handleOpenModal();
                                      }}
                                    >
                                      Self Sign
                                    </button>
                                  </li>
                                )}

                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    if (item.type === "FOLDER") {
                                      dispatch(
                                        downloadFolder({
                                          url: "downloadFolder",
                                          folderGuid: item.folderGuid,
                                          folderName: item?.folderName,
                                          appType: "",
                                        }) as any
                                      );
                                    } else {
                                      downloadFile(
                                        item?.readUrl,
                                        item.fileName
                                      );
                                    }
                                  }}
                                >
                                  Download
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-between">
            <div className="p-5">
              <ul className="pagination">
                <li
                  className={`page-item double-arrow ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={0}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(1)}
                  >
                    <i className="previous"></i>
                    <i className="previous"></i>
                  </a>
                </li>
                <li
                  className={`page-item previous ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={1}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="previous"></i>
                  </a>
                </li>
                {renderPageNumbers()}
                <li
                  className={`page-item next ${
                    currentPage ===
                    Math.ceil(folders?.folders?.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  key={2}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="next"></i>
                  </a>
                </li>
                <li
                  className={`page-item double-arrow ${
                    currentPage ===
                    Math.ceil(folders?.folders?.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  key={3}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() =>
                      handlePageChange(
                        Math.ceil(folders?.folders?.length / itemsPerPage)
                      )
                    }
                  >
                    <i className="next"></i>
                    <i className="next"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="p-5 d-flex align-items-center">
              <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                Total: {folders?.folders?.length}&nbsp;&nbsp;
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </div>
      )}
      <div
        className="modal fade"
        id="kt_modal_upload_file"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header" id="kt_modal_upload_file_header">
              <h2 className="fw-bold">Upload File</h2>
              {/* <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-bs-dismiss="modal"
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div> */}
            </div>

            <div
              className="modal-body scroll-y mx-5 mx-xl-15 my-7 "
              style={{
                maxHeight: "400px",
                overflow: "scroll",
              }}
            >
              {/* {init?.itemList?.map((item) => ( */}
              <div className="">
                {/* <h3 className="fs-6 fw-semibold mb-3">{tenant.tenantId}</h3> */}
                {/* <div className="border rounded-3 p-4"> */}
                <div className="">
                  {init?.itemList?.map((folder) =>
                    renderFolder(folder, 0, init?.itemList, checkboxLogic)
                  )}
                </div>
              </div>
              {/* ))} */}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                <span
                  className="indicator-label"
                  onClick={checkboxLogic.handleUpload}
                >
                  Upload
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ShareLinkModal itemGuid={shareGuid} />
      <div
        className="modal fade"
        id="docu_pdf_modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
          <div
            className="modal-content"
            style={{ maxHeight: "700px", overflow: "auto" }}
          >
            <div className="modal-header" id="kt_modal_add_user_header">
              <h3 className="fw-bold m-0">PDF Viewer</h3>
            </div>

            <div className="modal-body px-15">
              {fileName
                ?.substring(fileName.lastIndexOf(".") + 1)
                .toLowerCase() === "pdf" ? (
                <embed
                  src={`${profiledoc}#pagemode=none`}
                  type="application/pdf"
                  width="100%"
                  height="800px"
                />
              ) : fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "xlsx" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "xls" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "csv" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "doc" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "docx" ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${profiledoc}`}
                  width="100%"
                  height="800px"
                  style={{ border: "none" }}
                />
              ) : fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "txt" ? (
                <iframe
                  src={profiledoc}
                  width="100%"
                  height="800px"
                  style={{ border: "none" }}
                  title="Text File Viewer"
                />
              ) : fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "png" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "jpeg" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "jpg" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "gif" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "svg" ? (
                <img
                  className="image"
                  src={profiledoc}
                  alt="Preview"
                  style={{
                    borderRadius: "10px",
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "800px",
                  }}
                />
              ) : (
                <p>Unsupported file type</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Workspace };
