import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../core/Auth'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import Sucess from "../../../../../_metronic/assets/Icons/success.png"
import { getAllData, reapply } from './api'
import { useFormCompletion } from './Stepper'

const Reapply = () => {

    const { regUser, setRegUser } = useAuth()
    const navigate = useNavigate()
    const { markFormCompleted } = useFormCompletion();

    const reason = (useLocation().state as { data: any })
        ?.data;

    console.log(reason);







    return (
        <div className='d-flex flex-column justify-content-center'>

            <p className='fs-2 fw-bold text-center'>Re-Apply </p>
            <div className='mt-10'>
                <div className='p-10'>
                    <p className='fs-4'>Your application required additonal information</p>
                    <p className='fs-4'>Please re-apply after making the necessary corrections/modifications. </p>
                </div>
            </div>

            <div className='d-flex justify-content-center mt-10 p-10'>
                <button
                    className="btn w-100"
                    onClick={async () => {
                        try {
                            const res = await reapply(regUser?.token ?? "");
                            setRegUser((prevState) => ({
                                ...prevState,
                                ...res?.data?.message,
                            }));
                            markFormCompleted("registration,verify,password,terms");
                            navigate("/auth/business");
                        } catch (error) {
                            console.error("Error during reapply process:", error);
                        }
                    }}
                >
                    Proceed to Re-Apply
                </button>

            </div>
        </div>

    )
}

export default Reapply
