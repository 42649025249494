import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Swal from "sweetalert2";
import { UserFolderData } from "./documentRoomSlice";

// Define your API URL
const API_URL = process.env.REACT_APP_WEB_API_URL;

export interface ShareAccessData {
  email: string;
  status: string;
  accessDate: string;
  linkGuid: string;
  accessStatus: string;
}

export interface Email {
  itemValue: string;
}

export interface ShareItem {
  shareGuid: string;
  itemName: string;
  validity: string;
  createdDateString: string;
  updatedDateString: string;
  shareAccessData: ShareAccessData[]; // Optional, as it's not present in all objects
  status: number;
  itemType: string;
}

// Define initial state
interface ShareHistoryState {
  shareHistory: ShareItem[];
  docAppList: UserFolderData;
  emailList: Email[];
  count: number;
  loading: boolean;
  error: string | null;
}

const initialState: ShareHistoryState = {
  shareHistory: [],
  docAppList: {
    userId: "",
    tenantId: "",
    rootDirectory: "",
    businessGuid: "",
    collectionGuid: "",
    folders: [],
    itemGuid: "",
    parentFolderGuid: "",
    folderToParentMapping: {},
  },
  emailList: [],
  count: 0,
  loading: false,
  error: null,
};

export interface SearchCriteria {
  search: string;
  searchType: string | number;
}

interface sharePayload {
  itemGuid: [];
  emails: [];
}

interface manageAccessPayload {
  shareGuid: string;
  itemGuid: [];
  deleteItemGuid: [];
  emails: [];
}

interface FetchDirectoryPayload {
  search: string;
  pageNumber: number;
  pageSize: number;
  resultPerPage: number;
  skipPagination: "false";
  searchFilter: SearchCriteria[];
}

// Define async thunk for fetching notifications
export const listSharedHistory = createAsyncThunk<
  { results: ShareItem[]; count: number },
  FetchDirectoryPayload,
  { rejectValue: string }
>(
  "/dataRoom/listSharedHistory",
  async (payload: FetchDirectoryPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;
      console.log(payload);

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/listSharedHistory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...(payload.search && {
              search: payload.search,
            }),
            pageNumber: payload.pageNumber,
            resultPerPage: payload.resultPerPage,
            skipPagination: payload.skipPagination,
            searchFilter: payload.searchFilter,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return {
        results: responseData.results, // Assuming `message` contains the array of items
        count: responseData.count,
      };
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const findAdditionalInfo = createAsyncThunk<
  Email[],
  {},
  { rejectValue: string }
>("/dataRoom/findAdditionalInfo", async (payload: {}, { rejectWithValue }) => {
  try {
    const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
    const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
    const token = tokenData ? tokenData.token : null;
    console.log(payload);

    const response = await enhancedFetch(
      `${API_URL}/dataRoom/findAdditionalInfo`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          itemType: "EMAIL",
        }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      return rejectWithValue(errorData.message || "An error occurred.");
    }

    const responseData = await response.json();
    return responseData.results;
  } catch (error: any) {
    return rejectWithValue(error.message || "An error occurred.");
  }
});

export const changeExpiry = createAsyncThunk<
  { results: ShareItem[]; count: number },
  { shareGuid: string; updatedDateString: string },
  { rejectValue: string }
>(
  "/dataRoom/changeExpiry",
  async (
    payload: { shareGuid: string; updatedDateString: string },
    { rejectWithValue }
  ) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(`${API_URL}/dataRoom/changeExpiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          shareGuid: payload.shareGuid,
          updatedDateString: payload.updatedDateString,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      Swal?.fire({
        icon: "success",
        titleText: "Expiry period has been updated",
      });
      return {
        results: responseData.results, // Assuming `message` contains the array of items
        count: responseData.count,
      };
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const manageAccess = createAsyncThunk<
  { message: {}; status: { statusCosde: number } },
  manageAccessPayload,
  { rejectValue: string }
>(
  "/dataRoom/manageAccess",
  async (payload: manageAccessPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(`${API_URL}/dataRoom/manageAccess`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          shareGuid: payload.shareGuid,
          emails: payload.emails,
          itemGuid: payload?.itemGuid,
          deleteItemGuid: payload.deleteItemGuid,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      Swal?.fire({
        icon: "success",
        titleText: "Access has been updated",
      });
      return responseData.message;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const shareItem = createAsyncThunk<
  { message: {}; status: { statusCosde: number } },
  sharePayload,
  { rejectValue: string }
>("/dataRoom/shareItem", async (payload: sharePayload, { rejectWithValue }) => {
  try {
    const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
    const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
    const token = tokenData ? tokenData.token : null;

    const response = await enhancedFetch(`${API_URL}/dataRoom/shareItem`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        emails: payload.emails,
        itemGuid: payload.itemGuid,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return rejectWithValue(errorData.message || "An error occurred.");
    }

    const responseData = await response.json();
    if (responseData?.status?.statusCode === 0) {
      Swal?.fire({
        icon: "success",
        titleText: `Item has been shared to receipents (${payload.emails?.length})`,
      });
    }

    return responseData.message;
  } catch (error: any) {
    return rejectWithValue(error.message || "An error occurred.");
  }
});

export const resend = createAsyncThunk<
  { results: ShareItem[]; count: number },
  { shareGuid: string },
  { rejectValue: string }
>(
  "/dataRoom/drResendHistoryUrl",
  async (payload: { shareGuid: string }, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/drResendHistoryUrl`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            shareGuid: payload.shareGuid,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.log(response);
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      if (responseData?.status?.statusCode === 0) {
        Swal?.fire({
          icon: "success",
          titleText: "Item has been re-shared",
        });
      }
      return {
        results: responseData.results,
        count: responseData.count,
      };
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const validateOtp = createAsyncThunk<
  { message: {}; status: { statusCosde: number } },
  { otp: string },
  { rejectValue: string }
>(
  "/docApp/validateOtp",
  async (payload: { otp: string }, { rejectWithValue }) => {
    try {
      const data = sessionStorage.getItem("verifyData");
      const jsonData = data ? JSON.parse(data) : null;
      const verifyData = jsonData ? jsonData?.saveData : null;

      const response = await fetch(`${API_URL}/docApp/validateOtp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //   Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          tenantId: verifyData?.tenantId,
          businessGuid: verifyData?.businessGuid,
          shareGuid: verifyData?.shareGuid,
          validatedEmail: verifyData?.validatedEmail,
          linkGuid: verifyData?.linkGuid,
          otp: +payload.otp,
        }),
      });

      // if (!response.ok) {
      //   const errorData = await response.json();
      //   console.log(response);
      //   return rejectWithValue(errorData.message || "An error occurred.");
      // }

      const responseData = await response.json();

      if (
        responseData?.status?.statusCode === 1 &&
        responseData?.status?.svcErrorCode != "EMCORE_ACC_LOCKED"
      ) {
        Swal.fire({
          icon: "error",
          title: responseData?.status?.errorTitle || "Error",
          html:
            `<strong style="display: block; margin-bottom: 10px;">${
              responseData?.status?.messageDescription || ""
            }</strong>` +
            (responseData?.status?.suggestion
              ? `<span>${responseData.status.suggestion}</span><br>
                    <a href="${responseData.status?.refUrl}" target="_blank" rel="noopener noreferrer" style="display: block; margin-top: 10px;">
                      ${responseData?.status?.refUrl ? `Reference: ${responseData.status?.refUrl}` : ""}
                    </a>`
              : responseData?.status?.refUrl
                ? `<a href="${responseData.status?.refUrl}" target="_blank" rel="noopener noreferrer">${responseData.status?.refUrl}</a>`
                : ""),
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }

      const shareToken = responseData.token;
      sessionStorage.setItem("shareToken", JSON.stringify({ shareToken }));
      return responseData;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const docApplistDirectory = createAsyncThunk<
  UserFolderData,
  { parentFolderGuid: string },
  { rejectValue: string }
>(
  "/docApp/docApplistDirectory",
  async (payload: { parentFolderGuid: string }, { rejectWithValue }) => {
    try {
      const sharetokenDataString = sessionStorage.getItem("shareToken");
      const sharetokenData = sharetokenDataString
        ? JSON.parse(sharetokenDataString)
        : null;
      const sharetoken = sharetokenData ? sharetokenData.shareToken : null;

      const response = await fetch(`${API_URL}/docApp/docApplistDirectory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sharetoken}`,
          app_type: "DOCAPP",
        },
        body: JSON.stringify({
          parentFolderGuid: payload.parentFolderGuid,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log(response);
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.message;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);
// Create slice
export const shareHistorySlice = createSlice({
  name: "shareHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listSharedHistory?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        listSharedHistory?.fulfilled,
        (
          state,
          action: PayloadAction<{ results: ShareItem[]; count: number }>
        ) => {
          state.loading = false;
          state.shareHistory = action.payload?.results;
          state.count = action.payload?.results?.length;
        }
      )
      .addCase(listSharedHistory?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      })
      .addCase(docApplistDirectory?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(docApplistDirectory?.fulfilled, (state, action) => {
        state.loading = false;
        state.docAppList = action.payload;
        state.count = action?.payload?.folders?.length;
      })
      .addCase(docApplistDirectory?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      })
      .addCase(findAdditionalInfo?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(findAdditionalInfo?.fulfilled, (state, action) => {
        state.loading = false;
        state.emailList = action.payload;
        // state.count = action?.payload?.folders?.length;
      })
      .addCase(findAdditionalInfo?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      });
  },
});

// Reducer
export default shareHistorySlice.reducer;
