import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Swal from "sweetalert2";
import axios from "axios";

// Define your API URL
const API_URL = process.env.REACT_APP_WEB_API_URL;

interface SearchCriteria {
  propertyName: string;
  propertyValue: string;
}

interface FetchProgramListPayload {
  searchCriteriaList: SearchCriteria[];
}

interface ClaimHistory {
  claimedDate: string;
  claimedId: string;
  docUrl: string;
  claimedAmount: string;
}

export interface Insurance {
  tenantId: string;
  bizzInsuranceGuid: string;
  bizzInsuranceName: string;
  headline: string;
  logoUrl: string;
  coverPercentage: string;
  coverdValue: string;
  uncoverdValue: string;
  planDetails: string;
  policyNumber: string;
  validTill: string;
  email: string;
  policyDocUrl: string;
  website: string;
  claimHistoryMapList: ClaimHistory[];
}

interface ProgramState {
  insurances: Insurance[];
  insurance: Insurance;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: ProgramState = {
  insurances: [],
  insurance: {
    tenantId: "",
    bizzInsuranceGuid: "",
    bizzInsuranceName: "",
    headline: "",
    logoUrl: "",
    coverPercentage: "",
    coverdValue: "",
    uncoverdValue: "",
    planDetails: "",
    policyNumber: "",
    validTill: "",
    email: "",
    policyDocUrl: "",
    website: "",
    claimHistoryMapList: [],
  },
  loading: false,
  error: null,
  success: false,
};

const getToken = (): string | null => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  return tokenData ? tokenData.token : null;
};

const apiCall = async (url: string, body: any) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const options = {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  };

  const response = await enhancedFetch(url, options);
  const data = await response.json();

  if (data.status.statusCode !== 0) {
    const errorMessage =
      data.status.messageDescription || data.message || "An error occurred.";

    throw new Error(errorMessage);
  }

  return data.results || data.message;
};

export const fetchInsuranceList = createAsyncThunk<
  Insurance[],
  FetchProgramListPayload,
  { rejectValue: string }
>(
  "businessInsurance/find",
  async ({ searchCriteriaList }, { rejectWithValue }) => {
    try {
      const url = `${API_URL}/businessInsurance/find`;
      const response = await apiCall(url, { searchCriteriaList });
      return response as Insurance[];
    } catch (error) {
      // Handle errors and return a rejected value if necessary
      return rejectWithValue("Failed to fetch program list");
    }
  }
);

export const findProgram = createAsyncThunk<
  Insurance,
  { programGuid: string },
  { rejectValue: string }
>("businessInsurance/view", async ({ programGuid }) => {
  const url = `${API_URL}/businessInsurance/view`;
  return await apiCall(url, { programGuid: programGuid });
});

export const messageBusinessInsurer = createAsyncThunk<
  { statusCode: number; messageDescription: string },
  { message: string; uploadFiles: File[] | null; bizzInsuranceGuid: string },
  { rejectValue: string }
>(
  "businessInsurance/messageBusinessInsurer",

  async ({ message, uploadFiles, bizzInsuranceGuid }, { rejectWithValue }) => {
    const token = getToken();

    if (!uploadFiles) {
      return rejectWithValue("No file selected.");
    }

    try {
      const formData = new FormData();
      uploadFiles.forEach((file) => {
        formData.append("uploadFiles", file);
      });
      formData.append("message", message);
      formData.append("bizzInsuranceGuid", bizzInsuranceGuid);
      //   formData.append("userId", userId);

      const response = await axios.post<{
        status: { statusCode: number; messageDescription: string };
      }>(`${API_URL}/businessInsurance/messageBusinessInsurer`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status.statusCode === 0) {
        Swal.fire({
          title: "Confirmation!",
          text: "Message sent to vendor",
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "Alert!",
          text: response.data.status.messageDescription,
          icon: "warning",
        });
      }

      return response.data.status;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

const insuranceSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsuranceList.pending, (state) => {
        state.loading = false;
      })
      .addCase(
        fetchInsuranceList.fulfilled,
        (state, action: PayloadAction<Insurance[]>) => {
          state.insurances = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(fetchInsuranceList.rejected, (state, action) => {
        state.error = action.payload || "An error occurred.";
        state.loading = false;
      })
      .addCase(findProgram.pending, (state) => {
        state.loading = false;
      })
      .addCase(
        findProgram.fulfilled,
        (state, action: PayloadAction<Insurance>) => {
          state.insurance = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(findProgram.rejected, (state, action) => {
        state.error = action.payload || "An error occurred.";
        state.loading = false;
      });
  },
});

export default insuranceSlice.reducer;
