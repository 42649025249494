import React from 'react'
import { Carousel, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import ReactCardFlip from 'react-card-flip';
import Select from 'react-select';
import blueCard from "../../../_metronic/assets/Icons/Blue_Plain.png";
import blackCard from "../../../_metronic/assets/Icons/Black_Plain.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Authorize from '../../modules/auth/components/Authorize';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import masterCard from "../../../_metronic/assets/Icons/mastercard-logo-transparent-png-stickpng-10.png";

const Cards = () => {
    const options=[{ value: "0", label: "All" },{ value: "1", label: "Marketing" }, { value: "2", label: "Food/Bevarges" }, { value: "3", label: "Office Suplies" }, { value: "4", label: "Travel" }, { value: "5", label: "Utility Bill" }]

  const tooltipAddMoney = <Tooltip id="tooltip-add-money">Add Money</Tooltip>;
  const tooltipBlock = (
    <Tooltip id="tooltip-block">
    Block
    </Tooltip>
  );
  const tooltipChangeLimit = (
    <Tooltip id="tooltip-change-limit">Change Limit</Tooltip>
  );
  const tooltipTransfer = <Tooltip id="tooltip-transfer">Transfer</Tooltip>;
  const tooltipClose = <Tooltip id="tooltip-closer">Close</Tooltip>;

  function maskCardNumber(cardNum: string): string {
    // Check for empty or undefined cardNum
    if (!cardNum) {
      return "";
    }

    // Remove spaces, then get the last four digits
    const cleanedCardNum = cardNum.replace(/\s+/g, "");
    const lastFourDigits = cleanedCardNum.slice(-4);

    // Construct the masked string with only two asterisks
    const masked = "** " + lastFourDigits;

    // Insert spaces to the masked string to maintain the format
    return masked;
  }
    return (
        <>
            <h4>Cards</h4>
            <div className='card shadow  mt-10'>
                <div className="card-header border-0 mt-5">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input
                                type="text"
                                data-kt-user-table-filter="search"
                                className="form-control form-control-solid w-250px ps-13"
                                placeholder="Search "
                            //   value={searchValue}
                            //   onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <div className="card-toolbar">
                        <div
                            className="d-flex justify-content-end"
                            data-kt-customer-table-toolbar="base"
                        >
                            {/* {filter && (
                      <div className="d-flex align-items-center me-5 mt-3 justify-content-center">
                        <a
                          className="text-danger text-hover-danger fw-bold"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            filterFormik.resetForm();
                            setSelectedTypes([]);
                          }}
                        >
                          Clear Filters
                        </a>
                      </div>
                    )} */}
                            <div>
                                {/* <button
                                        type="button"
                                        className="btn me-3"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        id="kt-toolbar-filter"
                                    //   onClick={handleTransType}
                                    >
                                        <i className="ki-duotone ki-filter fs-2 text-light">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                        Filter
                                    </button> */}
                                <div
                                    className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                                    data-kt-menu="true"
                                    id="kt-toolbar-filter"
                                >
                                    <div className="px-7 py-5">
                                        <div className="fs-4 text-dark fw-bold">
                                            Filter Options
                                        </div>
                                    </div>
                                    <form>
                                        <div className="separator border-gray-200"></div>
                                        <div className="px-7 py-5">
                                            {/* By Status */}
                                            <div
                                                className="mb-5"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <label className="form-label fs-5 fw-semibold mb-3">By Tax Type</label>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusActive"
                                                            name="status"
                                                            value="Active"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusActive">
                                                            Corporate Income Tax
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusInactive"
                                                            name="status"
                                                            value="Inactive"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusInactive">
                                                            VAT
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            PAYE
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            UIF
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            SDL
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            Turnover Tax
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusPending"
                                                            name="status"
                                                            value="Pending Approval"
                                                        />
                                                        <label className="form-check-label" htmlFor="statusPending">
                                                            Provisional Tax
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* By Type */}
                                            <div className="mb-10">
                                                <label className="form-label fs-5 fw-semibold mb-3">By Status</label>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeRemittance"
                                                            name="type"
                                                            value="Remittance Partner"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeRemittance">
                                                            Paid
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeIDAuth"
                                                            name="type"
                                                            value="ID Authentication"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeIDAuth">
                                                            Overdue
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeSMS"
                                                            name="type"
                                                            value="SMS"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeSMS">
                                                            Due
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            type="reset"
                                                            className="btn btn-light btn-active-light-primary me-2"
                                                            data-kt-menu-dismiss="true"
                                                            data-kt-customer-table-filter="reset"
                                                        //   onClick={filterFormik.handleReset}
                                                        >
                                                            Reset
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            data-kt-customer-table-filter="filter"
                                                            data-kt-menu-dismiss="true"
                                                        //   disabled={filterFormik.isSubmitting}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>
                            <div>
                            <button
                                    type="button"
                                    className="btn  me-3"

                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_add_fund"
                                >

                                   Add Funds
                                </button>

                                <button
                                    type="button"
                                    className="btn  me-3"

                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_add_event"
                                >

                                   Assign Card
                                </button>


                            </div>






                        </div>


                    </div>
                    {/* <!--end::Card toolbar--> */}
                </div>
                <div className="card-body p-5 ms-5 me-5">
                    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_1"
                            >
                                All
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_2"
                            >
                                Active
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_3"
                            >
                                Blocked
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_4"
                            >
                                Unassigned
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_5"
                            >
                                Approval
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade active show"
                            id="kt_tab_pane_1"
                            role="tabpanel"
                        >
                            <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Card Number</th>
                                        <th>Card Type</th>
                                        <th>Assigned To</th>
                                        <th>Role</th>
                                        <th>Usage Limit</th>
                                        <th>Status</th>
                                       
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td><div >**** **** 5432</div></td>
                                        <td><div className='badge badge-light-info'>Prepaid</div></td>
                                        <td><div >John Doe</div></td>
                                        <td><div >Finance</div></td>
                                        <td><div>ZAR 5000</div></td>
                                        <td><div className='badge badge-light-success'>Active</div></td>
                                       
                                        <td>
                                            <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye' id="kt_partner_toggle"></i></div>
                                                <div><i className='bi bi-receipt'></i></div>
                                                <div><i className='bi bi-wallet2'></i></div>
                                                <div><i className='bi bi-play-fill'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div >**** **** 2109</div></td>
                                        <td><div className='badge badge-light-info'>Prepaid</div></td>
                                        <td><div className=''>Jane Smith</div></td>
                                        <td><div >Marketing</div></td>
                                        <td><div className=''>ZAR 3000</div></td>
                                        <td><div className='badge badge-light-warning '>Inactive</div></td>
                                       
                                        <td>
                                        <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye' id="kt_partner_toggle"></i></div>
                                                <div><i className='bi bi-receipt'></i></div>
                                                <div><i className='bi bi-wallet2'></i></div>
                                                <div><i className='bi bi-play-fill'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div >**** **** 8765</div></td>
                                        <td><div className='badge badge-light-info '>Prepaid</div></td>
                                        <td><div className=''>Michael Brown</div></td>
                                        <td><div >Food/Beverages</div></td>
                                        <td><div className=''>ZAR 10000</div></td>
                                        <td><div className='badge badge-light-primary '>Active</div></td>
                                      
                                        <td>
                                        <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye' id="kt_partner_toggle"></i></div>
                                                <div><i className='bi bi-receipt'></i></div>
                                                <div><i className='bi bi-wallet2'></i></div>
                                                <div><i className='bi bi-play-fill'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>**** **** 5678</div></td>
                                        <td><div className='badge badge-light-info '>Prepaid</div></td>
                                        <td><div className=''>Emily Clark</div></td>
                                        <td><div >Travel</div></td>
                                        <td><div className=''>ZAR 2000</div></td>
                                        <td><div className='badge badge-light-danger '>Blocked</div></td>
                                        
                                        <td>
                                        <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye' id="kt_partner_toggle"></i></div>
                                                <div><i className='bi bi-receipt'></i></div>
                                                <div><i className='bi bi-wallet2'></i></div>
                                                <div><i className='bi bi-play-fill'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div >**** **** 1234</div></td>
                                        <td><div className='badge badge-light-info  '>Prepaid</div></td>
                                        <td><div className=''>Olivia Taylor</div></td>
                                        <td><div >Utility</div></td>
                                        <td><div className=''>ZAR 8000</div></td>
                                        <td><div className='badge badge-light-success '>Active</div></td>
                                      
                                        <td>
                                        <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye' id="kt_partner_toggle"></i></div>
                                                <div><i className='bi bi-receipt'></i></div>
                                                <div><i className='bi bi-wallet2'></i></div>
                                                <div><i className='bi bi-play-fill'></i></div>
                                            </div>
                                        </td>
                                    </tr>





                                </tbody>
                            </table>

                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                        <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Card Number</th>
                                        <th>Card Type</th>
                                        <th>Assigned To</th>
                                        <th>Role</th>
                                        <th>Usage Limit</th>
                                        <th>Status</th>
                                       
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td><div >**** **** 5432</div></td>
                                        <td><div className='badge badge-light-info'>Prepaid</div></td>
                                        <td><div >John Doe</div></td>
                                        <td><div >Finance</div></td>
                                        <td><div>ZAR 5000</div></td>
                                        <td><div className='badge badge-light-success'>Active</div></td>
                                      
                                        <td>
                                        <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye' id="kt_partner_toggle"></i></div>
                                                <div><i className='bi bi-receipt'></i></div>
                                                <div><i className='bi bi-wallet2'></i></div>
                                                <div><i className='bi bi-play-fill'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                 





                                </tbody>
                            </table>

                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                        <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Card Number</th>
                                        <th>Card Type</th>
                                        <th>Assigned To</th>
                                        <th>Role</th>

                                        <th>Usage Limit</th>
                                        <th>Status</th>
                                      
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    
                                    <tr>
                                        <td><div >**** **** 2109</div></td>
                                        <td><div className='badge badge-light-info'>Prepaid</div></td>
                                        <td><div className=''>Jane Smith</div></td>
                                        <td><div >Marketing</div></td>
                                        <td><div className=''>ZAR 3000</div></td>
                                        <td><div className='badge badge-light-danger '>Blocked</div></td>
                                       
                                        <td>
                                        <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye' id="kt_partner_toggle"></i></div>
                                                <div><i className='bi bi-receipt'></i></div>
                                                <div><i className='bi bi-wallet2'></i></div>
                                                <div><i className='bi bi-play-fill'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                   





                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
                        <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Card Number</th>
                                        <th>Card Type</th>
                                        <th>Assigned To</th>
                                        <th>Role</th>
                                        <th>Usage Limit</th>
                                        <th>Status</th>
                                      
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td><div >-</div></td>
                                        <td><div className='badge badge-light-info'>-</div></td>
                                        <td><div >John Doe</div></td>
                                        <td><div></div></td>
                                        <td><div></div></td>
                                        <td><div >-</div></td>
                                        
                                        <td>
                                        <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye' id="kt_partner_toggle"></i></div>
                                                <div><i className='bi bi-receipt'></i></div>
                                                <div><i className='bi bi-wallet2'></i></div>
                                                <div><i className='bi bi-play-fill'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                   



                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                        <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Card Number</th>
                                        <th>Card Type</th>
                                        <th>Assigned To</th>
                                        <th>Role</th>
                                        <th>Usage Limit</th>
                                        <th>Approval Status</th>
                                        
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td><div >-</div></td>
                                        <td><div className='badge badge-light-info'>-</div></td>
                                        <td><div >John Doe</div></td>
                                        <td><div></div></td>
                                        <td><div></div></td>
                                        <td><div className='badge badge-light-warning' >Pending</div></td>
                                        
                                        <td>
                                        <div className='d-flex flex-row gap-4'>
                                                <div><i className='bi bi-eye' id="kt_partner_toggle"></i></div>
                                                <div><i className='bi bi-receipt'></i></div>
                                                <div><i className='bi bi-wallet2'></i></div>
                                                <div><i className='bi bi-play-fill'></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                   



                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <div
                className="modal fade"
                id="kt_modal_add_event"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div
                        className="modal-content"
                        style={{ maxHeight: "700px", overflow: "auto" }}
                    >
                        <div
                            className="modal-header d-flex justify-content-between"
                            id="kt_modal_add_user_header"
                        >
                            <h2 className="fw-bold">Assign Card</h2>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            <div className="fv-row mb-7 ">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                    Select User
                                </label>
                                <select className="form-select" data-control="select2" data-placeholder="Select an option">
                                    <option></option>
                                    <option value="1">Raju&nbsp;(Finance)</option>
                                    <option value="2">Ravi&nbsp;(Marketing)</option>
                                    <option value="3">Ajinkya&nbsp;(Operations)</option>
                                    <option value="4">Shashank&nbsp;(Travel)</option>


                                </select>
                            </div>
                            <div className="fv-row mb-7 ">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                   Card Limit
                                </label>
                                <div className="position-relative">
                                        <input
                                            type="text"
                                            name="amount"
                                            className="form-control ps-20"
                                            placeholder="Card Limit"
                                            // value={formik.values.amount}
                                            // onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            maxLength={10}
                                        />
                                        <span className="position-absolute top-50 start-0 translate-middle-y text-dark ps-3 fw-bold">ZAR</span>
                                    </div>
                            </div>
                            <div className="fv-row mb-7 ">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                Per Transaction Limit
                                </label>
                                <div className="position-relative">
                                        <input
                                            type="text"
                                            name="amount"
                                            className="form-control ps-20"
                                            placeholder=" Per Transaction Limit"
                                            // value={formik.values.amount}
                                            // onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            maxLength={10}
                                        />
                                        <span className="position-absolute top-50 start-0 translate-middle-y text-dark ps-3 fw-bold">ZAR</span>
                                    </div>
                            </div>
                            <div className="fv-row mb-7 ">
                                                {/* begin::Label */}
                                                <label className="required fw-semibold fs-6 mb-2">
                                                   Category Limit
                                                </label>
                                                <Select className='w-100'  isMulti 
                                                options={options}
                                                  onChange={(selectedOptions) => console.log(selectedOptions.some(option => option.value === "0") ? options.filter(option => option.value !== "0") : selectedOptions)} placeholder="Select Type" />

                                            </div>





                            <div className="text-center mt-10">
                                <button
                                    type="reset"
                                    // onClick={() => cancel()}
                                    className="btn btn-light me-3"
                                    data-kt-users-modal-action="cancel"
                                >
                                    Discard
                                </button>

                                <button
                                    type="submit"
                                    className="btn"
                                    data-kt-users-modal-action="submit"
                                >
                                    <span className="indicator-label">Submit</span>
                                    {/* {formik.isSubmitting && (
                                                            <span className="indicator-progress">
                                                                Please wait...{" "}
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )} */}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="kt_modal_add_fund"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div
                        className="modal-content"
                        style={{ maxHeight: "700px", overflow: "auto" }}
                    >
                        <div
                            className="modal-header d-flex justify-content-between"
                            id="kt_modal_add_user_header"
                        >
                            <h2 className="fw-bold">Add Funds</h2>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            <div className="fv-row mb-5">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                    Select Card
                                </label>
                                <select className="form-select" data-control="select2" data-placeholder="Select an option">
                                    <option>Select</option>
                                    <option value="1">**** **** 2046 &nbsp;&nbsp;(Ravi)</option>
                                    <option value="2">**** **** 4045 &nbsp;&nbsp;(Rahul)</option>
                                    <option value="3">**** **** 3446 &nbsp;&nbsp;(Tejesh)</option>


                                </select>
                            </div>

                            <div>
                                <div className='fw-bold'>Card Limit&nbsp;- <span className='fw-normal'>ZAR 10,000</span>  </div>
                                <div className='fw-bold mt-2'>Available Balance&nbsp;-  <span className='fw-normal'>ZAR 7,000</span>  </div>
                                <div className='fw-bold mt-2'>Remaining Topup value&nbsp;-  <span className='fw-normal'>ZAR 3,000</span>  </div>
                            </div>
                            <div className="fv-row mb-7 mt-7">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                   Amount
                                </label>
                                <div className="position-relative">
                                        <input
                                            type="text"
                                            name="amount"
                                            className="form-control ps-20"
                                           
                                            // value={formik.values.amount}
                                            // onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            maxLength={10}
                                        />
                                        <span className="position-absolute top-50 start-0 translate-middle-y text-dark ps-3 fw-bold">ZAR</span>
                                    </div>
                            </div>
                           





                            <div className="text-center mt-10">
                                <button
                                    type="reset"
                                    // onClick={() => cancel()}
                                    className="btn btn-light me-3"
                                    data-kt-users-modal-action="cancel"
                                >
                                    Discard
                                </button>

                                <button
                                    type="submit"
                                    className="btn"
                                    data-kt-users-modal-action="submit"
                                >
                                    <span className="indicator-label">Submit</span>
                                    {/* {formik.isSubmitting && (
                                                            <span className="indicator-progress">
                                                                Please wait...{" "}
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )} */}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="kt__partner_modal"
                className="bg-body"
                data-kt-drawer="true"
                data-kt-drawer-name="modal"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'449px', 'md': '455px'}"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_partner_toggle"
                data-kt-drawer-close="#kt_close"
            >
                <div
                    className="modal-dialog modal-fullscreen modal-dialog-end"
                    role="document"
                >
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header d-flex justify-content-between mt-5 mb-2 d-flex flex-row mx-10">
                            <div>
                                <h5 className="modal-title fw-bold fs-4">Card Details</h5>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                    aria-label="Close"
                                    id="kt_close"
                                >
                                    <i className="ki-duotone ki-cross fs-2x">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </button>
                            </div>
                        </div>
                        <hr className="w-100" />

                        <div className="modal-body w-100 p-5">
                            <div className="card shadow-sm p-4">
                                <div className="row">

                                <Carousel
                // onSelect={handleSelect}
                // activeIndex={selectedIndex}
                indicators={false}
                controls={false}
                
                
                interval={null}
                className="card shadow-sm justify-content-center p-5 w-100"
              >
                {/* {cards.length > 0 ? (
                  cards.map((card, index) => ( */}
                    <Carousel.Item
                    
                      className="justify-content-center"
                    >
                      <div className="d-flex flex-column">
                        <ReactCardFlip
                        //   isFlipped={!hide}
                          flipDirection="horizontal"
                          flipSpeedFrontToBack={1}
                          containerStyle={{}}
                          containerClassName="m-auto justify-content-center"
                        >
                          <div
                           
                            style={{
                              height: "220px",
                              width: "383.3px",
                              marginBottom:"10px",
                              
                              backgroundImage:
                              `url(${blackCard})`,
                              backgroundSize: "auto",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "10px",
                              
                              position: "relative",
                            }}
                          >
                            <div className="d-flex flex-row mb-10 justify-content-between align-items-center mx-10">
                              <div>
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/logos/xfin Logo.png"
                                  )}
                                  className="h-35px app-sidebar-logo-default mt-5 "
                                />
                              </div>
                              <Authorize hasAnyPermission={["MENU_CARDS|VIEW"]}>
                                <i
                                 
                                  onClick={() => {
                                    // handleViewClick(card);
                                    // setHide(!hide);
                                  }}
                                ></i>
                              </Authorize>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mx-5">
                              <div className="d-flex flex-row gap-5 align-items-center">
                                <div>
                                  {/* <p className="text-white m-0">
                                    {card?.cardName}
                                  </p> */}
                                 
                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      overlay={
                                        <Popover
                                          className="p-2 bg-light-secondary"
                                          id="popover-trigger-focus"
                                        >
                                        Shopping
                                        </Popover>
                                      }
                                    >
                                      <p className="text-white m-0">
                                        Shopping
                                      </p>
                                    </OverlayTrigger>
                                  
                                  <p className="fs-3 text-white">
                                    {maskCardNumber("7435842758978569")}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <img
                                  className="text-white"
                                  src={masterCard}
                                  style={{ width: "90px", height: "70px" }}
                                />
                              </div>
                            </div>
                          </div>

                          {/* {Card Back} */}
                          <div
                            className={`d-flex flex-column p-10 mb-5 `}
                            style={{
                              height: "220px",
                              width: "100%",
                             
                              backgroundSize: "auto",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "10px",
                              position: "relative",
                            }}
                          >
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div className="d-flex flex-row align-items-end ">
                                <div>
                                  <p className="text-white text-start m-0">
                                    Card Name
                                  </p>
                                  {/* <p className="fw-bold text-white fs-5">
                                    {card?.cardName}
                                  </p> */}
                                 
                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      overlay={
                                        <Popover
                                          className="p-2 bg-light-secondary"
                                          id="popover-trigger-focus"
                                        >
                                          Shopping
                                        </Popover>
                                      }
                                    >
                                      <p className="fw-bold text-white fs-5">
                                       Shopping
                                      </p>
                                    </OverlayTrigger>
                                  
                                </div>
                              </div>
                              <i
                                // className={`bi bi-eye${
                                //   hide ? "" : "-slash"
                                // } fs-3 text-white me-10 mb-5 cursor-pointer`}
                                // onClick={() => {
                                //   // getCard();
                                //   setHide(!hide);
                                // }}
                              ></i>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div className="d-flex flex-row align-items-end ">
                                <div>
                                  <p className="text-white text-start m-0">
                                    Card Number
                                  </p>
                                  <p className="fw-bold text-white fs-5">
                                   6357 6566 3535 4554
                                  </p>
                                </div>
                                {/* <i className="fa-light fa-copy"></i> */}
                               
                              </div>
                              <div>
                                <p className="text-white m-0">Expiry Date</p>
                                <p className="fw-bold text-white fs-5">
                                  22/07/2027
                                </p>
                              </div>
                            </div>
                            <div className="d-flex flex-row gap-20 align-items-center">
                              <div className="d-flex flex-row gap-12">
                                <div>
                                  <p className="text-white m-0">CVV</p>
                                  <p className="fw-bold text-white fs-5 ">
                                   544
                                  </p>
                                </div>
                                <div>
                                  <p className="text-white m-0">Card Limit</p>
                                  <p className="fw-bold text-white fs-5 text-nowrap">
                                   ZAR&nbsp;
                                   10,000
                                  </p>
                                </div>
                                <div>
                                  <p className="text-white m-0">Balance</p>
                                  <p className="fw-bold text-white fs-5 text-nowrap">
                                  ZAR&nbsp;
                                   10,000
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ReactCardFlip>
                        {/* {card?.status === "Blocked" && ( */}
                          <div
                            className=" mb-0 bg-dark "
                            style={{
                              width: "383.3px",
                              position: "absolute",
                              bottom: "56px", // Adjust as needed to place the image correctly
                              // left: "0",
                              // right: "0",
                              backgroundImage:
                              `url(${blackCard})`,
                               
                              borderRadius: "0 0 10px 10px",
                              display: "flex",
                              alignSelf: "center",
                              justifyContent: "center",
                            }}
                          >
                            {/* <img
                              className="text-white"
                              src={blockedImg2}
                              style={{
                                width: "90px", // Adjust size as needed
                                height: "50px",
                                objectFit: "contain",
                              }}
                            /> */}
                          </div>
                        

                        <div className="d-flex justify-content-center">
                          <div className="d-flex gap-7 jusftiy-content-center">
                          
                              <Authorize hasAnyPermission={["MENU_CARDS|ADD"]}>
                                <div
                                //   style={{
                                //     opacity:
                                //       blocked === "Blocked" ||
                                //       card?.status === "Blocked"
                                //         ? 0.5
                                //         : 1,
                                //     pointerEvents:
                                //       blocked === "Blocked" ||
                                //       card?.status === "Blocked"
                                //         ? "none"
                                //         : "auto",
                                //   }}
                                >
                                  {" "}
                                  {/* <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipAddMoney}
                                  >
                                    <button
                                      className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                                      style={{
                                        height: "40px",
                                        width: "40px",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        // setAction("AddMoney");
                                        // handleAddMoney();
                                        // setButton("Next");
                                        // setLoading(false);
                                        // setRuleData(null);
                                      }}
                                    >
                                      <span className="svg-icon svg-icon-1">
                                        <i className="bi bi-plus-lg text-light"></i>
                                      </span>
                                    </button>
                                  </OverlayTrigger> */}
                                </div>
                              </Authorize>
                         
                            <Authorize hasAnyPermission={["MENU_CARDS|EDIT"]}>
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipBlock}
                              >
                                <button
                                  className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    outline: "none",
                                    borderRadius: "5px",
                                    border: "0px",
                                  }}
                                  onClick={() => {
                                    // setAction("Block");
                                    // handleFreeze();
                                  }}
                                  // onMouseOver={() => {
                                  //   setIconColour;
                                  // }}
                                >
                                  <span className="svg-icon svg-icon-1 black-icon">
                                    <i className="bi bi-snow black-icon text-light"></i>
                                  </span>
                                </button>
                              </OverlayTrigger>
                            </Authorize>
                            <Authorize hasAnyPermission={["MENU_CARDS|EDIT"]}>
                              <div
                               
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipChangeLimit}
                                >
                                  <button
                                    className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      outline: "none",
                                      borderRadius: "5px",
                                      border: "0px",
                                    }}
                                    onClick={() => {
                                    //   handleChangeLimit();
                                    }}
                                  >
                                    <span className="svg-icon svg-icon-1">
                                      <i className="bi bi-pencil-square black-icon text-light"></i>
                                    </span>
                                  </button>
                                </OverlayTrigger>
                              </div>
                            </Authorize>

                          
                              <Authorize hasAnyPermission={["MENU_CARDS|ADD"]}>
                                <div
                                //   style={{
                                //     opacity:
                                //       blocked === "Blocked" ||
                                //       card?.status === "Blocked"
                                //         ? 0.5
                                //         : 1,
                                //     pointerEvents:
                                //       blocked === "Blocked" ||
                                //       card?.status === "Blocked"
                                //         ? "none"
                                //         : "auto",
                                //   }}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipTransfer}
                                  >
                                    <div
                                      className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                                      style={{ height: "40px", width: "40px" }}
                                      onClick={() => {
                                        // handleTransferMoney();
                                      }}
                                    >
                                      <span className="svg-icon svg-icon-1 black-icon">
                                        <i className="bi bi-cash-coin black-icon text-light"></i>
                                      </span>
                                    </div>
                                  </OverlayTrigger>
                                </div>
                              </Authorize>
                        
                            <Authorize hasAnyPermission={["MENU_CARDS|DELETE"]}>
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipClose}
                              >
                                <div
                                  className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                                  onClick={() => {
                                    // setAction("Close");
                                    // handleClose();
                                  }}
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    
                                  }}
                                >
                                  <span className="svg-icon svg-icon-1">
                                    <i className="bi bi-x-circle black-icon text-light"></i>
                                  </span>
                                </div>
                              </OverlayTrigger>
                            </Authorize>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  {/* ))
                ) : (
                  <p className="text-center fs-4">No cards available</p>
                )} */}
              </Carousel>
                                 


                                    {[
                                        { label: "Card Type", value: "Prepaid" },
                                        { label: "Card Provider", value: "Mastercard - VISA " },
                                        { label: "Card Number", value: "8764 2355 2355 2445" },
                                        { label: "Assigned To", value: "John" },
                                        { label: "Assigned By", value: "Ajinkya" },
                                        { label: "Usage Limit", value: "ZAR 5000" },
                                        { label: "Per Transaction Limit", value: "ZAR 1,000" },
                                        { label: "Status", value: "Active" },
                                        

                                    ].map((item, index) => (
                                        <div className="col-12 d-flex justify-content-between py-4" key={index}>
                                            <div className="fw-bold text-dark">{item.label}</div>
                                            <div className={`${item.label === "Status" ? "badge badge-light-success" : "text-muted"}`}>
                                                {item.value}
                                            </div>
                                            {item.label === "Action" && (
                                                <>
                                                    <div >
                                                        <div className="mb-10">
                                                            <div className="form-check form-switch form-check-custom form-check-solid">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexSwitchDefault" />

                                                            </div>
                                                        </div>


                                                    </div></>
                                            )
                                            }

                                        </div>
                                    ))}
                                </div>


                            </div>
                        </div>


                    </div>
                </div>
            </div>
         
        </>
    )
}

export default Cards