import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { docApplistDirectory } from "./shareHistorySlice";
import { RootState } from "../../store";
import { downloadFile } from "./Folder";
import { downloadFolder, Folders, UserFolderData } from "./documentRoomSlice";
import pdf from "../../../_metronic/assets/all-serviceIcons/pdf-file.svg";
import docx from "../../../_metronic/assets/all-serviceIcons/docx-file.svg";
import jpeg from "../../../_metronic/assets/all-serviceIcons/image-files.svg";
import xlsx from "../../../_metronic/assets/all-serviceIcons/excel-file.svg";
import { Modal } from "bootstrap";

const ViewListing: FC = () => {
  const tokenDataString = sessionStorage.getItem("shareToken");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.shareToken : null;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [searchValue, setSearchValue] = useState("");
  const [fileName, setFileName] = useState<string | undefined>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [profileimg, setProfileImg] = useState<string | undefined>("");
  const [profiledoc, setProfileDoc] = useState<string | undefined>("");

  const { docAppList, count } = useSelector(
    (state: RootState) => state?.docAppList
  );

  const handleViewClick = async () => {
    const modalElement = document.getElementById("docu_image_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDocViewClick = async () => {
    const modalElement = document.getElementById("docu_pdf_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     dispatch(docApplistDirectory({} as any) as any);
  //   }, 300);

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchValue]);

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchValue(event.target.value);
  //   setCurrentPage(1);
  // };

  useEffect(() => {
    dispatch(docApplistDirectory({} as any) as any);
  }, [token]);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(count / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  return (
    <div className="card p-5 m-5">
      {" "}
      <div className="navbar">
        <h4 className="mb-4">Document Room - Shared Links</h4>{" "}
      </div>
      <div
        className="card p-2 h-100"
        style={{
          // maxHeight: "600px",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center  position-relative my-1">
            {/* <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              data-kt-docs-table-filter="search"
              className="form-control p-3 border-secondary w-250px ps-15"
              placeholder="Search Files & Folders"
              defaultValue={searchValue}
              onChange={handleSearchChange}
            /> */}
          </div>
          <div>
            <button
              className="btn btn-sm"
              onClick={() => {
                sessionStorage?.removeItem("shareToken");
                navigate(-1);
              }}
            >
              Exit
            </button>
          </div>
        </div>

        <div className="d-flex mb-5 bg-light-primary">
          <div className="d-flex p-2 ">
            <p
              className="mb-0 text-primary cursor-pointer"
              onMouseEnter={(e) =>
                (e.currentTarget.style.textDecoration = "underline")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.textDecoration = "none")
              }
              onClick={() => {
                const folderGuid =
                  docAppList?.folderToParentMapping[docAppList?.businessGuid];
                dispatch(
                  docApplistDirectory({
                    parentFolderGuid: folderGuid,
                  } as any) as any
                );
              }}
            >
              root <i className="bi bi-chevron-right fs-8 text-dark px-2"></i>
            </p>
            {docAppList?.rootDirectory
              ?.split("/")
              ?.filter((path: string) => path !== "")
              ?.map((path: string, index: number) => (
                <div key={index} className="d-flex align-items-center">
                  <p
                    key={index}
                    className="mb-0 text-primary cursor-pointer"
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.textDecoration = "none")
                    }
                    onClick={() => {
                      const folderGuid =
                        docAppList?.folderToParentMapping[path];
                      console.log(folderGuid);

                      if (folderGuid) {
                        dispatch(
                          docApplistDirectory({
                            parentFolderGuid: folderGuid,
                          } as any) as any
                        );
                      }
                    }}
                  >
                    {path}
                  </p>
                  <i className="bi bi-chevron-right fs-8 text-dark px-2"></i>
                </div>
              ))}
          </div>
        </div>

        <table
          className="table align-middle border rounded table-row-dashed fs-6 g-5"
          // style={{
          //   // maxHeight: "600px",
          //   overflowY: "scroll",
          //   overflowX: "scroll",
          // }}
        >
          <thead className="bg-gray-200">
            <tr className="text-start text-dark-600 fw-bold fs-6 ">
              <th className="min-w-200px p-5">Name</th>
              <th className="min-w-100px p-5">Expiry Date</th>
              <th className="min-w-100px p-5">Action</th>
            </tr>
          </thead>

          <tbody>
            {docAppList?.folders?.length > 0 ? (
              docAppList?.folders?.map((item: Folders, index: number) => (
                <tr
                  key={index}
                  onMouseOver={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "#f1f1f2";
                  }}
                  onMouseOut={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "white";
                  }}
                >
                  <td className="p-5 text-gray-600 cursor-pointer fw-hover-bold fs-6">
                    <div className=" d-flex flex-row gap-3 align-items-center">
                      {item?.type === "FOLDER" ? (
                        <i
                          className={`bi bi-folder2-open fs-2 text-${
                            item.folderGuid === "System Generated"
                              ? "primary"
                              : "dark"
                          }`}
                        ></i>
                      ) : (
                        <img
                          src={
                            item?.fileName?.split(".").pop() === "pdf"
                              ? pdf
                              : ["jpeg", "png", "jpg"].includes(
                                    item?.fileName?.split(".").pop() || ""
                                  )
                                ? jpeg
                                : ["xlsx", "csv"].includes(
                                      item?.fileName?.split(".").pop() || ""
                                    )
                                  ? xlsx
                                  : docx
                          }
                          alt=""
                          style={{ width: "20px", height: "20px" }}
                        />
                      )}
                      <p
                        className={`text-${item.source === "SYSTEM GENERATED" ? "primary" : "gray-600"} fw-hover-bold fs-6 mb-0`}
                        onClick={() => {
                          if (item?.fileName) {
                            if (
                              !["jpeg", "png", "jpg"].includes(
                                (
                                  item?.fileName?.split(".").pop() || ""
                                ).toLowerCase()
                              )
                            ) {
                              setFileName(item?.fileName);
                              setProfileDoc(item?.readUrl);
                              handleDocViewClick();
                            } else {
                              setProfileImg(item?.readUrl);
                              handleViewClick();
                            }
                          } else if (item.folderName) {
                            dispatch(
                              docApplistDirectory({
                                parentFolderGuid: item.folderGuid,
                              } as any) as any
                            );
                          }
                        }}
                      >
                        {item.folderName ? item.folderName : item.fileName}
                      </p>
                    </div>
                  </td>
                  <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                    <p className="mb-0"> {item.lastModified}</p>
                  </td>
                  <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                    <i
                      className="bi bi-download fs-5 text-dark cursor-pointer"
                      onClick={() => {
                        if (item.type === "FOLDER") {
                          dispatch(
                            downloadFolder({
                              url: "downloadFolder",
                              folderGuid: item.folderGuid,
                              folderName: item?.folderName,
                              appType: "DOCAPP",
                            }) as any
                          );
                        } else {
                          downloadFile(item?.readUrl, item.fileName);
                        }
                      }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(count / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage === Math.ceil(count / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(Math.ceil(count / itemsPerPage))
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {count}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <div className="modal" tabIndex={-1} id="docu_image_modal">
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <div className="modal-body justify-content-center d-flex">
              <div
                className="image-input image-input-outline"
                data-kt-image-input="true"
              >
                <img
                  className="image"
                  src={profileimg}
                  style={{
                    borderRadius: "10px",
                    objectFit: "contain",
                    maxWidth: "500px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="docu_pdf_modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
          <div
            className="modal-content"
            style={{ maxHeight: "700px", overflow: "auto" }}
          >
            <div className="modal-header" id="kt_modal_add_user_header">
              <h3 className="fw-bold m-0">PDF Viewer</h3>

              {/* <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  data-bs-dismiss="modal"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div> */}
            </div>

            <div className="modal-body px-15">
              {fileName
                ?.substring(fileName.lastIndexOf(".") + 1)
                .toLowerCase() === "pdf" ? (
                <embed
                  src={`${profiledoc}#pagemode=none`}
                  type="application/pdf"
                  width="100%"
                  height="800px"
                />
              ) : fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "xlsx" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "xls" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "csv" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "doc" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "docx" ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${profiledoc}`}
                  width="100%"
                  height="800px"
                  style={{ border: "none" }}
                />
              ) : fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "txt" ? (
                <iframe
                  src={profiledoc}
                  width="100%"
                  height="800px"
                  style={{ border: "none" }}
                  title="Text File Viewer"
                />
              ) : fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "png" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "jpeg" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "jpg" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "gif" ||
                fileName
                  ?.substring(fileName.lastIndexOf(".") + 1)
                  .toLowerCase() === "svg" ? (
                <img
                  className="image"
                  src={profiledoc}
                  alt="Preview"
                  style={{
                    borderRadius: "10px",
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "800px",
                  }}
                />
              ) : (
                <p>Unsupported file type</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ViewListing };
