import React from 'react'
import { Chart } from "react-google-charts";

const Dashboard = () => {
  const data1 = [
    ["Task", "Hours per Day"],
    ["Issued cards", 11],
    ["Active", 2],
    ["Pending activation", 2],
    ["Blocked", 2],

  ];
  const data2 = [
    ["Marketing", "Hours per Day"],
    ["Marketing", 5],
    ["Food/Beverages", 11],
    ["Office Supplies", 2],
    ["Travel", 2],
    ["Utility Bills", 2],

  ];

  const data = [
    ["Task", "Hours per Day"],
    ["People in card program", 11],
    ["Active", 2],
    ["Yet to register", 2],
    ["Without card", 2],
    ["Card deactivated", 7], // CSS-style declaration
  ];

  const options = {

    pieHole: 0.6, // Donut chart style
    is3D: false,  // Flat chart
    chartArea: { width: "90%", height: "80%" }, // Slimmer chart area
    legend: { position: "left", alignment: "top" }, // Compact legend
    fontSize: 12, // Smaller font size
    slices: {
      0: { color: "#0d6efd" },
      1: { color: "#198754" }, // Light orange
      2: { color: "#6f42c1" }, // Light yellow
      3: { color: "#d63384" }, // Light green
      4: { color: "#20c997" }, // Light red
    },
  };

  return (
    <>

      <h4>Dashboard</h4>
      <div className='card shadow p-5'>
        <div className="d-flex flex-row justify-content-between gap-5">
          <div
            className="card p-5 shadow w-100 bg-secondary text-dark"
            style={{ height: "150px" }}
          >
            <div className="fs-2x fw-bold mt-3 ">ZAR 100,000</div>
            <div className="mt-3 fs-5">Primary A/C</div>
            <div className='text-gray fs-7 d-flex align-items-center mt-5'>
              <i className='bi bi-plus fs-2  m-0 p-0'></i>
              Add funds
            </div>

          </div>
          <div
            className="card p-5 shadow w-100 bg-danger text-light"
            style={{ height: "150px" }}
          >
            <div className="fs-2x fw-bold mt-3 ">ZAR 12,000</div>
            <div className="mt-3 fs-5">Amount in blocked cards</div>
            <div className='text-gray fs-7 d-flex align-items-center mt-5'>
              {/* <i className='bi bi-plus fs-2  m-0 p-0'></i> */}
              Revoke funds
            </div>

          </div>
          <div
            className="card p-5 shadow w-100 bg-dark text-light"
            style={{ height: "150px" }}
          >
            <div className="fs-2x fw-bold mt-3 ">ZAR 52,000</div>
            <div className="mt-3 fs-5">Amount in expired cards</div>
            <div className='text-gray fs-7 d-flex align-items-center mt-5'>
              {/* <i className='bi bi-plus fs-2  m-0 p-0'></i> */}
              Revoke funds
            </div>

          </div>
          <div
            className="card p-5 shadow w-100 bg-warning text-dark"
            style={{ height: "150px" }}
          >
            <div className="fs-2x fw-bold mt-3 ">ZAR 8,000</div>
            <div className="mt-3 fs-5">Amount in active cards</div>
            {/* <div className='text-gray fs-7 d-flex align-items-center mt-5'>
                        <i className='bi bi-plus fs-2  m-0 p-0'></i>
                        Add funds
                        </div> */}

          </div>
        </div>
        <div className='d-flex flex-row gap-5'>
          <div className='w-50 card shadow p-5 mt-5'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className='fw-bold fs-4'>People</div>
              <div className='text-primary'>Add people</div>
            </div>
            <div className="border-gray-300 mt-5 border-bottom-dashed"></div>

            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={data}
              options={options}
            />
            {/* <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    /> */}
          </div>
          <div className='w-50 card shadow p-5 mt-5'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className='fw-bold fs-4'>Card</div>
              <div className='text-primary'>Add Card</div>
            </div>
            <div className="border-gray-300 mt-5 border-bottom-dashed"></div>

            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={data1}
              options={options}
            />
            {/* <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    /> */}
          </div>
        </div>
        <div className='card shadow p-5 mt-5'>
          <h6>Expense Summary</h6>

          <div className='d-flex flex-row  justify-content-between p-5'>
            <div className='d-flex flex-column mt-3'>
              <div className='fs-6 mb-3'>Pending Submissions</div>
              <div className='fs-2x fw-bold m-0 p-0'>23</div>
              <div className='fs-7 m-0 p-0'>are yet to be submitted</div>
            </div>
            <div className='d-flex flex-column mt-3'>
              <div className='fs-6 mb-3'>Pending Approval</div>
              <div className='fs-2x fw-bold m-0 p-0'>149</div>
              <div className='fs-7 m-0 p-0' >awaiting review</div>
            </div>
            <div className='d-flex flex-column  mt-3'>
              <div className='fs-6 mb-3'>Pending Export</div>
              <div className='fs-2x fw-bold m-0 p-0'>273</div>
              <div className='fs-7 m-0 p-0'>awaiting export</div>
            </div>

          </div>

        </div>

        <div className='d-flex flex-row gap-5'>
          <div className="card p-5 w-50 shadow mt-5">
            <h5 className="mb-5">Recent Transactions</h5>
            <table className="table table-row-dashed table-row-gray-300 gy-5">
              <thead>
                <tr className="fw-bolder fs-6 text-gray-800 text-nowrap">
                  <th>Date</th>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Category</th>
                  <th>Card Number</th>
                  <th>Status</th>

                </tr>
              </thead>

              <tbody>
                <tr className="text-nowrap">
                  <td>Dec 7</td>
                  <td>Expense</td>
                  <td>ZAR 1,000</td>
                  <td>Shopping</td>
                  <td>**** **** 6047</td>
                  <td>
                    <div className="badge badge-success">Approved</div>
                  </td>
                </tr>
                <tr className="text-nowrap">
                  <td>Dec 8</td>
                  <td>Income</td>
                  <td>ZAR 2,500</td>
                  <td>Salary</td>
                  <td>**** **** 1234</td>
                  <td>
                    <div className="badge badge-warning">Pending</div>
                  </td>
                </tr>
                <tr className="text-nowrap">
                  <td>Dec 9</td>
                  <td>Expense</td>
                  <td>ZAR 750</td>
                  <td>Utilities</td>
                  <td>**** **** 4321</td>
                  <td>
                    <div className="badge badge-warning">Pending</div>
                  </td>
                </tr>
                <tr className="text-nowrap">
                  <td>Dec 10</td>
                  <td>Expense</td>
                  <td>ZAR 1,200</td>
                  <td>Travel</td>
                  <td>**** **** 5678</td>
                  <td>
                    <div className="badge badge-danger">Rejected</div>
                  </td>
                </tr>
                <tr className="text-nowrap">
                  <td>Dec 11</td>
                  <td>Expense</td>
                  <td>ZAR 500</td>
                  <td>Entertainment</td>
                  <td>**** **** 7890</td>
                  <td>
                    <div className="badge badge-warning">Pending</div>
                  </td>
                </tr>

              </tbody>
            </table>

          </div>
          <div className='w-50 card shadow p-5 mt-5'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className='fw-bold fs-4'>Top Expenses</div>
            
            </div>
            <div className="border-gray-300 mt-5 border-bottom-dashed"></div>

            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={data2}
              options={options}
            />
            {/* <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    /> */}
          </div>
        </div>

      </div>
    </>
  )
}

export default Dashboard