import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../core/Auth";
import { useFormCompletion } from "./Stepper";
import { toast } from "react-toastify";
import { getAllData } from "./api";

const Terms = () => {
  const [termChecked, setChecked] = useState(false);
  const { regUser,setRegUser } = useAuth();
  const navigate = useNavigate();
  const { markFormCompleted } = useFormCompletion();


  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      const confirmLeave = window.confirm("Are you sure you want to leave?");
      
      // Perform the actions regardless of user's choice
      if(confirmLeave){
        toast.info("Complete your KYC process by logging in to continue.",{theme: "colored"})
        setTimeout(()=>{
          navigate("/auth")
        },2000)
      }
  
      // Prevent navigation by pushing the current state again
      window.history.pushState(null, "", window.location.pathname);
    };
  
    // Add an initial state to handle the back button
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", handlePopState);
  
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const confirmLeave = window.confirm("Are you sure you want to leave?");
      if(confirmLeave){
        toast.info("Complete your KYC process by logging in to continue.",{theme: "colored"})
        setTimeout(()=>{
          navigate("/auth")
        },2000)
      }

      event.preventDefault();
      event.returnValue = ''; 
    };
  
    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      // Clean up the event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (regUser?.businessName) {
      getAllData(regUser?.token ?? "").then((res) => {
        setRegUser((prevState) => ({
          ...prevState,
          ...res.data.message,
        }));
      });
    }
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">Terms and Conditions</p>
      <div className="mt-5">
        <div className="p-10">
          <p className="fs-4">
            Thank you for verifying your phone number. We will now proceed with
            the application process.
          </p>
        </div>
        <div className="ms-10 me-10">
          <p>
            - We will collect your business details and pdf files of trade
            license, AOA/MOA and VAT certificate{" "}
          </p>
          <p>- We will do a realtime digital KYC of the primary applicant </p>
          <p>
            - We will collect the personal details and ID proof of other
            shareholders/directors
          </p>
        </div>
      </div>
      <div className="mt-5 d-flex justify-content-center ">
        <div className="form-check form-check-custom form-check-solid form-check-sm mt-20">
          <input
            className="form-check-input"
            type="checkbox"
            checked={termChecked}
            onChange={() => {
              setChecked(!termChecked);
              markFormCompleted("terms");
            }}
            id="flexRadioLg"
          />
          <label
            className="form-check-label fs-5"
            form="flexRadioLg"
            style={{ color: "#246BFD" }}
          >
            <a href="https://www.xfin.digital/terms-conditions" target="_blank">
              {" "}
              Terms and Conditions
            </a>
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5 p-10">
        <button
          className={`${!termChecked ? "btn btn-secondary" : "btn"} w-100`}
          onClick={() => {
            navigate("/auth/business");
          }}
          disabled={!termChecked}
        >
          Accept T&C to Proceed
        </button>
      </div>
    </div>
  );
};

export default Terms;
