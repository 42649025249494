import { FormikErrors, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { useFormCompletion } from "./Stepper";
import { useAuth } from "../../core/Auth";
import { getAllData, otherDocUpload } from "./api";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { toast } from "react-toastify";

interface Document {
  name: string;
  file: File | null;
  showInput: boolean;
  notes: string;
}

const OtherDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [extension, setExtension] = useState("");
  const navigate = useNavigate();
  const { regUser, setRegUser } = useAuth();
  const { markFormCompleted } = useFormCompletion();

  const otherDocFormik = useFormik<{
    documents: Document[];
  }>({
    initialValues: {
      documents: [{ name: "", file: null, showInput: true, notes: "" }],
    },
    validationSchema: Yup.object({
      documents: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string()
              .required("Document name is required") // Enforce required
              .trim("Document name cannot be empty"),
            file: Yup.mixed()
              .required("File is required")
              .test(
                "file-type",
                "Only JPEG, PNG, JPG, SVG, WEBP, and PDF files are allowed",
                (value) => {
                  if (!value) return false; // Ensure file exists
                  if (value instanceof File) {
                    const validExtensions = [
                      "image/jpeg",
                      "image/png",
                      "image/jpg",
                      "image/svg",
                      "image/webp",
                      "application/pdf",
                    ];
                    return validExtensions.includes(value.type); // Validate file type
                  }
                  return false;
                }
              ),
            notes: Yup.string()
              .max(50, "Notes must be 50 characters or less")
              .optional(),
          })
        )
        .min(1, "At least one document is required"), // Ensure at least one document
    }),


    onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      console.log(values);


      try {
        const validDocuments = values.documents.filter((doc) => doc.file);
        const data = await otherDocUpload(validDocuments, regUser?.token ?? "");

        if (data?.data?.status?.statusCode === 0) {
          markFormCompleted("otherDocuments");
          navigate("/auth/applicant");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const handleAddNewDocument = () => {
    otherDocFormik.setFieldValue("documents", [
      ...otherDocFormik.values.documents,
      { name: "", file: null, showInput: true, notes: "" },
    ]);
  };

  const validateFileExtension = (fileName: string) => {
    const allowedExtensions = ["pdf", "jpg", "jpeg", "png", "svg", "webp"];
    const extension = fileName.split(".").pop()?.toLowerCase();
    return allowedExtensions.includes(extension || "");
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      const isValidFile = validateFileExtension(file.name);
      if (!isValidFile) {
        toast.error("Invalid file type");
        return;
      }

      const updatedDocuments = otherDocFormik.values.documents.map((doc, i) =>
        i === index ? { ...doc, file, showInput: false } : doc
      );
      otherDocFormik.setFieldValue("documents", updatedDocuments);
    }
  };

  const handleRemoveDocument = (index: number) => {
    const updatedDocuments = otherDocFormik.values.documents.filter(
      (_, i) => i !== index
    );
    otherDocFormik.setFieldValue("documents", updatedDocuments);
    const fileInput = fileInputRefs.current[index];
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const fileInputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const setDocumentFromUrl = async (
    url: string,
    documentName: string,
    documentExtension: string
  ) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = `${documentName || "document"}.${documentExtension}`;
      return new File([blob], fileName, { type: blob.type });
    } catch (error) {
      console.error("Error converting document URL to File:", error);
      return null;
    }
  };

  const handleCancel = (index: number) => {
    const updatedDocuments = otherDocFormik.values.documents.map((doc, i) =>
      i === index ? { ...doc, file: null, showInput: true } : doc
    );
    otherDocFormik.setFieldValue("documents", updatedDocuments);
    const fileInput = fileInputRefs.current[index];
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handlePreview = (documentPath: string, documentExtension: string) => {
    setPreviewSrc(documentPath);
    setExtension(documentExtension);
  };

  useEffect(() => {
    getAllData(regUser?.token ?? "").then((res) => {
      setRegUser((prevState) => ({
        ...prevState,
        ...res.data.message,
      }));
    });
  }, []);

  useEffect(() => {
    const initializeDocuments = async () => {
      const documentsWithFiles = await Promise.all(
        (regUser?.additionalDocuments || []).map(async (doc) => {
          const file = await setDocumentFromUrl(
            doc.documentPath,
            doc.documentName,
            doc.documentExtention
          );
          return { name: doc.documentName, file, showInput: false, notes: doc?.documentCaption||"" };
        })
      );
      otherDocFormik.setFieldValue("documents", documentsWithFiles);
    };

    initializeDocuments();
  }, [getAllData]);

  return (
    <div>
      <p className="fs-2 fw-bold text-center mb-10">
        Please upload the following documents
      </p>
      <div className="fv-row mb-20">
        <form onSubmit={otherDocFormik.handleSubmit}>
          <div className="fv-row mb-7">
            {otherDocFormik.values.documents.map((document, index) => (
              <div className="mb-7" key={index}>
                <label className="required fw-bold fs-6 mb-2">
                  Document Name
                </label>
                <div className="d-flex flex-row gap-5 mb-3">
                  <div className="w-50">
                    <input
                      type="text"
                      placeholder="Document Name"
                      className="form-control"
                      name={`documents[${index}].name`}
                      maxLength={50}
                      value={document.name}
                      onChange={otherDocFormik.handleChange}
                      onBlur={otherDocFormik.handleBlur}
                    />
                    {Array.isArray(otherDocFormik.touched?.documents) &&
                      otherDocFormik.touched?.documents[index]?.name &&
                      otherDocFormik.errors?.documents?.[index] &&
                      typeof otherDocFormik.errors.documents[index] !== "string" &&
                      (
                        otherDocFormik.errors.documents[index] as FormikErrors<Document>
                      ).name && (
                        <div className="text-danger">
                          {
                            (
                              otherDocFormik.errors.documents[
                              index
                              ] as FormikErrors<Document>
                            ).name
                          }
                        </div>
                      )}

                  </div>

                  <div className="">
                    {document?.file && !document.showInput ? (
                      <div className="d-flex flex-start">
                        <div className="position-relative">
                          <span
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "-10px",
                              cursor: "pointer",
                              color: "red",
                              fontSize: "20px",
                            }}
                            onClick={() => handleCancel(index)}
                          >
                            &times;
                          </span>
                          <img
                            src={
                              document?.file?.type === "application/pdf"
                                ? toAbsoluteUrl("/media/subicons/pdf.png")
                                : URL.createObjectURL(document.file)
                            }
                            onClick={() => {
                              const fileUrl = document?.file
                                ? URL.createObjectURL(document?.file)
                                : "";
                              const isPdf =
                                document?.file?.type === "application/pdf";
                              const extension = isPdf
                                ? "pdf"
                                : document?.file?.name?.split(".").pop() || "";
                              handlePreview(fileUrl, extension);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            alt="Document Preview"
                            style={{
                              height: "40px",
                              width: "40px",
                              cursor: "pointer",
                              borderRadius: "5%",
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <input
                        type="file"
                        ref={(el) => {
                          fileInputRefs.current[index] = el;
                        }}
                        className="form-control"
                        accept="image/*,.pdf"
                        onChange={(e) => handleFileChange(e, index)}
                      />
                    )}
                    {Array.isArray(otherDocFormik.touched?.documents) &&
                      otherDocFormik.touched?.documents[index]?.file &&
                      otherDocFormik.errors?.documents?.[index] &&
                      typeof otherDocFormik.errors.documents[index] !== "string" &&
                      (
                        otherDocFormik.errors.documents[index] as FormikErrors<Document>
                      ).file && (
                        <div className="text-danger">
                          {
                            (
                              otherDocFormik.errors.documents[
                              index
                              ] as FormikErrors<Document>
                            ).file
                          }
                        </div>
                      )}

                  </div>
                  <div className="w-50">
                    <input
                      type="text"
                      placeholder="Notes"
                      className="form-control"
                      name={`documents[${index}].notes`}
                      maxLength={50}
                      value={document?.notes}
                      onChange={otherDocFormik.handleChange}
                      onBlur={otherDocFormik.handleBlur}
                    />

                  </div>

                  <div
                    className="mt-2 text-danger"
                    onClick={() => handleRemoveDocument(index)}
                  >
                    <i className="bi bi-dash text-danger fs-2 cursor-pointer"></i>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="cursor-pointer" onClick={handleAddNewDocument}>
            <p className="text-primary d-flex flex-row align-items-center">
              <i className="bi bi-plus text-primary fs-2"></i>Add New Document
            </p>
          </div>



          <div className="text-center pt-15">
            <button
              type="button"
              className="btn btn-light me-3"
              onClick={() => {
                navigate("/auth/applicant");
                markFormCompleted("otherDocuments");
              }}
            >
              Skip
            </button>
            <button
              type="submit"
              className="btn "
              disabled={otherDocFormik.isSubmitting || loading}
            >
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span role="status" aria-hidden="true"></span>
                </div>
              ) : (
                <div>Proceed</div>
              )}
            </button>
          </div>
        </form>
      </div>

      <div
        className="modal fade w-100"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered w-100"
          role="document"
        >
          {previewSrc && (
            <>
              {extension === "pdf" ? (
                <div
                  className="modal-content w-100 p-0 m-0"
                  style={{ position: "absolute", right: "50%" }}
                >
                  <div className="modal-body p-0 m-0">
                    <iframe
                      src={previewSrc}
                      title="PDF Preview"
                      style={{ width: "200%", height: "80vh" }}
                    ></iframe>
                  </div>
                </div>
              ) : (
                <img
                  src={previewSrc}
                  alt="Document Preview"
                  style={{
                    width: "300px",
                    height: "auto",
                    borderRadius: "10px",
                    objectFit: "fill",
                    marginLeft: "20%",
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherDocuments;
