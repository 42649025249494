import Swal from "sweetalert2";
import { AuthModel } from "./_models";
import { AxiosResponse, AxiosError } from "axios";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";
const getAuth = (): AuthModel | undefined => {
  if (!sessionStorage) {
    return;
  }

  const lsValue: string | null = sessionStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {

      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!sessionStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    sessionStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.response.use(
    (response: AxiosResponse) => {

      const res = response.data;
      if (
        res?.status?.statusCode === 1 &&
        res?.status?.svcErrorCode !== "EMCORE_ACC_LOCKED"
      ) {
        Swal.fire({
          icon: "error",
          title: res?.status?.errorTitle || "Error",
          html: `
            <strong style="display: block; margin-bottom: 10px;">
              ${res?.status?.messageDescription || ""}
            </strong>
            ${res?.status?.suggestion
              ? `<span>${res.status.suggestion}</span><br>
                    <a href="${res.status?.refUrl}" target="_blank" rel="noopener noreferrer" style="display: block; margin-top: 10px;">
                      ${res?.status?.refUrl ? `Reference: ${res.status?.refUrl}` : ""}
                    </a>`
              : res?.status?.refUrl
                ? `<a href="${res.status?.refUrl}" target="_blank" rel="noopener noreferrer">
                    ${res.status?.refUrl}
                  </a>`
                : ""
            }`,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      return response;
    },
    (error: AxiosError) => {

      return Promise.reject(error);
    }
  );
}



export { getAuth, setAuth, AUTH_LOCAL_STORAGE_KEY };
