import { useEffect } from "react";
import { useNavigate } from "react-router";
import { fetchProgramList, findProgram } from "./programsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

const Programs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { programs, loading, error } = useSelector(
    (state: RootState) => state.programs
  );

  useEffect(() => {
    dispatch(
      fetchProgramList({
        // searchCriteriaList: [],
      } as any) as any
    );
  }, [dispatch]);

  return (
    <>
      <h4>Programs Listing</h4>
      <div className="card p-5">
        <div className="container py-5">
          <div className="row g-10">
            {programs.map((program, index) => (
              <div key={index} className="col-12 col-md-6 col-lg-4">
                <div
                  className="card shadow p-4 cursor-pointer"
                  style={{ maxWidth: "350px", margin: "auto" }}
                  onClick={() => {
                    dispatch(
                      findProgram({
                        programGuid: program?.programGuid,
                      } as any) as any
                    ).then(() => {
                      navigate("/program-details");
                    });
                  }}
                >
                  <div className="d-flex flex-row gap-3">
                    <div style={{ height: "50px", width: "50px" }}>
                      <img
                        className="rounded"
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        src={program.logoUrl}
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <div className="fw-bold fs-6">{program.programName}</div>
                      <div className="fw-bold fs-8 text-gray-600">
                        ({program.category})
                      </div>
                    </div>
                  </div>
                  <hr
                    className="text-gray-600"
                    style={{ borderTop: "1px dashed" }}
                  />
                  <div className="fw-bold">{program.headline}</div>
                  <div className="mt-2 fs-8 text-gray-600">
                    {program.shortDescription}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Programs;
