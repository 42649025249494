import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import {
  findSettings,
  manageSettings,
  uploadSignatureFiles,
} from "./settingSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import Authorize from "../../modules/auth/components/Authorize";

const validationSchema = Yup.object({
  expiryPeriod: Yup.string().required("Expiry period is required"),
});

const Settings: React.FC = () => {
  const isLoading = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const { settings } = useSelector((state: RootState) => state?.settings);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      expiryPeriod: `${settings?.expiryPeriod + " " + settings?.expiryType}`,
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      const expiry = values?.expiryPeriod?.split(" ")?.[0];
      const type = values?.expiryPeriod?.split(" ")?.[1];
      dispatch(
        manageSettings({ expiryPeriod: expiry, expiryType: type }) as any
      );
    },
  });

  useEffect(() => {
    dispatch(findSettings({} as any) as any);
  }, [dispatch]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const totalFiles = files.length + acceptedFiles.length;
      console.log(files);

      if (totalFiles > 10) {
        // toast(`Maximum 10 files can be uploaded at a time`, {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        return;
      }
      console.log("hii");
      dispatch(
        uploadSignatureFiles({ uploadFiles: acceptedFiles } as any) as any
      ).then(() => {
        dispatch(findSettings({} as any) as any);
      });
      // const fileNames = acceptedFiles.map((file) => file.name);
      // setSelectedFiles((prevSelectedFiles) => [
      //   ...prevSelectedFiles,
      //   ...fileNames,
      // ]);
      // setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    [files]
  );

  const dropzoneOptions: DropzoneOptions = {
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    maxFiles: 10,
    maxSize: 3000000,
    onDrop,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  const removeFile = (indexToRemove: number) => {
    setSelectedFiles((prevFiles) => {
      // Remove the file at the specified index
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
  };

  return (
    <>
      <h4 className="mb-4">Document Room - Settings</h4>{" "}
      <div className="card bg-white p-10">
        <form onSubmit={formik.handleSubmit} className="">
          <div className="d-flex flex-column gap-10">
            <div className="d-flex align-items-center">
              <label
                htmlFor="expiryPeriod"
                className="fs-5 w-25 d-flex align-items-end"
              >
                Expiry Period <p className="fs-7 mb-0 ms-2">(Shared link)</p>
              </label>
              <div className="w-75">
                <select
                  id="expiryPeriod"
                  name="expiryPeriod"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.expiryPeriod}
                  className="form-select  w-50"
                >
                  <option value="">Select a Period</option>
                  <option value="1 HOURS">1hr</option>
                  <option value="4 HOURS">4hrs</option>
                  <option value="8 HOURS">8hrs</option>
                  <option value="24 HOURS">24hrs</option>
                  <option value="3 DAYS">3 days</option>
                  <option value="7 DAYS">7 days</option>
                  <option value="15 DAYS">15 days</option>
                  <option value="30 DAYS">30 days</option>
                  <option value="60 DAYS">60 days</option>
                  <option value="90 DAYS">90 days</option>
                </select>
                {formik.touched.expiryPeriod && formik.errors.expiryPeriod && (
                  <div className="mt-1 text-danger">
                    {formik.errors.expiryPeriod}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <label htmlFor="dateFormat" className="fs-5 w-25">
                Add Signature/Seal/others
              </label>
              <div className="d-flex w-75">
                <div
                  className="w-50"
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    marginRight: "50px",
                  }}
                >
                  <div
                    className={`dropzone ${isDragActive ? "drag-active" : ""} w-100`}
                    // id="kt_modal_create_ticket_attachments"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />

                    {selectedFiles.length > 0 ? (
                      <div>
                        <div className="d-flex flex-wrap min-w-300px">
                          {selectedFiles.map((fileName, index) => (
                            <div
                              className="d-flex flex-row align-items-center bg-secondary gap-3 px-2 py-1 ms-3 mb-3"
                              key={index}
                              style={{
                                zIndex: 1,
                                borderRadius: "5px",
                                flexBasis: "calc(33.33% - 20px)",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <p className="m-0">{fileName}</p>
                              <i
                                className="bi bi-x m-0 text-dark fs-4"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFile(index);
                                }}
                              ></i>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="dz-message needsclick align-items-center">
                        <i className="ki-duotone ki-file-up fs-3hx text-primary">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>

                        <div className="ms-4">
                          <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                            Drop files here or click to upload.
                          </h3>
                          {/* <span className="fw-semibold fs-7 text-gray-500">
                            Upload up to 10 files (Maximum upload size 3mb)
                          </span> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="p-5 d-flex flex-column justify-content-center align-items-center border border-dark border-1 rounded cursor-pointer bg-hover-light"
                  onClick={() => navigate("/documentRoom/library")}
                >
                  <div className="">View Library</div>
                  <div className="d-flex ">
                    (<p className="m-0">{settings?.documents?.length} items</p>)
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center gap-10 mt-10">
            <button
              type="button"
              onClick={() => formik.resetForm()}
              className="btn"
            >
              Cancel
            </button>
            <Authorize hasAnyPermission={["MENU_DR_SETTINGS|EDIT"]}>
              <button
                type="submit"
                disabled={isLoading || !formik.isValid}
                className="btn"
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
            </Authorize>
          </div>
        </form>
      </div>
    </>
  );
};

export default Settings;
