import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from '../../core/Auth';
import { compareFaces, uploadSelfieDoc } from './api';
import { useNavigate } from 'react-router';
import { useFormCompletion } from './Stepper';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../../pages/components/Loader';
import { RegModel } from '../../core/_models';

const Selfie = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isCameraActive, setIsCameraActive] = useState<boolean>(false);
    const streamRef = useRef<MediaStream | null>(null);  // Added ref to store stream
    const { regUser, setRegUser } = useAuth();
    const navigate = useNavigate()
    const { markFormCompleted } = useFormCompletion();
   

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            streamRef.current = stream; // Store the stream
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            }
            setIsCameraActive(true);
            setError(null);
        } catch (err) {
            setError('Unable to access the camera. Please ensure it is enabled.');
            setIsCameraActive(false);
        }
    };

    const stopCamera = () => {
      
        if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => {
                track.stop();
            });
            streamRef.current = null; // Clear the stored stream
        }
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
        setIsCameraActive(false);
    };

  
    

    const capturePhoto = async () => {
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                // Flip the canvas back after drawing the image
                ctx.save();
                ctx.scale(-1, 1);  // This will flip the canvas horizontally
                ctx.drawImage(video, -canvas.width, 0, canvas.width, canvas.height);  // Draw image flipped horizontally
                ctx.restore();
    
                const imageData = canvas.toDataURL('image/png');
                setCapturedImage(imageData);
                setRegUser((prevState) => ({
                    ...(prevState as RegModel),
                    livelinessImage: imageData,
                }));
                stopCamera();
            }
        }
    };
    
    const retakePhoto = () => {
        setCapturedImage(null);
        startCamera();
        setRegUser((prevState) => ({
            ...(prevState as RegModel),
            livelinessImage: "",
          }));
    };

    useEffect(() => {
        startCamera();
        return () => {
            stopCamera();
        };
    }, []);

 

    return (
        <div className="container text-center mt-5">
            <ToastContainer limit={1} />
            <h2 className="mb-4">Capture Selfie</h2>
<p className="mb-3">Position yourself in front of the camera and click "Capture Photo".</p>


            <div className="d-flex justify-content-center align-items-center flex-column mb-4">
                {capturedImage ? (
                    <div className='d-flex flex-row justify-content-center align-items-center gap-5'>
                        
                        <div>
                            <p className='fw-bold'>Selfie Image</p>
                            <img
                                src={capturedImage}
                                alt="Captured Selfie"
                                className="img-thumbnail mb-3"
                                style={{ width: '300px', height: 'auto' }}
                            />
                        </div>

                    </div>
                ) : (
                    <div
                        className="border border-secondary rounded mb-3"
                        style={{
                            width: '100%',
                            maxWidth: '400px',
                            height: '250px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#f8f9fa',
                        }}
                    >
                        <video ref={videoRef} style={{
                            width: '100%',
                            height: '100%',
                            transform: 'scaleX(-1)', // Flips the video horizontally
                        }} />
                    </div>
                )}

                <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>

            <div className="d-flex justify-content-center align-items-center gap-3">
                {!capturedImage ? (
                    <div className='d-flex flex-row gap-5 align-items-center justify-content-center'>
                        <button
                            className="btn btn-primary"
                            onClick={capturePhoto}
                            disabled={!isCameraActive}
                        >
                            Capture Photo
                        </button>



                       

                    </div>
                ) : (
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                       
                        <div className='d-flex flex-row gap-5'>
                            <button className="btn btn-secondary" onClick={retakePhoto}>
                                Retake Photo
                            </button>
                            <button className="btn" onClick={async () => { navigate("/auth/verification"); }}>
                                Continue
                            </button>
                        </div>
                    </div>


                )}
            </div>

            {error && <div className="text-danger mt-3">{error}</div>}
        </div>
    );
};

export default Selfie;
