import React, { useEffect, useRef } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import ApexCharts from 'apexcharts';
import { Chart } from 'react-google-charts';

const Analytics = () => {

    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!chartRef.current) return;

        // Create root element
        const root = am5.Root.new(chartRef.current);

        // Apply themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.verticalLayout,
            })
        );


        // Add legend at the top, centered with margin-bottom
        const legend = chart.children.unshift(
            am5.Legend.new(root, {
                centerX: am5.p50,      // Center horizontally
                x: am5.p50,           // Align to horizontal center
                y: am5.percent(0),    // Position at the top
                layout: root.horizontalLayout, // Arrange items in a row
                marginBottom: 15,
                // marginRight:-10     // Add some space below the legend

            })
        );

        // Explicit data for October, November, and December
        const data = [
            { year: "OCT", a: Math.floor(Math.random() * 100), b: Math.floor(Math.random() * 100), c: Math.floor(Math.random() * 100), d: Math.floor(Math.random() * 100) },
            { year: "NOV", a: Math.floor(Math.random() * 100), b: Math.floor(Math.random() * 100), c: Math.floor(Math.random() * 100), d: Math.floor(Math.random() * 100) },
            { year: "DEC", a: Math.floor(Math.random() * 100), b: Math.floor(Math.random() * 100), c: Math.floor(Math.random() * 100), d: Math.floor(Math.random() * 100) },
        ];

        // Create axes
        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "year",
                renderer: am5xy.AxisRendererX.new(root, {
                    cellStartLocation: 0.1,
                    cellEndLocation: 0.9,
                }),
                tooltip: am5.Tooltip.new(root, {}),
            })
        );

        xAxis.data.setAll(data);

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
                // Format the Y-axis labels with "ZAR" before the value
                numberFormat: "'ZAR' #,###.##" // Format with ZAR before the number
            })
        );
        
        // Add series
        const createSeries = (name: string, field: string) => {
            const series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name,
                    xAxis,
                    yAxis,
                    valueYField: field,
                    categoryXField: "year",
                })
            );

            series.columns.template.setAll({
                tooltipText: "{name}, {categoryX}: {valueY}",
                width: am5.percent(90),
                tooltipY: 0,
            });

            series.data.setAll(data);

            series.bullets.push(() => {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true,
                    }),
                });
            });

            legend.data.push(series);
        };

        createSeries("PAYE", "a");
        createSeries("UIF", "b");
        createSeries("SDL", "c");
        createSeries("VAT", "d");

        // Animate chart appearance
        chart.appear(1000, 100);

        // Cleanup on component unmount
        return () => {
            root.dispose();
        };
    }, []);

    const chartRef1 = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!chartRef1.current) return;

        // Create root element
        const root = am5.Root.new(chartRef1.current);

        // Apply themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.verticalLayout,
            })
        );

        // // Add legend at the top, centered with margin-bottom
        // const legend = chart.children.unshift(
        //     am5.Legend.new(root, {
        //         centerX: am5.p50,      // Center horizontally
        //         x: am5.p50,           // Align to horizontal center
        //         y: am5.percent(0),    // Position at the top
        //         layout: root.horizontalLayout, // Arrange items in a row
        //         marginBottom: 15,  // margin-bottom space
        //     })
        // );

        // Explicit data for 6 months with a single value per month
        const data = [
            { month: "Jan", value: Math.floor(Math.random() * 100) },
            { month: "Feb", value: Math.floor(Math.random() * 100) },
            { month: "Mar", value: Math.floor(Math.random() * 100) },
            { month: "Apr", value: Math.floor(Math.random() * 100) },
            { month: "May", value: Math.floor(Math.random() * 100) },
            { month: "Jun", value: Math.floor(Math.random() * 100) },
        ];

        // Create axes
        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "month",  // Use month for X-axis categories
                renderer: am5xy.AxisRendererX.new(root, {
                    cellStartLocation: 0.1,
                    cellEndLocation: 0.9,
                }),
                tooltip: am5.Tooltip.new(root, {}),
            })
        );

        xAxis.data.setAll(data);

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
                // Format the Y-axis labels with "ZAR" before the value
                numberFormat: "'ZAR' #,###.##" // Format with ZAR before the number
            })
        );
        


        

        // Add series (we only have one value per month)
        const createSeries = (name: string, field: string) => {
            const series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name,
                    xAxis,
                    yAxis,
                    valueYField: field,
                    categoryXField: "month", // Map category to 'month'
                })
            );

            series.columns.template.setAll({
                tooltipText: "{name}, {categoryX}: ZAR {valueY}", 
                width: am5.percent(90),
                tooltipY: 0,
                // fill: am5.color(0xb05f5f),
                // // stroke: am5.color(0x5f7db0),
            });

            series.data.setAll(data);

            // Add value label on top of the columns
            series.bullets.push(() => {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "ZAR {valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true,
                    }),
                });
            });

            // legend.data.push(series);
        };

        // Create the series for the single value (could be any financial metric)
        createSeries("Value", "value");

        // Animate chart appearance
        chart.appear(1000, 100);

        // Cleanup on component unmount
        return () => {
            root.dispose();
        };
    }, []);
    

    const chartRef2 = useRef<HTMLDivElement | null>(null);



    useEffect(() => {
        if (!chartRef.current) return;

        const element = chartRef2.current;
        //   const height = chartRef2.offsetHeight;
        const labelColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--bs-gray-500");
        const borderColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--bs-gray-200");
        const baseColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--bs-info");
        const lightColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--bs-info-light");

        const options = {
            series: [
                {
                    name: 'Tax Payable',
                    data: [30, 40, 40, 90, 90, 70, 70]
                },
            ],
            chart: {
                fontFamily: 'inherit',
                type: 'area',
                height: '350px',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {},
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                type: 'solid',
                opacity: 1,
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
                colors: [baseColor],
            },
            xaxis: {
                categories: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], // 6 months data
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                    },
                },
                crosshairs: {
                    position: 'front',
                    stroke: {
                        color: baseColor,
                        width: 1,
                        dashArray: 3,
                    },
                },
                tooltip: {
                    enabled: true,
                    offsetY: 0,
                    style: {
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                    },
                    formatter: function (val: number) {
                        return `ZAR ${val}`; // Prefix "ZAR" to y-axis markers
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: function (val: number) {
                        return `ZAR${val}`; // Display ZAR
                    },
                },
            },
            colors: [lightColor],
            grid: {
                borderColor: borderColor,
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            markers: {
                strokeColor: baseColor,
                strokeWidth: 3,
            },
        };

        const chart = new ApexCharts(element, options);
        chart.render();

        return () => {
            chart.destroy(); // Cleanup on component unmount
        };
    }, []);

    const chartRef3 = useRef<HTMLDivElement | null>(null);



    useEffect(() => {
        if (!chartRef3.current) return;

        const element = chartRef3.current;
        //   const height = chartRef2.offsetHeight;
        const labelColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--bs-gray-500");
        const borderColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--bs-gray-200");
        const baseColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--bs-info");
        const lightColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--bs-info-light");

        const options = {
            series: [
                {
                    name: 'Tax Payable',
                    data: [30, 40, 40, 90, 90, 70, 70]
                },
            ],
            chart: {
                fontFamily: 'inherit',
                type: 'area',
                height: '350px',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {},
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                type: 'solid',
                opacity: 1,
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
                colors: [baseColor],
            },
            xaxis: {
                categories: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], // 6 months data
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                    },
                },
                crosshairs: {
                    position: 'front',
                    stroke: {
                        color: baseColor,
                        width: 1,
                        dashArray: 3,
                    },
                },
                tooltip: {
                    enabled: true,
                    offsetY: 0,
                    style: {
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                    },
                    formatter: function (val: number) {
                        return `ZAR ${val}`; // Prefix "ZAR" to y-axis markers
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: function (val: number) {
                        return `ZAR${val}`; // Display ZAR
                    },
                },
            },
            colors: [lightColor],
            grid: {
                borderColor: borderColor,
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            markers: {
                strokeColor: baseColor,
                strokeWidth: 3,
            },
        };

        const chart = new ApexCharts(element, options);
        chart.render();

        return () => {
            chart.destroy(); // Cleanup on component unmount
        };
    }, []);


    const data = [
        ['Task', 'ZAR Value'],
        ['PAYE', 1100], // Example ZAR values
        ['UIF', 200],
        ['SDL', 200],
        ['VAT', 200],
        ['Provisional Tax', 700],
        ['Income Tax', 700],
    ];

    const options = {
        title: '',
        colors: ['#fe3995', '#f6aa33', '#6e4ff5', '#2abe81', '#c7d2e7', '#593ae1'],
        pieSliceText: 'value', // Display the label as part of the slice
        pieSliceTextStyle: {
            fontSize: 16, // Increase font size for the pie slices (if needed)
        },
        chartArea: {
            width: '80%', // Make the pie chart bigger by reducing space around it
            height: '80%', // Adjust height to make the chart larger
            
        },
        tooltip: {
            trigger: 'selection',
            isHtml: true,
            formatter: function (data: any) {
                const value = data['row'] ? data['row'][1] : 0; // Accessing the ZAR value
                return `<div>ZAR ${value}</div>`; // Showing ZAR in tooltip
            },
        },
        slices: {
            0: { offset: 0.1 },
            1: { offset: 0.1 },
            2: { offset: 0.1 },
            3: { offset: 0.1 },
            4: { offset: 0.1 },
        },
        legend: {
            position: 'left', // Move legend to the right
            alignment: 'bottom', // Align legend text to the center
            textStyle: {
                fontSize: 14, // Adjust the font size of the legend
            },
            maxLines: 5, // Limit the number of lines in the legend (if needed)
            width: 200,
           
            // Optionally, set the width of the legend box

        },
    };

    return (
        <>
            <h4>Analytics</h4>
            <div className='card p-5'>
                <div className='d-flex flex-row  gap-5'>
                    <div className="card shadow-sm w-50 p-5">
                        <h5>Tax Compliance Health Check</h5>
                        <div>
                            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-7">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                        href="#kt_tab_pane_1"
                                    >
                                        Monthly
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        href="#kt_tab_pane_2"
                                    >
                                        Periodic
                                    </a>
                                </li>

                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade active show"
                                    id="kt_tab_pane_1"
                                    role="tabpanel"
                                >
                                    <div className="mt-20">
                                        <div className="d-flex flex-row gap-5">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8">PAYE/UIF/SDL</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Paid</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Paid</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Overdue</div>
                                                        <div className="fs-9 text-nowrap">By 30 days</div>
                                                    </div>
                                                    <div className="card shadow bg-warning text-nowrap py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Upcoming</div>
                                                        {/* <div className="fs-9">7th Aug</div> */}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card  bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Filed</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card  bg-success  py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Filed</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card  bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 ">Overdue</div>
                                                        <div className="fs-9 text-nowrap">By 12 days</div>
                                                    </div>
                                                    <div className="card bg-warning text-nowrap py-2 px-5 w-100">
                                                        <div className="fw-bold text-nowrap fs-8">Upcoming</div>
                                                        {/* <div className="fs-9"></div> */}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="d-flex flex-row gap-5 mt-20">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8">VAT</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Paid</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Paid</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Overdue</div>
                                                        <div className="fs-9 text-nowrap">By 30 days</div>
                                                    </div>
                                                    <div className="card shadow bg-warning text-nowrap py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Upcoming</div>
                                                        {/* <div className="fs-9">7th Aug</div> */}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card  bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Filed</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card  bg-success  py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Filed</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card  bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 ">Overdue</div>
                                                        <div className="fs-9 text-nowrap">By 12 days</div>
                                                    </div>
                                                    <div className="card bg-warning text-nowrap py-2 px-5 w-100">
                                                        <div className="fw-bold text-nowrap fs-8">Upcoming</div>
                                                        {/* <div className="fs-9"></div> */}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                                    <div className="">
                                        <div className="d-flex flex-row gap-5">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8">Provisional Tax</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">First Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Paid</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>


                                                    </div>
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 text-nowrap">Second Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Paid</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>

                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 text-wrap">Third Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Pending</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">First Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Filed</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>


                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 text-nowrap">Second Payment</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Overdue</div>
                                                            <div className="fs-9 ms-3 text-nowrap">By 2 days</div>
                                                        </div>

                                                    </div>
                                                    <div className="card shadow bg-warning py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 text-wrap">Third Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Upcoming</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div className="d-flex flex-row gap-5 mt-10">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8 text-wrap text-center">EMP501 Reconciliation</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">End of October</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Paid</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>
                                                    </div>
                                                    <div className="card shadow bg-warning py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">End of May</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Upcoming</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">End of October</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Filed</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>
                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">End of May</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Overdue</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div className="d-flex flex-row gap-5 mt-10">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8 text-wrap text-center">Corporate Income Tax</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Annual Tax Return</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Paid</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card  bg-warning py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Annual Tax Return</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Upcoming</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">

                                    eiusmod et amet.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card w-50 shadow p-5">
                        <h5 className="mb-5 ">Monthly Tax Distributions</h5>
                        <div className="">
                            <div id="kt_amcharts_1" ref={chartRef} style={{ height: "350px" }}></div>
                        </div>

                    </div>
                </div>
                <div className='d-flex flex-row  gap-5 mt-5'>
                    <div className="card w-50 shadow p-5">
                        <h5 className="mb-5 ">Monthly Tax Payouts</h5>
                        <div className="">
                            <div ref={chartRef2} id="kt_apexcharts_3" style={{ height: '350px' }} />
                        </div>

                    </div>
                    <div className="card w-50 shadow p-5">
                        <h5 className="mb-5 ">Monthly Tax Payouts</h5>
                        <div className="">
                            <Chart
                                chartType="PieChart"
                                data={data}
                                options={options}
                                width={'100%'}
                                height={'400px'}
                               

                            />
                        </div>

                    </div>
                </div>
                <div className='d-flex flex-row  gap-5 mt-5'>
                    <div className="card w-50 shadow p-5">
                        <h5 className="mb-5 ">Tax Penalty</h5>
                        <div className="">
                            <div ref={chartRef3} id="kt_apexcharts_3" style={{ height: '350px' }} />
                        </div>

                    </div>
                    <div className="card w-50 shadow p-5">
                        <h5 className="mb-5 ">Tax Refund Tracker</h5>
                        <div className="">
                            <div id="kt_amcharts_1" ref={chartRef1} style={{ height: "350px" }}></div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default Analytics