import React, { useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import ApexCharts from 'apexcharts';
// import ReactApexChart from 'react-apexcharts';


interface ChartProps {
    data: number[]; // Net Profit data for 6 months
  }

const TaxDashboard = () => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!chartRef.current) return;

        // Create root element
        const root = am5.Root.new(chartRef.current);

        // Apply themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.verticalLayout,
            })
        );


        // Add legend at the top, centered with margin-bottom
        const legend = chart.children.unshift(
            am5.Legend.new(root, {
                centerX: am5.p50,      // Center horizontally
                x: am5.p50,           // Align to horizontal center
                y: am5.percent(0),    // Position at the top
                layout: root.horizontalLayout, // Arrange items in a row
                marginBottom: 15,
                // marginRight:-10     // Add some space below the legend

            })
        );

        // Explicit data for October, November, and December
        const data = [
            { year: "OCT", a: Math.floor(Math.random() * 100), b: Math.floor(Math.random() * 100), c: Math.floor(Math.random() * 100), d: Math.floor(Math.random() * 100) },
            { year: "NOV", a: Math.floor(Math.random() * 100), b: Math.floor(Math.random() * 100), c: Math.floor(Math.random() * 100), d: Math.floor(Math.random() * 100) },
            { year: "DEC", a: Math.floor(Math.random() * 100), b: Math.floor(Math.random() * 100), c: Math.floor(Math.random() * 100), d: Math.floor(Math.random() * 100) },
        ];

        // Create axes
        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "year",
                renderer: am5xy.AxisRendererX.new(root, {
                    cellStartLocation: 0.1,
                    cellEndLocation: 0.9,
                }),
                tooltip: am5.Tooltip.new(root, {}),
            })
        );

        xAxis.data.setAll(data);

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );

        // Add series
        const createSeries = (name: string, field: string) => {
            const series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name,
                    xAxis,
                    yAxis,
                    valueYField: field,
                    categoryXField: "year",
                })
            );

            series.columns.template.setAll({
                tooltipText: "{name}, {categoryX}: {valueY}",
                width: am5.percent(90),
                tooltipY: 0,
            });

            series.data.setAll(data);

            series.bullets.push(() => {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true,
                    }),
                });
            });

            legend.data.push(series);
        };

        createSeries("PAYE", "a");
        createSeries("UIF", "b");
        createSeries("SDL", "c");
        createSeries("VAT", "d");

        // Animate chart appearance
        chart.appear(1000, 100);

        // Cleanup on component unmount
        return () => {
            root.dispose();
        };
    }, []);

    const chartRef2 = useRef<HTMLDivElement | null>(null);

   

    useEffect(() => {
      if (!chartRef.current) return;
  
      const element = chartRef2.current;
    //   const height = chartRef2.offsetHeight;
    const labelColor = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--bs-gray-500");
      const borderColor = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--bs-gray-200");
      const baseColor = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--bs-info");
      const lightColor = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--bs-info-light");

      const options = {
        series: [
          {
            name: 'Tax Payable',
            data: [30, 40, 40, 90, 90, 70, 70]
          },
        ],
        chart: {
          fontFamily: 'inherit',
          type: 'area',
          height: '350px',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'solid',
          opacity: 1,
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 3,
          colors: [baseColor],
        },
        xaxis: {
          categories: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], // 6 months data
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
          crosshairs: {
            position: 'front',
            stroke: {
              color: baseColor,
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: true,
            offsetY: 0,
            style: {
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
            formatter: function (val: number) {
                return `ZAR ${val}`; // Prefix "ZAR" to y-axis markers
              },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val: number) {
              return `ZAR${val}`; // Display ZAR
            },
          },
        },
        colors: [lightColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          strokeColor: baseColor,
          strokeWidth: 3,
        },
      };
  
      const chart = new ApexCharts(element, options);
      chart.render();
  
      return () => {
        chart.destroy(); // Cleanup on component unmount
      };
    }, []);



    return (
        <>
            <h4>Dashboard</h4>
            <div className="card  p-5">
                <div className="d-flex flex-row justify-content-between gap-5">
                    <div
                        className="card p-5 shadow w-100 bg-secondary text-dark"
                        style={{ height: "150px" }}
                    >
                        <div className="fs-2x fw-bold mt-10 ">ZAR 1,000</div>
                        <div className="">Total tax due this month</div>
                    </div>
                    <div
                        className="card p-5 shadow w-100 bg-dark text-light"
                        style={{ height: "150px" }}
                    >
                        <div className="fs-2x fw-bold mt-10">ZAR 500</div>
                        <div className="">Total paid this month</div>
                    </div>
                    <div
                        className="card p-5 shadow w-100 bg-warning text-light"
                        style={{ height: "150px" }}
                    >
                        <div className="fs-2x fw-bold mt-10">ZAR 500</div>
                        <div className="">Total pending this month</div>
                    </div>
                    <div
                        className="card p-5 shadow w-100  bg-info text-light"
                        style={{ height: "150px" }}
                    >
                        <div className="fs-2x fw-bold mt-10">3</div>
                        <div className="">Event's spending</div>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row gap-5">
                    <div className="card shadow-sm w-100 p-5 mt-5">
                        <h5>Deadline's this month</h5>
                        <div className="card shadow p-5 mt-3 mb-3">
                            <div className="d-flex flex-row gap-5 align-items-center">
                                <div className="badge badge-dark text-white pt-5 pb-5 ps-3 pe-3 fw-bold rounded">
                                    7th Jan
                                </div>
                                <div>
                                    <div className="fw-bold ">PAYE/UIF/SDL - EMP201</div>
                                    <div className="mt-2 fs-7">For January</div>
                                </div>
                            </div>
                            <div className="border-gray-300 mt-5 border-bottom-dashed"></div>
                            <div className="d-flex flex-row flex-end gap-5 mt-3">
                                <div>
                                    {" "}
                                    <div className="badge badge-light-primary">Payment pending </div>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-add-money">Pay now</Tooltip>}
                                    >
                                        <span
                                            style={{ cursor: "pointer", marginLeft: "5px" }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_payment"
                                        >
                                            <img
                                                style={{ height: "20px", width: "20px" }}
                                                src={toAbsoluteUrl("/media/tax-icons/Pay Now.svg")}
                                                alt=""
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </div>

                                <div>
                                    {" "}
                                    <div className="badge badge-light-primary">Filing pending </div>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-add-money">File now</Tooltip>}
                                    >
                                        <span
                                            style={{ cursor: "pointer", marginLeft: "5px" }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_payment"
                                        >
                                            <img
                                                style={{ height: "20px", width: "20px" }}
                                                src={toAbsoluteUrl(
                                                    "/media/tax-icons/File Now (1).svg"
                                                )}
                                                alt=""
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow p-5 mt-3 mb-3">
                            <div className="d-flex flex-row gap-5 align-items-center">
                                <div className="badge badge-dark text-white pt-5 pb-5 ps-3 pe-3 fw-bold rounded">
                                    13th Jan
                                </div>
                                <div>
                                    <div className="fw-bold ">PAYE/UIF/SDL - EMP201</div>
                                    <div className="mt-2 fs-7">For January</div>
                                </div>
                            </div>
                            <div className="border-gray-300 mt-5 border-bottom-dashed"></div>
                            <div className="d-flex flex-row flex-end gap-5 mt-3">
                                <div>
                                    {" "}
                                    <div className="badge badge-light-success">Completed</div>
                                    <i className="bi bi-download fs-4 mt-2 ms-3 text-dark"></i>
                                </div>

                                <div>
                                    {" "}
                                    <div className="badge badge-light-primary">Filing pending </div>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-add-money">File now</Tooltip>}
                                    >
                                        <span
                                            style={{ cursor: "pointer", marginLeft: "5px" }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_payment"
                                        >
                                            <img
                                                style={{ height: "20px", width: "20px" }}
                                                src={toAbsoluteUrl(
                                                    "/media/tax-icons/File Now (1).svg"
                                                )}
                                                alt=""
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow p-5 mt-3 mb-3">
                            <div className="d-flex flex-row gap-5 align-items-center">
                                <div className="badge badge-dark text-white pt-5 pb-5 ps-3 pe-3 fw-bold rounded">
                                    17th Jan
                                </div>
                                <div>
                                    <div className="fw-bold ">PAYE/UIF/SDL - EMP201</div>
                                    <div className="mt-2 fs-7">For January</div>
                                </div>
                            </div>
                            <div className="border-gray-300 mt-5 border-bottom-dashed"></div>
                            <div className="d-flex flex-row flex-end gap-5 mt-3">
                                <div>
                                    {" "}
                                    <div className="badge badge-light-primary">Payment pending </div>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-add-money">Pay now</Tooltip>}
                                    >
                                        <span
                                            style={{ cursor: "pointer", marginLeft: "5px" }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_payment"
                                        >
                                            <img
                                                style={{ height: "20px", width: "20px" }}
                                                src={toAbsoluteUrl("/media/tax-icons/Pay Now.svg")}
                                                alt=""
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </div>

                                <div>
                                    {" "}
                                    <div className="badge badge-light-primary">Filing pending </div>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-add-money">File now</Tooltip>}
                                    >
                                        <span
                                            style={{ cursor: "pointer", marginLeft: "5px" }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_payment"
                                        >
                                            <img
                                                style={{ height: "20px", width: "20px" }}
                                                src={toAbsoluteUrl(
                                                    "/media/tax-icons/File Now (1).svg"
                                                )}
                                                alt=""
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-sm w-100 p-5 mt-5">
                        <h5>Tax Compliance Health Check</h5>
                        <div>
                            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-7">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                        href="#kt_tab_pane_1"
                                    >
                                        Monthly
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        href="#kt_tab_pane_2"
                                    >
                                        Periodic
                                    </a>
                                </li>

                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade active show"
                                    id="kt_tab_pane_1"
                                    role="tabpanel"
                                >
                                    <div className="mt-20">
                                        <div className="d-flex flex-row gap-5">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8">PAYE/UIF/SDL</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Paid</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Paid</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Overdue</div>
                                                        <div className="fs-9 text-nowrap">By 30 days</div>
                                                    </div>
                                                    <div className="card shadow bg-warning text-nowrap py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Upcoming</div>
                                                        {/* <div className="fs-9">7th Aug</div> */}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card  bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Filed</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card  bg-success  py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Filed</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card  bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 ">Overdue</div>
                                                        <div className="fs-9 text-nowrap">By 12 days</div>
                                                    </div>
                                                    <div className="card bg-warning text-nowrap py-2 px-5 w-100">
                                                        <div className="fw-bold text-nowrap fs-8">Upcoming</div>
                                                        {/* <div className="fs-9"></div> */}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="d-flex flex-row gap-5 mt-20">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8">VAT</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Paid</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Paid</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Overdue</div>
                                                        <div className="fs-9 text-nowrap">By 30 days</div>
                                                    </div>
                                                    <div className="card shadow bg-warning text-nowrap py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Upcoming</div>
                                                        {/* <div className="fs-9">7th Aug</div> */}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card  bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Filed</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card  bg-success  py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Filed</div>
                                                        <div className="fs-9">7th Aug</div>
                                                    </div>
                                                    <div className="card  bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 ">Overdue</div>
                                                        <div className="fs-9 text-nowrap">By 12 days</div>
                                                    </div>
                                                    <div className="card bg-warning text-nowrap py-2 px-5 w-100">
                                                        <div className="fw-bold text-nowrap fs-8">Upcoming</div>
                                                        {/* <div className="fs-9"></div> */}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                                    <div className="">
                                        <div className="d-flex flex-row gap-5">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8">Provisional Tax</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">First Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Paid</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>


                                                    </div>
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 text-nowrap">Second Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Paid</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>

                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 text-wrap">Third Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Pending</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">First Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Filed</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>


                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 text-nowrap">Second Payment</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Overdue</div>
                                                            <div className="fs-9 ms-3 text-nowrap">By 2 days</div>
                                                        </div>

                                                    </div>
                                                    <div className="card shadow bg-warning py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8 text-wrap">Third Payment</div>
                                                        <div className="d-flex flex-row">
                                                            <div className="fs-9  text-sucess">Upcoming</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div className="d-flex flex-row gap-5 mt-10">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8 text-wrap text-center">EMP501 Reconciliation</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">End of October</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Paid</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>
                                                    </div>
                                                    <div className="card shadow bg-warning py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">End of May</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Upcoming</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">End of October</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Filed</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>
                                                    </div>
                                                    <div className="card shadow bg-danger py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">End of May</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Overdue</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div className="d-flex flex-row gap-5 mt-10">

                                            <div className="bg-dark card shadow text-white d-flex align-items-center justify-content-center text-nowrap" style={{ width: "150px" }}>
                                                <div className="fs-8 text-wrap text-center">Corporate Income Tax</div></div>
                                            <div className="d-flex flex-column gap-5 w-100">
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card shadow bg-success py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Annual Tax Return</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Paid</div>
                                                            <div className="fs-9 ms-3 text-nowrap">7th Aug</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="d-flex flex-row gap-5 w-100">
                                                    <div className="card  bg-warning py-2 px-5 w-100">
                                                        <div className="fw-bold fs-8">Annual Tax Return</div>
                                                        <div className="d-flex flex-row ">
                                                            <div className="fs-9  text-sucess">Upcoming</div>
                                                            {/* <div className="fs-9 ms-3 text-nowrap">7th Aug</div> */}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">

                                    eiusmod et amet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row gap-5 mt-5">
                    <div className="card w-50 shadow p-5">
                    <h5 className="mb-5 ">Monthly Tax Distributions</h5>
                        <div className="card-body">
                            <div id="kt_amcharts_1" ref={chartRef} style={{ height: "500px" }}></div>
                        </div>

                    </div>
                    <div className="card p-5 w-50 shadow">
                    <h5 className="mb-5">Recent Payments</h5>
                        <table className="table table-row-dashed table-row-gray-300 gy-7">
                            <thead>
                                <tr className="fw-bolder fs-6 text-gray-800">
                                    <th>Date</th>
                                    <th>Tax Type</th>
                                    <th>Form Number</th>
                                    <th>Amount</th>
                                    <th>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>PAYE/UIF/SDL</td>
                                    <td>EMP201</td>
                                    <td>ZAR 1,000</td>
                                    <td>
                                        <div className="badge badge-success">
                                            Paid
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>VAT</td>
                                    <td>VAT201</td>
                                    <td>ZAR 1,300</td>
                                    <td>
                                        <div className="badge badge-success">
                                            Paid
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>Provisional Tax</td>
                                    <td>IRP6</td>
                                    <td>ZAR 1,300</td>
                                    <td>
                                        <div className="badge badge-success">
                                            Paid
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>EMP501 Reconciliation</td>
                                    <td>EMP501</td>
                                    <td>ZAR 1,300</td>
                                    <td>
                                        <div className="badge badge-success">
                                            Paid
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>Corporate Income Tax</td>
                                    <td>ITR14</td>
                                    <td>ZAR 1,300</td>
                                    <td>
                                        <div className="badge badge-success">
                                            Paid
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>VAT</td>
                                    <td>VAT201</td>
                                    <td>ZAR 1,300</td>
                                    <td>
                                        <div className="badge badge-success">
                                            Paid
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>PAYE/UIF/SDL</td>
                                    <td>EMP201</td>
                                    <td>ZAR 1,300</td>
                                    <td>
                                        <div className="badge badge-success">
                                            Paid
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="d-flex flex-row gap-5 mt-5">
                    
                    <div className="card p-5 w-50 shadow">
                    <h5 className="mb-5">Recent Documents</h5>
                        <table className="table table-row-dashed table-row-gray-300 gy-7">
                            <thead>
                                <tr className="fw-bolder fs-6 text-gray-800">
                                    <th>Date</th>
                                    <th>Tax Type</th>
                                    <th>Form Number</th>
                                    <th>Document Type</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>PAYE/UIF/SDL</td>
                                    <td>EMP201</td>
                                    <td>Payment Receipt</td>
                                    <td>
                                        <div className="">
                                           <i className="bi bi-download"></i>
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>VAT</td>
                                    <td>VAT201</td>
                                    <td>File Receipt</td>
                                    <td>
                                    <div className="">
                                           <i className="bi bi-download"></i>
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>Provisional Tax</td>
                                    <td>IRP6</td>
                                    <td>Payment Receipt</td>
                                    <td>
                                    <div className="">
                                           <i className="bi bi-download"></i>
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>EMP501 Reconciliation</td>
                                    <td>EMP501</td>
                                    <td>File Receipt</td>
                                    <td>
                                    <div className="">
                                           <i className="bi bi-download"></i>
                                        </div>
                                    </td>
                                    

                                </tr>
                                <tr>
                                    <td>Dec 7</td>
                                    <td>Corporate Income Tax</td>
                                    <td>ITR14</td>
                                    <td>File Receipt</td>
                                    <td>
                                    <div className="">
                                           <i className="bi bi-download"></i>
                                        </div>
                                    </td>

                                </tr>
                               
                            </tbody>
                        </table>

                    </div>
                    <div className="card w-50 shadow p-5">
                    <h5 className="mb-5 ">Monthly Tax Payouts</h5>
                        <div className="card-body">
                        <div ref={chartRef2} id="kt_apexcharts_3" style={{ height: '350px' }} />
                        </div>

                    </div>
                </div>

            </div>

        </>
    );
};

export default TaxDashboard;
