import React from 'react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import { createSession, getSessionResults } from './api';
import { useAuth } from '../../core/Auth';
import { AxiosResponse } from 'axios';
import { Amplify } from "aws-amplify";
import '@aws-amplify/ui-react/styles.css';
import awsexports from "../../../../../aws-exports";
import { useNavigate } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { RegModel } from '../../core/_models';

Amplify.configure(awsexports);

interface CreateLivenessResponse {
  sessionId: string;
  confidenceValue: string;
}

const Recognition = () => {
  const [loading, setLoading] = React.useState(true);
  const { regUser, setRegUser } = useAuth();
  const [createLivenessApiData, setCreateLivenessApiData] = React.useState<CreateLivenessResponse | null>(null);
  const navigate = useNavigate();

  const fetchCreateLiveness = async () => {
    try {
      const response: AxiosResponse<{ message: CreateLivenessResponse }> = await createSession(regUser?.token || "");
      console.log(response.data);
      setCreateLivenessApiData(response.data.message);
      setLoading(false);
    } catch (error) {
      console.error("Failed to create liveness session:", error);
    }
  };

  React.useEffect(() => {
    fetchCreateLiveness();
  }, [regUser?.token]);

  const handleAnalysisComplete = async () => {
    try {
      // const videoElement = document.querySelector('video');
      
  
      // if (videoElement && videoElement.readyState >= 2) {
      //   // Wait for the video to render a frame properly
      //   await new Promise((resolve) => setTimeout(resolve, 100)); // Delay to give Safari time to render
  
      //   const canvas = document.createElement('canvas');
      //   canvas.width = videoElement.videoWidth || 640;
      //   canvas.height = videoElement.videoHeight || 480;
      //   const ctx = canvas.getContext('2d');
  
      //   if (ctx) {
      //     // Draw the video frame onto the canvas
      //     ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  
      //     // Force a render update for Safari
      //     await new Promise((resolve) => setTimeout(resolve, 50)); // Small additional delay for Safari rendering
  
      //     const imageData = canvas.toDataURL('image/png');
  
      //     if (imageData === "data:,") {
      //       console.error("Captured image is empty. Ensure video stream is rendering properly.");
      //     } else {
      //       setRegUser((prevState) => ({
      //         ...(prevState as RegModel),
      //         livelinessImage: imageData,
      //       }));
      //       console.log('Captured image:', imageData);
      //     }
      //   } else {
      //     console.error("Canvas context could not be retrieved.");
      //   }
      // } else {
      //   console.error("Video element not ready or not rendering.");
      // }
  
      const response: AxiosResponse<{ message: CreateLivenessResponse }> = await getSessionResults(
        regUser?.token || "",
        createLivenessApiData?.sessionId || ""
      );
  
      console.log(response?.data?.message?.confidenceValue, regUser?.livenessConfidenceValue);
  
      if (response && Number(response?.data?.message?.confidenceValue) >= Number(regUser?.livenessConfidenceValue)) {
        navigate("/auth/idCard");
      } else {
        setCreateLivenessApiData(null);
        setLoading(true);
        fetchCreateLiveness();
        toast.error("Liveness Confidence value is low. Please try again.", { theme: "colored" });
      }
    } catch (error) {
      console.error("Failed to fetch session results:", error);
      alert("An error occurred during the liveness verification. Please try again.");
    }
  };
  
  
  return (
    <>
      <ToastContainer limit={1} />
      
      <ThemeProvider>
        {loading ? (
          <Loader />
        ) : createLivenessApiData?.sessionId ? (
          <>
          <h2 className="mb-4 text-center">Liveliness Check</h2>
            <FaceLivenessDetector
              sessionId={createLivenessApiData?.sessionId}
              region="ap-south-1"
              onAnalysisComplete={handleAnalysisComplete}
              onError={(error) => {
                console.error(error);
              }}
            />
          </>
        ) : (
          <p>Unable to start session. Please try again.</p>
        )}
      </ThemeProvider>
    </>
  );
};

export default Recognition;
