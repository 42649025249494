/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useRef } from "react";
import { useAuth } from "../../../../app/modules/auth";
import { enhancedFetch } from "../../../../app/modules/auth/core/_requests";
import Authorize from "../../../../app/modules/auth/components/Authorize";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

interface ContactDetails {
  contactEmail: string;
  contactPhone: string;
  contactWhatsapp: string;
}

const SwitchMenu = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const navigate = useNavigate();
  const [menuType, setMenuType] = useState(1);

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const fetchMenus = () => {
    const menuTypeString = sessionStorage.getItem("menuType");
    const menuTypeData = menuTypeString ? JSON.parse(menuTypeString) : null;
    const menuType = menuTypeData ? menuTypeData.menuType : null;
    setMenuType(menuType);
  };

  useEffect(() => {
    fetchMenus(); // Initial fetch

    const handleMenusUpdated = () => fetchMenus(); // Fetch menus on custom event

    // Listen for the custom event
    window.addEventListener("menusUpdated", handleMenusUpdated);

    return () => {
      // Cleanup event listener
      window.removeEventListener("menusUpdated", handleMenusUpdated);
    };
  }, []);

  const getContactDetails = async (type: number): Promise<ContactDetails> => {
    try {
      const url = `${API_URL}/dashboard/getSegregatedMenu`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        segregationType: type,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };
      let response = await enhancedFetch(url, options);
      let data = await response?.json();
      if (data?.status?.statusCode === 0) {
        const menus = data?.message?.menuCategoryList;
        sessionStorage.setItem("menuType", JSON.stringify({ menuType: type }));
        sessionStorage.setItem("menus", JSON.stringify({ menus }));
        setTimeout(() => {
          window.dispatchEvent(new Event("menusUpdated"));
        }, 100);
        navigate("/dashboard");
      }

      return data.message as ContactDetails;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const confirmationScreen = (type: number) => {
    Swal?.fire({
      icon: "question",
      titleText: `Would you like to switch your view to : ${type === 1 ? "Banking + Beyond Banking" : type === 2 ? "Banking" : "Beyond Banking"}`,

      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        container: "blurred-backdrop-unique",
      },
    }).then((res) => {
      if (res?.isConfirmed) getContactDetails(type);
    });
  };

  return (
    <>
      <style>{`
    .blurred-backdrop-unique {
  backdrop-filter: blur(10px); /* Adjust the blur intensity */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent dark overlay */
}
    `}</style>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-250px"
        data-kt-menu="true"
      >
        <div className="menu-item px-3">
          <h5 className="ms-7 pt-2">Switch View</h5>
        </div>

        <div className="separator my-2"></div>
        <div
          className={`py-3 px-5 ${menuType === 1 ? "bg-light text-primary" : "text-gray-500"} bg-hover-light rounded text-hover-primary mb-2 mx-5`}
          onClick={() => {
            if (menuType !== 1) confirmationScreen(1);
          }}
        >
          Banking + Beyond banking
        </div>
        <div
          className={`py-3 px-5 ${menuType === 2 ? "bg-light text-primary" : "text-gray-500"} bg-hover-light rounded text-hover-primary mb-2 mx-5`}
          onClick={() => {
            if (menuType !== 2) confirmationScreen(2);
          }}
        >
          Banking
        </div>
        <div
          className={`py-3 px-5 ${menuType === 3 ? "bg-light text-primary" : "text-gray-500"} bg-hover-light rounded text-hover-primary mb-2 mx-5`}
          onClick={() => {
            if (menuType !== 3) confirmationScreen(3);
          }}
        >
          Beyond banking
        </div>
      </div>
    </>
  );
};

export { SwitchMenu };
